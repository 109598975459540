/* INFO: These variables constitute the 16 colors of Solarized bright:
   https://ethanschoonover.com/solarized/ */
/* They are used throughout the stylesheets of the components. */
/*
  ------------
  usage guide:
  ------------

  this is supposed to document current usage,
  it's not complete, not necessarily prescriptive and
  some usages might need to be reconsidered to be more consistent

  --base03: text in search/agenda/tasklist, selected buttons
  --base02: timestamps
  --base01: title text
  --base00: non-user provided text (e.g. footer, privacy policy)
  --base0: help text, actionable text (e.g. 'insert timestamp')
  --base1: elements being dragged
  --base2: highlights like borders and dividers
  --base3: background

  --base0-soft: box shadows
  --base1-soft: special surfaces (selected header, things behind modal, glow)

  header text based on indentation level:
  --blue
  --green: DONE keyword
  --cyan
  --yellow

  additional colors:
  --orange: TODO keyword, overdue planning dates, active clock indicator
  --red: error messages, changelog
  --magenta: action button
  --violet: unused

  --green-soft: highlight selected table cell
*/
:root {
  --base03: #002b36;
  --base02: #073642;
  --base01: #586e75;
  --base00: #657b83;
  --base0: #839496;
  --base0-soft: rgba(131, 148, 150, 0.75);
  --base1: #93a1a1;
  --base1-soft: rgba(147, 161, 161, 0.4);
  --base2: #eee8d5;
  --base3: #fdf6e3;
  --yellow: #b58900;
  --orange: #cb4b16;
  --red: #dc322f;
  --magenta: #d33682;
  --violet: #6c71c4;
  --blue: #268bd2;
  --cyan: #2aa198;
  --green: #859900;
  --green-soft: rgba(133, 153, 0, 0.28);
}
