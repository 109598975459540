.main-image {
  max-height: 35em;
}
.testimonial-brand img {
  height: 3em;
  width: auto;
}

/* Icons with <img> */
#icons img {
  opacity: 0.5;
  margin-bottom: 2em;
  width: 100%;
  max-height: 2em;
}

#icons img:hover {
  opacity: 1;
}

/* Icons with Fontawesome <i> */
#icons svg:not(:root).svg-inline--fa {
  height: 3em;
  width: 100%;
}

#false-bottom-preventor {
  cursor: pointer;
}
