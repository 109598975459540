@import '../../../../../../colors.css';

.table-action-drawer {
  display: flex;
  justify-content: space-around;
  min-height: '100px';
}

.table-action-drawer-container {
  color: var(--base01);

  width: 50%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.table-action-drawer__edit-icon-container {
  grid-row-start: span 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.table-action-drawer__sub-icon-container {
  position: relative;
  margin: 15px 0;
}

.table-action-drawer__main-icon {
  padding-right: 3px;
  padding-bottom: 3px;
}

.table-action-drawer__sub-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.table-action-drawer__sub-icon--rotated {
  transform: rotate(270deg);
}

.table-action-movement-container {
  color: var(--base01);

  width: 50%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.table-action-movement__up {
  grid-column-start: 2;
  grid-row-start: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}

.table-action-movement__down {
  grid-column-start: 2;
  grid-row-start: 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.table-action-movement__left {
  grid-column-start: 1;
  grid-row-start: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.table-action-movement__right {
  grid-column-start: 3;
  grid-row-start: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}
