@import '../../../colors.css';

.switch {
  display: inline-block;
  border: 1px solid var(--base2);
  width: 90px;
  height: 34px;
  padding: 2px;
  box-sizing: border-box;
}

.switch__grabber {
  width: 42px;
  height: 28px;
  border: 1px solid var(--base2);
  box-sizing: border-box;
  box-shadow: 1px 1px 5px 0px var(--base0-soft);
  background-color: var(--base00);
}
