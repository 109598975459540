@import '../../../../colors.css';

/* Currently, the markup is the same as in the AgendaModal component.
 * Hence the CSS rules from there apply. */

.search-input-container{
  display: flex;
  margin-bottom: 1em;
}

.search-input-line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bookmark__icon {
  margin-left: 10px;

  cursor: pointer;
}

.bookmark__icon__enabled {
  color: var(--magenta);
}

.task-list__filter-input,
.task-list__filter-input-invalid {
  box-sizing: border-box;
  width: 100%;
}

.search__input-container {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.task-list__filter-input-invalid {
  border: 2px solid var(--red);
}

.task-list__modal-title {
  width: 100%;
  height: 20px;
  min-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-list__modal-title_search {
  width: 100%;
  height: 20px;
  min-height: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
