.entry-container {
  font-size: 16px;

  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow-x: hidden;
}

.entry-container--large-font {
  font-size: 20px;
}
