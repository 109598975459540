.action-drawer__btn--letter {
  font-size: 24px;
}

.action-drawer__btn--with-sub-icon {
  padding-right: 3px;
  padding-bottom: 3px;
}

.action-drawer__btn__sub-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.action-drawer__btn__sub-icon--rotated {
  transform: rotate(270deg);
}
