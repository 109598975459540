.property-list__property {
  font-weight: bold;
  color: var(--yellow);

  margin-right: 5px;
}

.property-list__item-container {
  display: flex;
}
