@import '../../../../colors.css';

.agenda__title {
  color: var(--base01);

  margin-top: 0;
}

.agenda__tab-container {
  justify-content: center;
}

.agenda__timeframe-header-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 2em 0 1.5em 0;

  color: var(--base01);
}

.agenda__timeframe-header-container > i {
  cursor: pointer;
}

.agenda__timeframe-header {
  font-weight: bold;
  font-size: 18px;

  text-align: center;

  margin-left: 15px;
  margin-right: 15px;

  min-width: 230px;
}

.agenda__days-container {
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}
