@charset "UTF-8";
/* INFO: This file is taken from
   https://200ok.ch/landing_page/css/styles.css and has been slightly
   adapted so that it compiles for React (some resources are 404s).
   Also, we have 'rewritten' it as SCSS, so that we can scope
   everything under '.landing-page', so that the styles do not bleed
   through. See adr-002 for details.

   The template has been bought by 200ok llc from
   https://startbootstrap.com/previews/sb-ui-kit-pro
*/
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-white: #fff;
  --bs-gray: #69707a;
  --bs-gray-dark: #363d47;
  --bs-primary: #0061f2;
  --bs-secondary: #5bc2ff;
  --bs-success: #00ac69;
  --bs-info: #00cfd5;
  --bs-warning: #f4a100;
  --bs-danger: #e81500;
  --bs-light: #f2f6fc;
  --bs-dark: #212832;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-red: #e81500;
  --bs-orange: #f76400;
  --bs-yellow: #f4a100;
  --bs-green: #00ac69;
  --bs-teal: #00ba94;
  --bs-cyan: #00cfd5;
  --bs-blue: #0061f2;
  --bs-indigo: #5800e8;
  --bs-purple: #6900c7;
  --bs-pink: #e30059;
  --bs-red-soft: #f1e0e3;
  --bs-orange-soft: #f3e7e3;
  --bs-yellow-soft: #f2eee3;
  --bs-green-soft: #daefed;
  --bs-teal-soft: #daf0f2;
  --bs-cyan-soft: #daf2f8;
  --bs-blue-soft: #dae7fb;
  --bs-indigo-soft: #e3ddfa;
  --bs-purple-soft: #e4ddf7;
  --bs-pink-soft: #f1ddec;
  --bs-primary-soft: #dae7fb;
  --bs-secondary-soft: #e3f1fc;
  --bs-success-soft: #daefed;
  --bs-info-soft: #daf2f8;
  --bs-warning-soft: #f2eee3;
  --bs-danger-soft: #f1e0e3;
  --bs-font-sans-serif: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

.landing-page {
  /* rtl:raw:
  [type="tel"],
  [type="url"],
  [type="email"],
  [type="number"] {
    direction: ltr;
  }
  */
  /* rtl:begin:ignore */
  /* rtl:end:ignore */
  /* rtl:options: {
    "autoRename": true,
    "stringMap":[ {
      "name"    : "prev-next",
      "search"  : "prev",
      "replace" : "next"
    } ]
  } */
  /* rtl:begin:remove */
  /* rtl:end:remove */
  /* .card-waves .card-body { */
  /*   background-image: url("../img/backgrounds/bg-waves.svg"); */
  /* } */
  /* .card-angles .card-body { */
  /*   background-image: url("../img/backgrounds/bg-angles.svg"); */
  /* } */
  /* /\*! */
  /*  * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups) */
  /*  * Copyright 2013 - 2018 pixelsign */
  /*  * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt) */
  /*  * Last Build: Thu Dec 20 2018 14:05:50 */
  /*  *\/ */
  /* .device-wrapper { */
  /*   max-width: 300px; */
  /*   width: 100%; */
  /* } */
  /* .device { */
  /*   position: relative; */
  /*   background-size: cover; */
  /* } */
  /* .device::after { */
  /*   position: absolute; */
  /*   background-size: cover; */
  /*   width: 100%; */
  /*   height: 100%; */
  /*   pointer-events: none; */
  /* } */
  /* .device .screen { */
  /*   position: absolute; */
  /*   background-size: cover; */
  /*   pointer-events: auto; */
  /* } */
  /* .device .button { */
  /*   position: absolute; */
  /*   cursor: pointer; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black'] { */
  /*   padding-bottom: 198.89807163%; */
  /*   background-image: url('../img/device-mockups/iPhoneX/portrait.png'); */
  /*   z-index: initial; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black'] .screen { */
  /*   top: 3.254847645%; */
  /*   left: 7.162534435%; */
  /*   width: 85.67493113%; */
  /*   height: 93.49030471%; */
  /*   border: 0px; */
  /*   z-index: 0; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black'] { */
  /*   padding-bottom: 50.27700831%; */
  /*   background-image: url('../img/device-mockups/iPhoneX/landscape.png'); */
  /*   z-index: initial; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black'] .screen { */
  /*   top: 7.162534435%; */
  /*   left: 3.254847645%; */
  /*   width: 93.49030471%; */
  /*   height: 85.67493113%; */
  /*   border: 0px; */
  /*   z-index: 0; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black'] { */
  /*   padding-bottom: 198.898071625%; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black'] .screen { */
  /*   top: 3.1855955679%; */
  /*   left: 6.8870523416%; */
  /*   width: 86.2258953168%; */
  /*   height: 93.6288088643%; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black']::after { */
  /*   content: ''; */
  /*   background-image: url('../img/device-mockups/iPhoneX/portrait_black.png'); */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='portrait'][data-color='black'] .button { */
  /*   display: none; */
  /*   top: 0%; */
  /*   left: 0%; */
  /*   width: 100%; */
  /*   height: 100%; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black'] { */
  /*   padding-bottom: 50.2770083102%; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black'] .screen { */
  /*   top: 6.3360881543%; */
  /*   left: 3.4626038781%; */
  /*   width: 93.0747922438%; */
  /*   height: 87.3278236915%; */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black']::after { */
  /*   content: ''; */
  /*   background-image: url('../img/device-mockups/iPhoneX/landscape_black.png'); */
  /* } */
  /* .device[data-device='iPhoneX'][data-orientation='landscape'][data-color='black'] .button { */
  /*   display: none; */
  /*   top: 0%; */
  /*   left: 0%; */
  /*   width: 100%; */
  /*   height: 100%; */
  /* } */
}
.landing-page *,
.landing-page *::before,
.landing-page *::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  .landing-page :root {
    scroll-behavior: smooth;
  }
}
.landing-page .App {
  margin: 0;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #69707a;
  background-color: #f2f6fc;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.landing-page hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
.landing-page hr:not([size]) {
  height: 1px;
}
.landing-page h6,
.landing-page .h6,
.landing-page h5,
.landing-page .h5,
.landing-page h4,
.landing-page .h4,
.landing-page h3,
.landing-page .h3,
.landing-page h2,
.landing-page .h2,
.landing-page h1,
.landing-page .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #363d47;
}
.landing-page h1,
.landing-page .h1 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .landing-page h1,
.landing-page .h1 {
    font-size: 1.5rem;
  }
}
.landing-page h2,
.landing-page .h2 {
  font-size: calc(1.265rem + 0.18vw);
}
@media (min-width: 1200px) {
  .landing-page h2,
.landing-page .h2 {
    font-size: 1.4rem;
  }
}
.landing-page h3,
.landing-page .h3 {
  font-size: calc(1.255rem + 0.06vw);
}
@media (min-width: 1200px) {
  .landing-page h3,
.landing-page .h3 {
    font-size: 1.3rem;
  }
}
.landing-page h4,
.landing-page .h4 {
  font-size: 1.2rem;
}
.landing-page h5,
.landing-page .h5 {
  font-size: 1.1rem;
}
.landing-page h6,
.landing-page .h6 {
  font-size: 1rem;
}
.landing-page p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.landing-page abbr[title],
.landing-page abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
.landing-page address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
.landing-page ol,
.landing-page ul {
  padding-left: 2rem;
}
.landing-page ol,
.landing-page ul,
.landing-page dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
.landing-page ol ol,
.landing-page ul ul,
.landing-page ol ul,
.landing-page ul ol {
  margin-bottom: 0;
}
.landing-page dt {
  font-weight: 500;
}
.landing-page dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.landing-page blockquote {
  margin: 0 0 1rem;
}
.landing-page b,
.landing-page strong {
  font-weight: bolder;
}
.landing-page small,
.landing-page .small {
  font-size: 0.875em;
}
.landing-page mark,
.landing-page .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.landing-page sub,
.landing-page sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
.landing-page sub {
  bottom: -0.25em;
}
.landing-page sup {
  top: -0.5em;
}
.landing-page a {
  color: #0061f2;
  text-decoration: none;
}
.landing-page a:hover {
  color: #004ec2;
  text-decoration: underline;
}
.landing-page a:not([href]):not([class]),
.landing-page a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
.landing-page pre,
.landing-page code,
.landing-page kbd,
.landing-page samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}
.landing-page pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: #69707a;
}
.landing-page pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.landing-page code {
  font-size: 0.875em;
  color: #e30059;
  word-wrap: break-word;
}
.landing-page a > code {
  color: inherit;
}
.landing-page kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212832;
  border-radius: 0.25rem;
}
.landing-page kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 500;
}
.landing-page figure {
  margin: 0 0 1rem;
}
.landing-page img,
.landing-page svg {
  vertical-align: middle;
}
.landing-page table {
  caption-side: bottom;
  border-collapse: collapse;
}
.landing-page caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #a7aeb8;
  text-align: left;
}
.landing-page th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
.landing-page thead,
.landing-page tbody,
.landing-page tfoot,
.landing-page tr,
.landing-page td,
.landing-page th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
.landing-page label {
  display: inline-block;
}
.landing-page button {
  border-radius: 0;
}
.landing-page button:focus:not(:focus-visible) {
  outline: 0;
}
.landing-page input,
.landing-page button,
.landing-page select,
.landing-page optgroup,
.landing-page textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.landing-page button,
.landing-page select {
  text-transform: none;
}
.landing-page [role=button] {
  cursor: pointer;
}
.landing-page select {
  word-wrap: normal;
}
.landing-page select:disabled {
  opacity: 1;
}
.landing-page [list]::-webkit-calendar-picker-indicator {
  display: none;
}
.landing-page button,
.landing-page [type=button],
.landing-page [type=reset],
.landing-page [type=submit] {
  -webkit-appearance: button;
}
.landing-page button:not(:disabled),
.landing-page [type=button]:not(:disabled),
.landing-page [type=reset]:not(:disabled),
.landing-page [type=submit]:not(:disabled) {
  cursor: pointer;
}
.landing-page ::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
.landing-page textarea {
  resize: vertical;
}
.landing-page fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
.landing-page legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  .landing-page legend {
    font-size: 1.5rem;
  }
}
.landing-page legend + * {
  clear: left;
}
.landing-page ::-webkit-datetime-edit-fields-wrapper,
.landing-page ::-webkit-datetime-edit-text,
.landing-page ::-webkit-datetime-edit-minute,
.landing-page ::-webkit-datetime-edit-hour-field,
.landing-page ::-webkit-datetime-edit-day-field,
.landing-page ::-webkit-datetime-edit-month-field,
.landing-page ::-webkit-datetime-edit-year-field {
  padding: 0;
}
.landing-page ::-webkit-inner-spin-button {
  height: auto;
}
.landing-page [type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
.landing-page ::-webkit-search-decoration {
  -webkit-appearance: none;
}
.landing-page ::-webkit-color-swatch-wrapper {
  padding: 0;
}
.landing-page ::file-selector-button {
  font: inherit;
}
.landing-page ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
.landing-page output {
  display: inline-block;
}
.landing-page iframe {
  border: 0;
}
.landing-page summary {
  display: list-item;
  cursor: pointer;
}
.landing-page progress {
  vertical-align: baseline;
}
.landing-page [hidden] {
  display: none !important;
}
.landing-page .lead {
  font-size: 1.1rem;
  font-weight: 400;
}
.landing-page .display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-1 {
    font-size: 5rem;
  }
}
.landing-page .display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-2 {
    font-size: 4.5rem;
  }
}
.landing-page .display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-3 {
    font-size: 4rem;
  }
}
.landing-page .display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-4 {
    font-size: 3.5rem;
  }
}
.landing-page .display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-5 {
    font-size: 3rem;
  }
}
.landing-page .display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .landing-page .display-6 {
    font-size: 2.5rem;
  }
}
.landing-page .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.landing-page .list-inline {
  padding-left: 0;
  list-style: none;
}
.landing-page .list-inline-item {
  display: inline-block;
}
.landing-page .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.landing-page .initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.landing-page .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.landing-page .blockquote > :last-child {
  margin-bottom: 0;
}
.landing-page .blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #69707a;
}
.landing-page .blockquote-footer::before {
  content: "— ";
}
.landing-page .img-fluid {
  max-width: 100%;
  height: auto;
}
.landing-page .img-thumbnail {
  padding: 0.25rem;
  background-color: #f2f6fc;
  border: 1px solid #d4dae3;
  border-radius: 0.35rem;
  max-width: 100%;
  height: auto;
}
.landing-page .figure {
  display: inline-block;
}
.landing-page .figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.landing-page .figure-caption {
  font-size: 0.875em;
  color: #69707a;
}
.landing-page .container,
.landing-page .container-fluid,
.landing-page .container-xxl,
.landing-page .container-xl,
.landing-page .container-lg,
.landing-page .container-md,
.landing-page .container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .landing-page .container-sm,
.landing-page .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .landing-page .container-md,
.landing-page .container-sm,
.landing-page .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .landing-page .container-lg,
.landing-page .container-md,
.landing-page .container-sm,
.landing-page .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .landing-page .container-xl,
.landing-page .container-lg,
.landing-page .container-md,
.landing-page .container-sm,
.landing-page .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .landing-page .container-xxl,
.landing-page .container-xl,
.landing-page .container-lg,
.landing-page .container-md,
.landing-page .container-sm,
.landing-page .container {
    max-width: 1440px;
  }
}
.landing-page .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.landing-page .row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.landing-page .col {
  flex: 1 0 0%;
}
.landing-page .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.landing-page .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.landing-page .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.landing-page .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.landing-page .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.landing-page .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.landing-page .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
@media (min-width: 576px) {
  .landing-page .col-sm {
    flex: 1 0 0%;
  }
  .landing-page .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .landing-page .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .landing-page .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .landing-page .col-md {
    flex: 1 0 0%;
  }
  .landing-page .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .landing-page .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .landing-page .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .landing-page .col-lg {
    flex: 1 0 0%;
  }
  .landing-page .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .landing-page .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .landing-page .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .landing-page .col-xl {
    flex: 1 0 0%;
  }
  .landing-page .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .landing-page .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .landing-page .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1500px) {
  .landing-page .col-xxl {
    flex: 1 0 0%;
  }
  .landing-page .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .landing-page .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .landing-page .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.landing-page .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.landing-page .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.landing-page .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.landing-page .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.landing-page .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.landing-page .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.landing-page .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.landing-page .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.landing-page .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.landing-page .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.landing-page .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.landing-page .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.landing-page .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.landing-page .offset-1 {
  margin-left: 8.33333333%;
}
.landing-page .offset-2 {
  margin-left: 16.66666667%;
}
.landing-page .offset-3 {
  margin-left: 25%;
}
.landing-page .offset-4 {
  margin-left: 33.33333333%;
}
.landing-page .offset-5 {
  margin-left: 41.66666667%;
}
.landing-page .offset-6 {
  margin-left: 50%;
}
.landing-page .offset-7 {
  margin-left: 58.33333333%;
}
.landing-page .offset-8 {
  margin-left: 66.66666667%;
}
.landing-page .offset-9 {
  margin-left: 75%;
}
.landing-page .offset-10 {
  margin-left: 83.33333333%;
}
.landing-page .offset-11 {
  margin-left: 91.66666667%;
}
.landing-page .g-0,
.landing-page .gx-0 {
  --bs-gutter-x: 0;
}
.landing-page .g-0,
.landing-page .gy-0 {
  --bs-gutter-y: 0;
}
.landing-page .g-1,
.landing-page .gx-1 {
  --bs-gutter-x: 0.25rem;
}
.landing-page .g-1,
.landing-page .gy-1 {
  --bs-gutter-y: 0.25rem;
}
.landing-page .g-2,
.landing-page .gx-2 {
  --bs-gutter-x: 0.5rem;
}
.landing-page .g-2,
.landing-page .gy-2 {
  --bs-gutter-y: 0.5rem;
}
.landing-page .g-3,
.landing-page .gx-3 {
  --bs-gutter-x: 1rem;
}
.landing-page .g-3,
.landing-page .gy-3 {
  --bs-gutter-y: 1rem;
}
.landing-page .g-4,
.landing-page .gx-4 {
  --bs-gutter-x: 1.5rem;
}
.landing-page .g-4,
.landing-page .gy-4 {
  --bs-gutter-y: 1.5rem;
}
.landing-page .g-5,
.landing-page .gx-5 {
  --bs-gutter-x: 2.5rem;
}
.landing-page .g-5,
.landing-page .gy-5 {
  --bs-gutter-y: 2.5rem;
}
.landing-page .g-10,
.landing-page .gx-10 {
  --bs-gutter-x: 6rem;
}
.landing-page .g-10,
.landing-page .gy-10 {
  --bs-gutter-y: 6rem;
}
.landing-page .g-15,
.landing-page .gx-15 {
  --bs-gutter-x: 9rem;
}
.landing-page .g-15,
.landing-page .gy-15 {
  --bs-gutter-y: 9rem;
}
@media (min-width: 576px) {
  .landing-page .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .landing-page .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .landing-page .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .landing-page .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .landing-page .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .landing-page .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .landing-page .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .landing-page .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .landing-page .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .landing-page .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .offset-sm-0 {
    margin-left: 0;
  }
  .landing-page .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .landing-page .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .landing-page .offset-sm-3 {
    margin-left: 25%;
  }
  .landing-page .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .landing-page .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .landing-page .offset-sm-6 {
    margin-left: 50%;
  }
  .landing-page .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .landing-page .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .landing-page .offset-sm-9 {
    margin-left: 75%;
  }
  .landing-page .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .landing-page .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .landing-page .g-sm-0,
.landing-page .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .landing-page .g-sm-0,
.landing-page .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .landing-page .g-sm-1,
.landing-page .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .landing-page .g-sm-1,
.landing-page .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .landing-page .g-sm-2,
.landing-page .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .landing-page .g-sm-2,
.landing-page .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .landing-page .g-sm-3,
.landing-page .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .landing-page .g-sm-3,
.landing-page .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .landing-page .g-sm-4,
.landing-page .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .landing-page .g-sm-4,
.landing-page .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .landing-page .g-sm-5,
.landing-page .gx-sm-5 {
    --bs-gutter-x: 2.5rem;
  }
  .landing-page .g-sm-5,
.landing-page .gy-sm-5 {
    --bs-gutter-y: 2.5rem;
  }
  .landing-page .g-sm-10,
.landing-page .gx-sm-10 {
    --bs-gutter-x: 6rem;
  }
  .landing-page .g-sm-10,
.landing-page .gy-sm-10 {
    --bs-gutter-y: 6rem;
  }
  .landing-page .g-sm-15,
.landing-page .gx-sm-15 {
    --bs-gutter-x: 9rem;
  }
  .landing-page .g-sm-15,
.landing-page .gy-sm-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 768px) {
  .landing-page .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .landing-page .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .landing-page .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .landing-page .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .landing-page .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .landing-page .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .landing-page .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .landing-page .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .landing-page .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .landing-page .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .offset-md-0 {
    margin-left: 0;
  }
  .landing-page .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .landing-page .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .landing-page .offset-md-3 {
    margin-left: 25%;
  }
  .landing-page .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .landing-page .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .landing-page .offset-md-6 {
    margin-left: 50%;
  }
  .landing-page .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .landing-page .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .landing-page .offset-md-9 {
    margin-left: 75%;
  }
  .landing-page .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .landing-page .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .landing-page .g-md-0,
.landing-page .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .landing-page .g-md-0,
.landing-page .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .landing-page .g-md-1,
.landing-page .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .landing-page .g-md-1,
.landing-page .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .landing-page .g-md-2,
.landing-page .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .landing-page .g-md-2,
.landing-page .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .landing-page .g-md-3,
.landing-page .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .landing-page .g-md-3,
.landing-page .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .landing-page .g-md-4,
.landing-page .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .landing-page .g-md-4,
.landing-page .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .landing-page .g-md-5,
.landing-page .gx-md-5 {
    --bs-gutter-x: 2.5rem;
  }
  .landing-page .g-md-5,
.landing-page .gy-md-5 {
    --bs-gutter-y: 2.5rem;
  }
  .landing-page .g-md-10,
.landing-page .gx-md-10 {
    --bs-gutter-x: 6rem;
  }
  .landing-page .g-md-10,
.landing-page .gy-md-10 {
    --bs-gutter-y: 6rem;
  }
  .landing-page .g-md-15,
.landing-page .gx-md-15 {
    --bs-gutter-x: 9rem;
  }
  .landing-page .g-md-15,
.landing-page .gy-md-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 992px) {
  .landing-page .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .landing-page .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .landing-page .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .landing-page .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .landing-page .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .landing-page .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .landing-page .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .landing-page .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .landing-page .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .landing-page .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .offset-lg-0 {
    margin-left: 0;
  }
  .landing-page .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .landing-page .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .landing-page .offset-lg-3 {
    margin-left: 25%;
  }
  .landing-page .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .landing-page .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .landing-page .offset-lg-6 {
    margin-left: 50%;
  }
  .landing-page .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .landing-page .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .landing-page .offset-lg-9 {
    margin-left: 75%;
  }
  .landing-page .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .landing-page .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .landing-page .g-lg-0,
.landing-page .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .landing-page .g-lg-0,
.landing-page .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .landing-page .g-lg-1,
.landing-page .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .landing-page .g-lg-1,
.landing-page .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .landing-page .g-lg-2,
.landing-page .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .landing-page .g-lg-2,
.landing-page .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .landing-page .g-lg-3,
.landing-page .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .landing-page .g-lg-3,
.landing-page .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .landing-page .g-lg-4,
.landing-page .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .landing-page .g-lg-4,
.landing-page .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .landing-page .g-lg-5,
.landing-page .gx-lg-5 {
    --bs-gutter-x: 2.5rem;
  }
  .landing-page .g-lg-5,
.landing-page .gy-lg-5 {
    --bs-gutter-y: 2.5rem;
  }
  .landing-page .g-lg-10,
.landing-page .gx-lg-10 {
    --bs-gutter-x: 6rem;
  }
  .landing-page .g-lg-10,
.landing-page .gy-lg-10 {
    --bs-gutter-y: 6rem;
  }
  .landing-page .g-lg-15,
.landing-page .gx-lg-15 {
    --bs-gutter-x: 9rem;
  }
  .landing-page .g-lg-15,
.landing-page .gy-lg-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 1200px) {
  .landing-page .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .landing-page .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .landing-page .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .landing-page .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .landing-page .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .landing-page .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .landing-page .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .landing-page .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .landing-page .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .landing-page .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .offset-xl-0 {
    margin-left: 0;
  }
  .landing-page .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .landing-page .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .landing-page .offset-xl-3 {
    margin-left: 25%;
  }
  .landing-page .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .landing-page .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .landing-page .offset-xl-6 {
    margin-left: 50%;
  }
  .landing-page .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .landing-page .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .landing-page .offset-xl-9 {
    margin-left: 75%;
  }
  .landing-page .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .landing-page .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .landing-page .g-xl-0,
.landing-page .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .landing-page .g-xl-0,
.landing-page .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .landing-page .g-xl-1,
.landing-page .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .landing-page .g-xl-1,
.landing-page .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .landing-page .g-xl-2,
.landing-page .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .landing-page .g-xl-2,
.landing-page .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .landing-page .g-xl-3,
.landing-page .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .landing-page .g-xl-3,
.landing-page .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .landing-page .g-xl-4,
.landing-page .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .landing-page .g-xl-4,
.landing-page .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .landing-page .g-xl-5,
.landing-page .gx-xl-5 {
    --bs-gutter-x: 2.5rem;
  }
  .landing-page .g-xl-5,
.landing-page .gy-xl-5 {
    --bs-gutter-y: 2.5rem;
  }
  .landing-page .g-xl-10,
.landing-page .gx-xl-10 {
    --bs-gutter-x: 6rem;
  }
  .landing-page .g-xl-10,
.landing-page .gy-xl-10 {
    --bs-gutter-y: 6rem;
  }
  .landing-page .g-xl-15,
.landing-page .gx-xl-15 {
    --bs-gutter-x: 9rem;
  }
  .landing-page .g-xl-15,
.landing-page .gy-xl-15 {
    --bs-gutter-y: 9rem;
  }
}
@media (min-width: 1500px) {
  .landing-page .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .landing-page .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .landing-page .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .landing-page .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .landing-page .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .landing-page .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .landing-page .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .landing-page .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .landing-page .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .landing-page .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .landing-page .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .landing-page .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .landing-page .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .landing-page .offset-xxl-0 {
    margin-left: 0;
  }
  .landing-page .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .landing-page .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .landing-page .offset-xxl-3 {
    margin-left: 25%;
  }
  .landing-page .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .landing-page .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .landing-page .offset-xxl-6 {
    margin-left: 50%;
  }
  .landing-page .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .landing-page .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .landing-page .offset-xxl-9 {
    margin-left: 75%;
  }
  .landing-page .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .landing-page .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .landing-page .g-xxl-0,
.landing-page .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .landing-page .g-xxl-0,
.landing-page .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .landing-page .g-xxl-1,
.landing-page .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .landing-page .g-xxl-1,
.landing-page .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .landing-page .g-xxl-2,
.landing-page .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .landing-page .g-xxl-2,
.landing-page .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .landing-page .g-xxl-3,
.landing-page .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .landing-page .g-xxl-3,
.landing-page .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .landing-page .g-xxl-4,
.landing-page .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .landing-page .g-xxl-4,
.landing-page .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .landing-page .g-xxl-5,
.landing-page .gx-xxl-5 {
    --bs-gutter-x: 2.5rem;
  }
  .landing-page .g-xxl-5,
.landing-page .gy-xxl-5 {
    --bs-gutter-y: 2.5rem;
  }
  .landing-page .g-xxl-10,
.landing-page .gx-xxl-10 {
    --bs-gutter-x: 6rem;
  }
  .landing-page .g-xxl-10,
.landing-page .gy-xxl-10 {
    --bs-gutter-y: 6rem;
  }
  .landing-page .g-xxl-15,
.landing-page .gx-xxl-15 {
    --bs-gutter-x: 9rem;
  }
  .landing-page .g-xxl-15,
.landing-page .gy-xxl-15 {
    --bs-gutter-y: 9rem;
  }
}
.landing-page .table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #69707a;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #69707a;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #69707a;
  --bs-table-hover-bg: rgba(224, 229, 236, 0.25);
  width: 100%;
  margin-bottom: 1rem;
  color: #69707a;
  vertical-align: top;
  border-color: #e0e5ec;
}
.landing-page .table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.landing-page .table > tbody {
  vertical-align: inherit;
}
.landing-page .table > thead {
  vertical-align: bottom;
}
.landing-page .table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}
.landing-page .caption-top {
  caption-side: top;
}
.landing-page .table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}
.landing-page .table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.landing-page .table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.landing-page .table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.landing-page .table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
.landing-page .table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}
.landing-page .table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}
.landing-page .table-primary {
  --bs-table-bg: #ccdffc;
  --bs-table-striped-bg: #c2d4ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8c9e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdcee9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8c9e3;
}
.landing-page .table-secondary {
  --bs-table-bg: #def3ff;
  --bs-table-striped-bg: #d3e7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8dbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cde1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c8dbe6;
}
.landing-page .table-success {
  --bs-table-bg: #cceee1;
  --bs-table-striped-bg: #c2e2d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d6cb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddcd0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d6cb;
}
.landing-page .table-info {
  --bs-table-bg: #ccf5f7;
  --bs-table-striped-bg: #c2e9eb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8ddde;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bde3e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8ddde;
}
.landing-page .table-warning {
  --bs-table-bg: #fdeccc;
  --bs-table-striped-bg: #f0e0c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4d4b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eadabd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4d4b8;
}
.landing-page .table-danger {
  --bs-table-bg: #fad0cc;
  --bs-table-striped-bg: #eec6c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1bbb8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7c0bd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1bbb8;
}
.landing-page .table-light {
  --bs-table-bg: #f2f6fc;
  --bs-table-striped-bg: #e6eaef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dadde3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e0e4e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dadde3;
}
.landing-page .table-dark {
  --bs-table-bg: #212832;
  --bs-table-striped-bg: #2c333c;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373e47;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323841;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373e47;
}
.landing-page .table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .landing-page .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .landing-page .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .landing-page .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .landing-page .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1499.98px) {
  .landing-page .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.landing-page .form-label {
  margin-bottom: 0.5rem;
}
.landing-page .col-form-label {
  padding-top: calc(0.875rem + 1px);
  padding-bottom: calc(0.875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1;
}
.landing-page .col-form-label-lg {
  padding-top: calc(1.125rem + 1px);
  padding-bottom: calc(1.125rem + 1px);
  font-size: 1rem;
}
.landing-page .col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.75rem;
}
.landing-page .form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #a7aeb8;
}
.landing-page .form-control {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-control {
    transition: none;
  }
}
.landing-page .form-control[type=file] {
  overflow: hidden;
}
.landing-page .form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.landing-page .form-control:focus {
  color: #69707a;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .form-control::-webkit-date-and-time-value {
  height: 1em;
}
.landing-page .form-control::-webkit-input-placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.landing-page .form-control::-moz-placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.landing-page .form-control::placeholder {
  color: #a7aeb8;
  opacity: 1;
}
.landing-page .form-control:disabled,
.landing-page .form-control[readonly] {
  background-color: #e0e5ec;
  opacity: 1;
}
.landing-page .form-control::file-selector-button {
  padding: 0.875rem 1.125rem;
  margin: -0.875rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
  color: #69707a;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-control::file-selector-button {
    transition: none;
  }
}
.landing-page .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #f2f2f2;
}
.landing-page .form-control::-webkit-file-upload-button {
  padding: 0.875rem 1.125rem;
  margin: -0.875rem -1.125rem;
  -webkit-margin-end: 1.125rem;
  margin-inline-end: 1.125rem;
  color: #69707a;
  background-color: #fff;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.landing-page .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #f2f2f2;
}
.landing-page .form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.875rem 0;
  margin-bottom: 0;
  line-height: 1;
  color: #69707a;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.landing-page .form-control-plaintext.form-control-sm,
.landing-page .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.landing-page .form-control-sm {
  min-height: calc(1em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.landing-page .form-control-sm::file-selector-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
}
.landing-page .form-control-sm::-webkit-file-upload-button {
  padding: 0.5rem 0.75rem;
  margin: -0.5rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
}
.landing-page .form-control-lg {
  min-height: calc(1em + 2.25rem + 2px);
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.landing-page .form-control-lg::file-selector-button {
  padding: 1.125rem 1.5rem;
  margin: -1.125rem -1.5rem;
  -webkit-margin-end: 1.5rem;
  margin-inline-end: 1.5rem;
}
.landing-page .form-control-lg::-webkit-file-upload-button {
  padding: 1.125rem 1.5rem;
  margin: -1.125rem -1.5rem;
  -webkit-margin-end: 1.5rem;
  margin-inline-end: 1.5rem;
}
.landing-page textarea.form-control {
  min-height: calc(1em + 1.75rem + 2px);
}
.landing-page textarea.form-control-sm {
  min-height: calc(1em + 1rem + 2px);
}
.landing-page textarea.form-control-lg {
  min-height: calc(1em + 2.25rem + 2px);
}
.landing-page .form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.875rem;
}
.landing-page .form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.landing-page .form-control-color::-moz-color-swatch {
  height: 1em;
  border-radius: 0.35rem;
}
.landing-page .form-control-color::-webkit-color-swatch {
  height: 1em;
  border-radius: 0.35rem;
}
.landing-page .form-select {
  display: block;
  width: 100%;
  padding: 0.875rem 3.375rem 0.875rem 1.125rem;
  -moz-padding-start: calc(1.125rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.125rem center;
  background-size: 16px 12px;
  border: 1px solid #c5ccd6;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-select {
    transition: none;
  }
}
.landing-page .form-select:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .form-select[multiple],
.landing-page .form-select[size]:not([size="1"]) {
  padding-right: 1.125rem;
  background-image: none;
}
.landing-page .form-select:disabled {
  background-color: #e0e5ec;
}
.landing-page .form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #69707a;
}
.landing-page .form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 0.75rem;
}
.landing-page .form-select-lg {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  padding-left: 1.5rem;
  font-size: 1rem;
}
.landing-page .form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.landing-page .form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.landing-page .form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.landing-page .form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.landing-page .form-check-input[type=radio] {
  border-radius: 50%;
}
.landing-page .form-check-input:active {
  filter: brightness(90%);
}
.landing-page .form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .form-check-input:checked {
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.landing-page .form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.landing-page .form-check-input[type=checkbox]:indeterminate {
  background-color: #0061f2;
  border-color: #0061f2;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.landing-page .form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.landing-page .form-check-input[disabled] ~ .form-check-label,
.landing-page .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}
.landing-page .form-switch {
  padding-left: 2.5em;
}
.landing-page .form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-switch .form-check-input {
    transition: none;
  }
}
.landing-page .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='transparent'/%3e%3c/svg%3e");
}
.landing-page .form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.landing-page .form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.landing-page .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.landing-page .btn-check[disabled] + .btn,
.landing-page .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.landing-page .form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.landing-page .form-range:focus {
  outline: 0;
}
.landing-page .form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f2f6fc, 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f2f6fc, 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .form-range::-moz-focus-outer {
  border: 0;
}
.landing-page .form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0061f2;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.landing-page .form-range::-webkit-slider-thumb:active {
  background-color: #b3d0fb;
}
.landing-page .form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d4dae3;
  border-color: transparent;
  border-radius: 1rem;
}
.landing-page .form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0061f2;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.landing-page .form-range::-moz-range-thumb:active {
  background-color: #b3d0fb;
}
.landing-page .form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #d4dae3;
  border-color: transparent;
  border-radius: 1rem;
}
.landing-page .form-range:disabled {
  pointer-events: none;
}
.landing-page .form-range:disabled::-webkit-slider-thumb {
  background-color: #a7aeb8;
}
.landing-page .form-range:disabled::-moz-range-thumb {
  background-color: #a7aeb8;
}
.landing-page .form-floating {
  position: relative;
}
.landing-page .form-floating > .form-control,
.landing-page .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.landing-page .form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.125rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .form-floating > label {
    transition: none;
  }
}
.landing-page .form-floating > .form-control {
  padding: 1rem 1.125rem;
}
.landing-page .form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.landing-page .form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.landing-page .form-floating > .form-control::placeholder {
  color: transparent;
}
.landing-page .form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.landing-page .form-floating > .form-control:focus,
.landing-page .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.landing-page .form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.landing-page .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.landing-page .form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.landing-page .form-floating > .form-control:focus ~ label,
.landing-page .form-floating > .form-control:not(:placeholder-shown) ~ label,
.landing-page .form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.landing-page .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.landing-page .input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.landing-page .input-group > .form-control,
.landing-page .input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.landing-page .input-group > .form-control:focus,
.landing-page .input-group > .form-select:focus {
  z-index: 3;
}
.landing-page .input-group .btn {
  position: relative;
  z-index: 2;
}
.landing-page .input-group .btn:focus {
  z-index: 3;
}
.landing-page .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #c5ccd6;
  border-radius: 0.35rem;
}
.landing-page .input-group-lg > .form-control,
.landing-page .input-group-lg > .form-select,
.landing-page .input-group-lg > .input-group-text,
.landing-page .input-group-lg > .btn {
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.landing-page .input-group-sm > .form-control,
.landing-page .input-group-sm > .form-select,
.landing-page .input-group-sm > .input-group-text,
.landing-page .input-group-sm > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.landing-page .input-group-lg > .form-select,
.landing-page .input-group-sm > .form-select {
  padding-right: 4.5rem;
}
.landing-page .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.landing-page .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.landing-page .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.landing-page .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.landing-page .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.landing-page .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00ac69;
}
.landing-page .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(0, 172, 105, 0.9);
  border-radius: 0.35rem;
}
.landing-page .was-validated :valid ~ .valid-feedback,
.landing-page .was-validated :valid ~ .valid-tooltip,
.landing-page .is-valid ~ .valid-feedback,
.landing-page .is-valid ~ .valid-tooltip {
  display: block;
}
.landing-page .was-validated .form-control:valid,
.landing-page .form-control.is-valid {
  border-color: #00ac69;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.landing-page .was-validated .form-control:valid:focus,
.landing-page .form-control.is-valid:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}
.landing-page .was-validated textarea.form-control:valid,
.landing-page textarea.form-control.is-valid {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}
.landing-page .was-validated .form-select:valid,
.landing-page .form-select.is-valid {
  border-color: #00ac69;
}
.landing-page .was-validated .form-select:valid:not([multiple]):not([size]),
.landing-page .was-validated .form-select:valid:not([multiple])[size="1"],
.landing-page .form-select.is-valid:not([multiple]):not([size]),
.landing-page .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300ac69' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.landing-page .was-validated .form-select:valid:focus,
.landing-page .form-select.is-valid:focus {
  border-color: #00ac69;
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}
.landing-page .was-validated .form-check-input:valid,
.landing-page .form-check-input.is-valid {
  border-color: #00ac69;
}
.landing-page .was-validated .form-check-input:valid:checked,
.landing-page .form-check-input.is-valid:checked {
  background-color: #00ac69;
}
.landing-page .was-validated .form-check-input:valid:focus,
.landing-page .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.25);
}
.landing-page .was-validated .form-check-input:valid ~ .form-check-label,
.landing-page .form-check-input.is-valid ~ .form-check-label {
  color: #00ac69;
}
.landing-page .form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.landing-page .was-validated .input-group .form-control:valid,
.landing-page .input-group .form-control.is-valid,
.landing-page .was-validated .input-group .form-select:valid,
.landing-page .input-group .form-select.is-valid {
  z-index: 1;
}
.landing-page .was-validated .input-group .form-control:valid:focus,
.landing-page .input-group .form-control.is-valid:focus,
.landing-page .was-validated .input-group .form-select:valid:focus,
.landing-page .input-group .form-select.is-valid:focus {
  z-index: 3;
}
.landing-page .invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #e81500;
}
.landing-page .invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(232, 21, 0, 0.9);
  border-radius: 0.35rem;
}
.landing-page .was-validated :invalid ~ .invalid-feedback,
.landing-page .was-validated :invalid ~ .invalid-tooltip,
.landing-page .is-invalid ~ .invalid-feedback,
.landing-page .is-invalid ~ .invalid-tooltip {
  display: block;
}
.landing-page .was-validated .form-control:invalid,
.landing-page .form-control.is-invalid {
  border-color: #e81500;
  padding-right: calc(1em + 1.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e81500'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.4375rem) center;
  background-size: calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.landing-page .was-validated .form-control:invalid:focus,
.landing-page .form-control.is-invalid:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}
.landing-page .was-validated textarea.form-control:invalid,
.landing-page textarea.form-control.is-invalid {
  padding-right: calc(1em + 1.75rem);
  background-position: top calc(0.25em + 0.4375rem) right calc(0.25em + 0.4375rem);
}
.landing-page .was-validated .form-select:invalid,
.landing-page .form-select.is-invalid {
  border-color: #e81500;
}
.landing-page .was-validated .form-select:invalid:not([multiple]):not([size]),
.landing-page .was-validated .form-select:invalid:not([multiple])[size="1"],
.landing-page .form-select.is-invalid:not([multiple]):not([size]),
.landing-page .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 6.1875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23363d47' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e81500'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e81500' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.125rem center, center right 3.375rem;
  background-size: 16px 12px, calc(0.5em + 0.875rem) calc(0.5em + 0.875rem);
}
.landing-page .was-validated .form-select:invalid:focus,
.landing-page .form-select.is-invalid:focus {
  border-color: #e81500;
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}
.landing-page .was-validated .form-check-input:invalid,
.landing-page .form-check-input.is-invalid {
  border-color: #e81500;
}
.landing-page .was-validated .form-check-input:invalid:checked,
.landing-page .form-check-input.is-invalid:checked {
  background-color: #e81500;
}
.landing-page .was-validated .form-check-input:invalid:focus,
.landing-page .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.25);
}
.landing-page .was-validated .form-check-input:invalid ~ .form-check-label,
.landing-page .form-check-input.is-invalid ~ .form-check-label {
  color: #e81500;
}
.landing-page .form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.landing-page .was-validated .input-group .form-control:invalid,
.landing-page .input-group .form-control.is-invalid,
.landing-page .was-validated .input-group .form-select:invalid,
.landing-page .input-group .form-select.is-invalid {
  z-index: 2;
}
.landing-page .was-validated .input-group .form-control:invalid:focus,
.landing-page .input-group .form-control.is-invalid:focus,
.landing-page .was-validated .input-group .form-select:invalid:focus,
.landing-page .input-group .form-select.is-invalid:focus {
  z-index: 3;
}
.landing-page .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  border-radius: 0.35rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .btn {
    transition: none;
  }
}
.landing-page .btn:hover {
  color: #69707a;
  text-decoration: none;
}
.landing-page .btn-check:focus + .btn,
.landing-page .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .btn:disabled,
.landing-page .btn.disabled,
.landing-page fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.landing-page .btn-primary {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-primary:hover {
  color: #fff;
  background-color: #0052ce;
  border-color: #004ec2;
}
.landing-page .btn-check:focus + .btn-primary,
.landing-page .btn-primary:focus {
  color: #fff;
  background-color: #0052ce;
  border-color: #004ec2;
  box-shadow: 0 0 0 0.25rem rgba(38, 121, 244, 0.5);
}
.landing-page .btn-check:checked + .btn-primary,
.landing-page .btn-check:active + .btn-primary,
.landing-page .btn-primary:active,
.landing-page .btn-primary.active,
.landing-page .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004ec2;
  border-color: #0049b6;
}
.landing-page .btn-check:checked + .btn-primary:focus,
.landing-page .btn-check:active + .btn-primary:focus,
.landing-page .btn-primary:active:focus,
.landing-page .btn-primary.active:focus,
.landing-page .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 121, 244, 0.5);
}
.landing-page .btn-primary:disabled,
.landing-page .btn-primary.disabled {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-secondary {
  color: #fff;
  background-color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .btn-secondary:hover {
  color: #fff;
  background-color: #4da5d9;
  border-color: #499bcc;
}
.landing-page .btn-check:focus + .btn-secondary,
.landing-page .btn-secondary:focus {
  color: #fff;
  background-color: #4da5d9;
  border-color: #499bcc;
  box-shadow: 0 0 0 0.25rem rgba(116, 203, 255, 0.5);
}
.landing-page .btn-check:checked + .btn-secondary,
.landing-page .btn-check:active + .btn-secondary,
.landing-page .btn-secondary:active,
.landing-page .btn-secondary.active,
.landing-page .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #499bcc;
  border-color: #4492bf;
}
.landing-page .btn-check:checked + .btn-secondary:focus,
.landing-page .btn-check:active + .btn-secondary:focus,
.landing-page .btn-secondary:active:focus,
.landing-page .btn-secondary.active:focus,
.landing-page .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(116, 203, 255, 0.5);
}
.landing-page .btn-secondary:disabled,
.landing-page .btn-secondary.disabled {
  color: #fff;
  background-color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .btn-success {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-success:hover {
  color: #fff;
  background-color: #009259;
  border-color: #008a54;
}
.landing-page .btn-check:focus + .btn-success,
.landing-page .btn-success:focus {
  color: #fff;
  background-color: #009259;
  border-color: #008a54;
  box-shadow: 0 0 0 0.25rem rgba(38, 184, 128, 0.5);
}
.landing-page .btn-check:checked + .btn-success,
.landing-page .btn-check:active + .btn-success,
.landing-page .btn-success:active,
.landing-page .btn-success.active,
.landing-page .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #008a54;
  border-color: #00814f;
}
.landing-page .btn-check:checked + .btn-success:focus,
.landing-page .btn-check:active + .btn-success:focus,
.landing-page .btn-success:active:focus,
.landing-page .btn-success.active:focus,
.landing-page .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 184, 128, 0.5);
}
.landing-page .btn-success:disabled,
.landing-page .btn-success.disabled {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-info {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-info:hover {
  color: #fff;
  background-color: #00b0b5;
  border-color: #00a6aa;
}
.landing-page .btn-check:focus + .btn-info,
.landing-page .btn-info:focus {
  color: #fff;
  background-color: #00b0b5;
  border-color: #00a6aa;
  box-shadow: 0 0 0 0.25rem rgba(38, 214, 219, 0.5);
}
.landing-page .btn-check:checked + .btn-info,
.landing-page .btn-check:active + .btn-info,
.landing-page .btn-info:active,
.landing-page .btn-info.active,
.landing-page .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00a6aa;
  border-color: #009ba0;
}
.landing-page .btn-check:checked + .btn-info:focus,
.landing-page .btn-check:active + .btn-info:focus,
.landing-page .btn-info:active:focus,
.landing-page .btn-info.active:focus,
.landing-page .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 214, 219, 0.5);
}
.landing-page .btn-info:disabled,
.landing-page .btn-info.disabled {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-warning {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-warning:hover {
  color: #fff;
  background-color: #cf8900;
  border-color: #c38100;
}
.landing-page .btn-check:focus + .btn-warning,
.landing-page .btn-warning:focus {
  color: #fff;
  background-color: #cf8900;
  border-color: #c38100;
  box-shadow: 0 0 0 0.25rem rgba(246, 175, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-warning,
.landing-page .btn-check:active + .btn-warning,
.landing-page .btn-warning:active,
.landing-page .btn-warning.active,
.landing-page .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c38100;
  border-color: #b77900;
}
.landing-page .btn-check:checked + .btn-warning:focus,
.landing-page .btn-check:active + .btn-warning:focus,
.landing-page .btn-warning:active:focus,
.landing-page .btn-warning.active:focus,
.landing-page .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 175, 38, 0.5);
}
.landing-page .btn-warning:disabled,
.landing-page .btn-warning.disabled {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-danger {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-danger:hover {
  color: #fff;
  background-color: #c51200;
  border-color: #ba1100;
}
.landing-page .btn-check:focus + .btn-danger,
.landing-page .btn-danger:focus {
  color: #fff;
  background-color: #c51200;
  border-color: #ba1100;
  box-shadow: 0 0 0 0.25rem rgba(235, 56, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-danger,
.landing-page .btn-check:active + .btn-danger,
.landing-page .btn-danger:active,
.landing-page .btn-danger.active,
.landing-page .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ba1100;
  border-color: #ae1000;
}
.landing-page .btn-check:checked + .btn-danger:focus,
.landing-page .btn-check:active + .btn-danger:focus,
.landing-page .btn-danger:active:focus,
.landing-page .btn-danger.active:focus,
.landing-page .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 56, 38, 0.5);
}
.landing-page .btn-danger:disabled,
.landing-page .btn-danger.disabled {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-light {
  color: #000;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .btn-light:hover {
  color: #000;
  background-color: #f4f7fc;
  border-color: #f3f7fc;
}
.landing-page .btn-check:focus + .btn-light,
.landing-page .btn-light:focus {
  color: #000;
  background-color: #f4f7fc;
  border-color: #f3f7fc;
  box-shadow: 0 0 0 0.25rem rgba(206, 209, 214, 0.5);
}
.landing-page .btn-check:checked + .btn-light,
.landing-page .btn-check:active + .btn-light,
.landing-page .btn-light:active,
.landing-page .btn-light.active,
.landing-page .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f5f8fd;
  border-color: #f3f7fc;
}
.landing-page .btn-check:checked + .btn-light:focus,
.landing-page .btn-check:active + .btn-light:focus,
.landing-page .btn-light:active:focus,
.landing-page .btn-light.active:focus,
.landing-page .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 209, 214, 0.5);
}
.landing-page .btn-light:disabled,
.landing-page .btn-light.disabled {
  color: #000;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .btn-dark {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.landing-page .btn-dark:hover {
  color: #fff;
  background-color: #1c222b;
  border-color: #1a2028;
}
.landing-page .btn-check:focus + .btn-dark,
.landing-page .btn-dark:focus {
  color: #fff;
  background-color: #1c222b;
  border-color: #1a2028;
  box-shadow: 0 0 0 0.25rem rgba(66, 72, 81, 0.5);
}
.landing-page .btn-check:checked + .btn-dark,
.landing-page .btn-check:active + .btn-dark,
.landing-page .btn-dark:active,
.landing-page .btn-dark.active,
.landing-page .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a2028;
  border-color: #191e26;
}
.landing-page .btn-check:checked + .btn-dark:focus,
.landing-page .btn-check:active + .btn-dark:focus,
.landing-page .btn-dark:active:focus,
.landing-page .btn-dark.active:focus,
.landing-page .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 72, 81, 0.5);
}
.landing-page .btn-dark:disabled,
.landing-page .btn-dark.disabled {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.landing-page .btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.landing-page .btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.landing-page .btn-check:focus + .btn-black,
.landing-page .btn-black:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-black,
.landing-page .btn-check:active + .btn-black,
.landing-page .btn-black:active,
.landing-page .btn-black.active,
.landing-page .show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.landing-page .btn-check:checked + .btn-black:focus,
.landing-page .btn-check:active + .btn-black:focus,
.landing-page .btn-black:active:focus,
.landing-page .btn-black.active:focus,
.landing-page .show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.landing-page .btn-black:disabled,
.landing-page .btn-black.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.landing-page .btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.landing-page .btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.landing-page .btn-check:focus + .btn-white,
.landing-page .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.landing-page .btn-check:checked + .btn-white,
.landing-page .btn-check:active + .btn-white,
.landing-page .btn-white:active,
.landing-page .btn-white.active,
.landing-page .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.landing-page .btn-check:checked + .btn-white:focus,
.landing-page .btn-check:active + .btn-white:focus,
.landing-page .btn-white:active:focus,
.landing-page .btn-white.active:focus,
.landing-page .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.landing-page .btn-white:disabled,
.landing-page .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.landing-page .btn-red {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-red:hover {
  color: #fff;
  background-color: #c51200;
  border-color: #ba1100;
}
.landing-page .btn-check:focus + .btn-red,
.landing-page .btn-red:focus {
  color: #fff;
  background-color: #c51200;
  border-color: #ba1100;
  box-shadow: 0 0 0 0.25rem rgba(235, 56, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-red,
.landing-page .btn-check:active + .btn-red,
.landing-page .btn-red:active,
.landing-page .btn-red.active,
.landing-page .show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #ba1100;
  border-color: #ae1000;
}
.landing-page .btn-check:checked + .btn-red:focus,
.landing-page .btn-check:active + .btn-red:focus,
.landing-page .btn-red:active:focus,
.landing-page .btn-red.active:focus,
.landing-page .show > .btn-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(235, 56, 38, 0.5);
}
.landing-page .btn-red:disabled,
.landing-page .btn-red.disabled {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-orange {
  color: #fff;
  background-color: #f76400;
  border-color: #f76400;
}
.landing-page .btn-orange:hover {
  color: #fff;
  background-color: #d25500;
  border-color: #c65000;
}
.landing-page .btn-check:focus + .btn-orange,
.landing-page .btn-orange:focus {
  color: #fff;
  background-color: #d25500;
  border-color: #c65000;
  box-shadow: 0 0 0 0.25rem rgba(248, 123, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-orange,
.landing-page .btn-check:active + .btn-orange,
.landing-page .btn-orange:active,
.landing-page .btn-orange.active,
.landing-page .show > .btn-orange.dropdown-toggle {
  color: #fff;
  background-color: #c65000;
  border-color: #b94b00;
}
.landing-page .btn-check:checked + .btn-orange:focus,
.landing-page .btn-check:active + .btn-orange:focus,
.landing-page .btn-orange:active:focus,
.landing-page .btn-orange.active:focus,
.landing-page .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 123, 38, 0.5);
}
.landing-page .btn-orange:disabled,
.landing-page .btn-orange.disabled {
  color: #fff;
  background-color: #f76400;
  border-color: #f76400;
}
.landing-page .btn-yellow {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-yellow:hover {
  color: #fff;
  background-color: #cf8900;
  border-color: #c38100;
}
.landing-page .btn-check:focus + .btn-yellow,
.landing-page .btn-yellow:focus {
  color: #fff;
  background-color: #cf8900;
  border-color: #c38100;
  box-shadow: 0 0 0 0.25rem rgba(246, 175, 38, 0.5);
}
.landing-page .btn-check:checked + .btn-yellow,
.landing-page .btn-check:active + .btn-yellow,
.landing-page .btn-yellow:active,
.landing-page .btn-yellow.active,
.landing-page .show > .btn-yellow.dropdown-toggle {
  color: #fff;
  background-color: #c38100;
  border-color: #b77900;
}
.landing-page .btn-check:checked + .btn-yellow:focus,
.landing-page .btn-check:active + .btn-yellow:focus,
.landing-page .btn-yellow:active:focus,
.landing-page .btn-yellow.active:focus,
.landing-page .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(246, 175, 38, 0.5);
}
.landing-page .btn-yellow:disabled,
.landing-page .btn-yellow.disabled {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-green {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-green:hover {
  color: #fff;
  background-color: #009259;
  border-color: #008a54;
}
.landing-page .btn-check:focus + .btn-green,
.landing-page .btn-green:focus {
  color: #fff;
  background-color: #009259;
  border-color: #008a54;
  box-shadow: 0 0 0 0.25rem rgba(38, 184, 128, 0.5);
}
.landing-page .btn-check:checked + .btn-green,
.landing-page .btn-check:active + .btn-green,
.landing-page .btn-green:active,
.landing-page .btn-green.active,
.landing-page .show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #008a54;
  border-color: #00814f;
}
.landing-page .btn-check:checked + .btn-green:focus,
.landing-page .btn-check:active + .btn-green:focus,
.landing-page .btn-green:active:focus,
.landing-page .btn-green.active:focus,
.landing-page .show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 184, 128, 0.5);
}
.landing-page .btn-green:disabled,
.landing-page .btn-green.disabled {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-teal {
  color: #fff;
  background-color: #00ba94;
  border-color: #00ba94;
}
.landing-page .btn-teal:hover {
  color: #fff;
  background-color: #009e7e;
  border-color: #009576;
}
.landing-page .btn-check:focus + .btn-teal,
.landing-page .btn-teal:focus {
  color: #fff;
  background-color: #009e7e;
  border-color: #009576;
  box-shadow: 0 0 0 0.25rem rgba(38, 196, 164, 0.5);
}
.landing-page .btn-check:checked + .btn-teal,
.landing-page .btn-check:active + .btn-teal,
.landing-page .btn-teal:active,
.landing-page .btn-teal.active,
.landing-page .show > .btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #009576;
  border-color: #008c6f;
}
.landing-page .btn-check:checked + .btn-teal:focus,
.landing-page .btn-check:active + .btn-teal:focus,
.landing-page .btn-teal:active:focus,
.landing-page .btn-teal.active:focus,
.landing-page .show > .btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 196, 164, 0.5);
}
.landing-page .btn-teal:disabled,
.landing-page .btn-teal.disabled {
  color: #fff;
  background-color: #00ba94;
  border-color: #00ba94;
}
.landing-page .btn-cyan {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-cyan:hover {
  color: #fff;
  background-color: #00b0b5;
  border-color: #00a6aa;
}
.landing-page .btn-check:focus + .btn-cyan,
.landing-page .btn-cyan:focus {
  color: #fff;
  background-color: #00b0b5;
  border-color: #00a6aa;
  box-shadow: 0 0 0 0.25rem rgba(38, 214, 219, 0.5);
}
.landing-page .btn-check:checked + .btn-cyan,
.landing-page .btn-check:active + .btn-cyan,
.landing-page .btn-cyan:active,
.landing-page .btn-cyan.active,
.landing-page .show > .btn-cyan.dropdown-toggle {
  color: #fff;
  background-color: #00a6aa;
  border-color: #009ba0;
}
.landing-page .btn-check:checked + .btn-cyan:focus,
.landing-page .btn-check:active + .btn-cyan:focus,
.landing-page .btn-cyan:active:focus,
.landing-page .btn-cyan.active:focus,
.landing-page .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 214, 219, 0.5);
}
.landing-page .btn-cyan:disabled,
.landing-page .btn-cyan.disabled {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-blue {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-blue:hover {
  color: #fff;
  background-color: #0052ce;
  border-color: #004ec2;
}
.landing-page .btn-check:focus + .btn-blue,
.landing-page .btn-blue:focus {
  color: #fff;
  background-color: #0052ce;
  border-color: #004ec2;
  box-shadow: 0 0 0 0.25rem rgba(38, 121, 244, 0.5);
}
.landing-page .btn-check:checked + .btn-blue,
.landing-page .btn-check:active + .btn-blue,
.landing-page .btn-blue:active,
.landing-page .btn-blue.active,
.landing-page .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #004ec2;
  border-color: #0049b6;
}
.landing-page .btn-check:checked + .btn-blue:focus,
.landing-page .btn-check:active + .btn-blue:focus,
.landing-page .btn-blue:active:focus,
.landing-page .btn-blue.active:focus,
.landing-page .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(38, 121, 244, 0.5);
}
.landing-page .btn-blue:disabled,
.landing-page .btn-blue.disabled {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-indigo {
  color: #fff;
  background-color: #5800e8;
  border-color: #5800e8;
}
.landing-page .btn-indigo:hover {
  color: #fff;
  background-color: #4b00c5;
  border-color: #4600ba;
}
.landing-page .btn-check:focus + .btn-indigo,
.landing-page .btn-indigo:focus {
  color: #fff;
  background-color: #4b00c5;
  border-color: #4600ba;
  box-shadow: 0 0 0 0.25rem rgba(113, 38, 235, 0.5);
}
.landing-page .btn-check:checked + .btn-indigo,
.landing-page .btn-check:active + .btn-indigo,
.landing-page .btn-indigo:active,
.landing-page .btn-indigo.active,
.landing-page .show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #4600ba;
  border-color: #4200ae;
}
.landing-page .btn-check:checked + .btn-indigo:focus,
.landing-page .btn-check:active + .btn-indigo:focus,
.landing-page .btn-indigo:active:focus,
.landing-page .btn-indigo.active:focus,
.landing-page .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(113, 38, 235, 0.5);
}
.landing-page .btn-indigo:disabled,
.landing-page .btn-indigo.disabled {
  color: #fff;
  background-color: #5800e8;
  border-color: #5800e8;
}
.landing-page .btn-purple {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.landing-page .btn-purple:hover {
  color: #fff;
  background-color: #5900a9;
  border-color: #54009f;
}
.landing-page .btn-check:focus + .btn-purple,
.landing-page .btn-purple:focus {
  color: #fff;
  background-color: #5900a9;
  border-color: #54009f;
  box-shadow: 0 0 0 0.25rem rgba(128, 38, 207, 0.5);
}
.landing-page .btn-check:checked + .btn-purple,
.landing-page .btn-check:active + .btn-purple,
.landing-page .btn-purple:active,
.landing-page .btn-purple.active,
.landing-page .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #54009f;
  border-color: #4f0095;
}
.landing-page .btn-check:checked + .btn-purple:focus,
.landing-page .btn-check:active + .btn-purple:focus,
.landing-page .btn-purple:active:focus,
.landing-page .btn-purple.active:focus,
.landing-page .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(128, 38, 207, 0.5);
}
.landing-page .btn-purple:disabled,
.landing-page .btn-purple.disabled {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.landing-page .btn-pink {
  color: #fff;
  background-color: #e30059;
  border-color: #e30059;
}
.landing-page .btn-pink:hover {
  color: #fff;
  background-color: #c1004c;
  border-color: #b60047;
}
.landing-page .btn-check:focus + .btn-pink,
.landing-page .btn-pink:focus {
  color: #fff;
  background-color: #c1004c;
  border-color: #b60047;
  box-shadow: 0 0 0 0.25rem rgba(231, 38, 114, 0.5);
}
.landing-page .btn-check:checked + .btn-pink,
.landing-page .btn-check:active + .btn-pink,
.landing-page .btn-pink:active,
.landing-page .btn-pink.active,
.landing-page .show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #b60047;
  border-color: #aa0043;
}
.landing-page .btn-check:checked + .btn-pink:focus,
.landing-page .btn-check:active + .btn-pink:focus,
.landing-page .btn-pink:active:focus,
.landing-page .btn-pink.active:focus,
.landing-page .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(231, 38, 114, 0.5);
}
.landing-page .btn-pink:disabled,
.landing-page .btn-pink.disabled {
  color: #fff;
  background-color: #e30059;
  border-color: #e30059;
}
.landing-page .btn-red-soft {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-red-soft:hover {
  color: #000;
  background-color: #f3e5e7;
  border-color: #f2e3e6;
}
.landing-page .btn-check:focus + .btn-red-soft,
.landing-page .btn-red-soft:focus {
  color: #000;
  background-color: #f3e5e7;
  border-color: #f2e3e6;
  box-shadow: 0 0 0 0.25rem rgba(205, 190, 193, 0.5);
}
.landing-page .btn-check:checked + .btn-red-soft,
.landing-page .btn-check:active + .btn-red-soft,
.landing-page .btn-red-soft:active,
.landing-page .btn-red-soft.active,
.landing-page .show > .btn-red-soft.dropdown-toggle {
  color: #000;
  background-color: #f4e6e9;
  border-color: #f2e3e6;
}
.landing-page .btn-check:checked + .btn-red-soft:focus,
.landing-page .btn-check:active + .btn-red-soft:focus,
.landing-page .btn-red-soft:active:focus,
.landing-page .btn-red-soft.active:focus,
.landing-page .show > .btn-red-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 190, 193, 0.5);
}
.landing-page .btn-red-soft:disabled,
.landing-page .btn-red-soft.disabled {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-orange-soft {
  color: #000;
  background-color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .btn-orange-soft:hover {
  color: #000;
  background-color: #f5ebe7;
  border-color: #f4e9e6;
}
.landing-page .btn-check:focus + .btn-orange-soft,
.landing-page .btn-orange-soft:focus {
  color: #000;
  background-color: #f5ebe7;
  border-color: #f4e9e6;
  box-shadow: 0 0 0 0.25rem rgba(207, 196, 193, 0.5);
}
.landing-page .btn-check:checked + .btn-orange-soft,
.landing-page .btn-check:active + .btn-orange-soft,
.landing-page .btn-orange-soft:active,
.landing-page .btn-orange-soft.active,
.landing-page .show > .btn-orange-soft.dropdown-toggle {
  color: #000;
  background-color: #f5ece9;
  border-color: #f4e9e6;
}
.landing-page .btn-check:checked + .btn-orange-soft:focus,
.landing-page .btn-check:active + .btn-orange-soft:focus,
.landing-page .btn-orange-soft:active:focus,
.landing-page .btn-orange-soft.active:focus,
.landing-page .show > .btn-orange-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(207, 196, 193, 0.5);
}
.landing-page .btn-orange-soft:disabled,
.landing-page .btn-orange-soft.disabled {
  color: #000;
  background-color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .btn-yellow-soft {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-yellow-soft:hover {
  color: #000;
  background-color: #f4f1e7;
  border-color: #f3f0e6;
}
.landing-page .btn-check:focus + .btn-yellow-soft,
.landing-page .btn-yellow-soft:focus {
  color: #000;
  background-color: #f4f1e7;
  border-color: #f3f0e6;
  box-shadow: 0 0 0 0.25rem rgba(206, 202, 193, 0.5);
}
.landing-page .btn-check:checked + .btn-yellow-soft,
.landing-page .btn-check:active + .btn-yellow-soft,
.landing-page .btn-yellow-soft:active,
.landing-page .btn-yellow-soft.active,
.landing-page .show > .btn-yellow-soft.dropdown-toggle {
  color: #000;
  background-color: #f5f1e9;
  border-color: #f3f0e6;
}
.landing-page .btn-check:checked + .btn-yellow-soft:focus,
.landing-page .btn-check:active + .btn-yellow-soft:focus,
.landing-page .btn-yellow-soft:active:focus,
.landing-page .btn-yellow-soft.active:focus,
.landing-page .show > .btn-yellow-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 202, 193, 0.5);
}
.landing-page .btn-yellow-soft:disabled,
.landing-page .btn-yellow-soft.disabled {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-green-soft {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-green-soft:hover {
  color: #000;
  background-color: #e0f1f0;
  border-color: #def1ef;
}
.landing-page .btn-check:focus + .btn-green-soft,
.landing-page .btn-green-soft:focus {
  color: #000;
  background-color: #e0f1f0;
  border-color: #def1ef;
  box-shadow: 0 0 0 0.25rem rgba(185, 203, 201, 0.5);
}
.landing-page .btn-check:checked + .btn-green-soft,
.landing-page .btn-check:active + .btn-green-soft,
.landing-page .btn-green-soft:active,
.landing-page .btn-green-soft.active,
.landing-page .show > .btn-green-soft.dropdown-toggle {
  color: #000;
  background-color: #e1f2f1;
  border-color: #def1ef;
}
.landing-page .btn-check:checked + .btn-green-soft:focus,
.landing-page .btn-check:active + .btn-green-soft:focus,
.landing-page .btn-green-soft:active:focus,
.landing-page .btn-green-soft.active:focus,
.landing-page .show > .btn-green-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 203, 201, 0.5);
}
.landing-page .btn-green-soft:disabled,
.landing-page .btn-green-soft.disabled {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-teal-soft {
  color: #000;
  background-color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .btn-teal-soft:hover {
  color: #000;
  background-color: #e0f2f4;
  border-color: #def2f3;
}
.landing-page .btn-check:focus + .btn-teal-soft,
.landing-page .btn-teal-soft:focus {
  color: #000;
  background-color: #e0f2f4;
  border-color: #def2f3;
  box-shadow: 0 0 0 0.25rem rgba(185, 204, 206, 0.5);
}
.landing-page .btn-check:checked + .btn-teal-soft,
.landing-page .btn-check:active + .btn-teal-soft,
.landing-page .btn-teal-soft:active,
.landing-page .btn-teal-soft.active,
.landing-page .show > .btn-teal-soft.dropdown-toggle {
  color: #000;
  background-color: #e1f3f5;
  border-color: #def2f3;
}
.landing-page .btn-check:checked + .btn-teal-soft:focus,
.landing-page .btn-check:active + .btn-teal-soft:focus,
.landing-page .btn-teal-soft:active:focus,
.landing-page .btn-teal-soft.active:focus,
.landing-page .show > .btn-teal-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 204, 206, 0.5);
}
.landing-page .btn-teal-soft:disabled,
.landing-page .btn-teal-soft.disabled {
  color: #000;
  background-color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .btn-cyan-soft {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-cyan-soft:hover {
  color: #000;
  background-color: #e0f4f9;
  border-color: #def3f9;
}
.landing-page .btn-check:focus + .btn-cyan-soft,
.landing-page .btn-cyan-soft:focus {
  color: #000;
  background-color: #e0f4f9;
  border-color: #def3f9;
  box-shadow: 0 0 0 0.25rem rgba(185, 206, 211, 0.5);
}
.landing-page .btn-check:checked + .btn-cyan-soft,
.landing-page .btn-check:active + .btn-cyan-soft,
.landing-page .btn-cyan-soft:active,
.landing-page .btn-cyan-soft.active,
.landing-page .show > .btn-cyan-soft.dropdown-toggle {
  color: #000;
  background-color: #e1f5f9;
  border-color: #def3f9;
}
.landing-page .btn-check:checked + .btn-cyan-soft:focus,
.landing-page .btn-check:active + .btn-cyan-soft:focus,
.landing-page .btn-cyan-soft:active:focus,
.landing-page .btn-cyan-soft.active:focus,
.landing-page .show > .btn-cyan-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 206, 211, 0.5);
}
.landing-page .btn-cyan-soft:disabled,
.landing-page .btn-cyan-soft.disabled {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-blue-soft {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-blue-soft:hover {
  color: #000;
  background-color: #e0ebfc;
  border-color: #dee9fb;
}
.landing-page .btn-check:focus + .btn-blue-soft,
.landing-page .btn-blue-soft:focus {
  color: #000;
  background-color: #e0ebfc;
  border-color: #dee9fb;
  box-shadow: 0 0 0 0.25rem rgba(185, 196, 213, 0.5);
}
.landing-page .btn-check:checked + .btn-blue-soft,
.landing-page .btn-check:active + .btn-blue-soft,
.landing-page .btn-blue-soft:active,
.landing-page .btn-blue-soft.active,
.landing-page .show > .btn-blue-soft.dropdown-toggle {
  color: #000;
  background-color: #e1ecfc;
  border-color: #dee9fb;
}
.landing-page .btn-check:checked + .btn-blue-soft:focus,
.landing-page .btn-check:active + .btn-blue-soft:focus,
.landing-page .btn-blue-soft:active:focus,
.landing-page .btn-blue-soft.active:focus,
.landing-page .show > .btn-blue-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 196, 213, 0.5);
}
.landing-page .btn-blue-soft:disabled,
.landing-page .btn-blue-soft.disabled {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-indigo-soft {
  color: #000;
  background-color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .btn-indigo-soft:hover {
  color: #000;
  background-color: #e7e2fb;
  border-color: #e6e0fb;
}
.landing-page .btn-check:focus + .btn-indigo-soft,
.landing-page .btn-indigo-soft:focus {
  color: #000;
  background-color: #e7e2fb;
  border-color: #e6e0fb;
  box-shadow: 0 0 0 0.25rem rgba(193, 188, 213, 0.5);
}
.landing-page .btn-check:checked + .btn-indigo-soft,
.landing-page .btn-check:active + .btn-indigo-soft,
.landing-page .btn-indigo-soft:active,
.landing-page .btn-indigo-soft.active,
.landing-page .show > .btn-indigo-soft.dropdown-toggle {
  color: #000;
  background-color: #e9e4fb;
  border-color: #e6e0fb;
}
.landing-page .btn-check:checked + .btn-indigo-soft:focus,
.landing-page .btn-check:active + .btn-indigo-soft:focus,
.landing-page .btn-indigo-soft:active:focus,
.landing-page .btn-indigo-soft.active:focus,
.landing-page .show > .btn-indigo-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(193, 188, 213, 0.5);
}
.landing-page .btn-indigo-soft:disabled,
.landing-page .btn-indigo-soft.disabled {
  color: #000;
  background-color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .btn-purple-soft {
  color: #000;
  background-color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .btn-purple-soft:hover {
  color: #000;
  background-color: #e8e2f8;
  border-color: #e7e0f8;
}
.landing-page .btn-check:focus + .btn-purple-soft,
.landing-page .btn-purple-soft:focus {
  color: #000;
  background-color: #e8e2f8;
  border-color: #e7e0f8;
  box-shadow: 0 0 0 0.25rem rgba(194, 188, 210, 0.5);
}
.landing-page .btn-check:checked + .btn-purple-soft,
.landing-page .btn-check:active + .btn-purple-soft,
.landing-page .btn-purple-soft:active,
.landing-page .btn-purple-soft.active,
.landing-page .show > .btn-purple-soft.dropdown-toggle {
  color: #000;
  background-color: #e9e4f9;
  border-color: #e7e0f8;
}
.landing-page .btn-check:checked + .btn-purple-soft:focus,
.landing-page .btn-check:active + .btn-purple-soft:focus,
.landing-page .btn-purple-soft:active:focus,
.landing-page .btn-purple-soft.active:focus,
.landing-page .show > .btn-purple-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(194, 188, 210, 0.5);
}
.landing-page .btn-purple-soft:disabled,
.landing-page .btn-purple-soft.disabled {
  color: #000;
  background-color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .btn-pink-soft {
  color: #000;
  background-color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .btn-pink-soft:hover {
  color: #000;
  background-color: #f3e2ef;
  border-color: #f2e0ee;
}
.landing-page .btn-check:focus + .btn-pink-soft,
.landing-page .btn-pink-soft:focus {
  color: #000;
  background-color: #f3e2ef;
  border-color: #f2e0ee;
  box-shadow: 0 0 0 0.25rem rgba(205, 188, 201, 0.5);
}
.landing-page .btn-check:checked + .btn-pink-soft,
.landing-page .btn-check:active + .btn-pink-soft,
.landing-page .btn-pink-soft:active,
.landing-page .btn-pink-soft.active,
.landing-page .show > .btn-pink-soft.dropdown-toggle {
  color: #000;
  background-color: #f4e4f0;
  border-color: #f2e0ee;
}
.landing-page .btn-check:checked + .btn-pink-soft:focus,
.landing-page .btn-check:active + .btn-pink-soft:focus,
.landing-page .btn-pink-soft:active:focus,
.landing-page .btn-pink-soft.active:focus,
.landing-page .show > .btn-pink-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 188, 201, 0.5);
}
.landing-page .btn-pink-soft:disabled,
.landing-page .btn-pink-soft.disabled {
  color: #000;
  background-color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .btn-primary-soft {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-primary-soft:hover {
  color: #000;
  background-color: #e0ebfc;
  border-color: #dee9fb;
}
.landing-page .btn-check:focus + .btn-primary-soft,
.landing-page .btn-primary-soft:focus {
  color: #000;
  background-color: #e0ebfc;
  border-color: #dee9fb;
  box-shadow: 0 0 0 0.25rem rgba(185, 196, 213, 0.5);
}
.landing-page .btn-check:checked + .btn-primary-soft,
.landing-page .btn-check:active + .btn-primary-soft,
.landing-page .btn-primary-soft:active,
.landing-page .btn-primary-soft.active,
.landing-page .show > .btn-primary-soft.dropdown-toggle {
  color: #000;
  background-color: #e1ecfc;
  border-color: #dee9fb;
}
.landing-page .btn-check:checked + .btn-primary-soft:focus,
.landing-page .btn-check:active + .btn-primary-soft:focus,
.landing-page .btn-primary-soft:active:focus,
.landing-page .btn-primary-soft.active:focus,
.landing-page .show > .btn-primary-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 196, 213, 0.5);
}
.landing-page .btn-primary-soft:disabled,
.landing-page .btn-primary-soft.disabled {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-secondary-soft {
  color: #000;
  background-color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .btn-secondary-soft:hover {
  color: #000;
  background-color: #e7f3fc;
  border-color: #e6f2fc;
}
.landing-page .btn-check:focus + .btn-secondary-soft,
.landing-page .btn-secondary-soft:focus {
  color: #000;
  background-color: #e7f3fc;
  border-color: #e6f2fc;
  box-shadow: 0 0 0 0.25rem rgba(193, 205, 214, 0.5);
}
.landing-page .btn-check:checked + .btn-secondary-soft,
.landing-page .btn-check:active + .btn-secondary-soft,
.landing-page .btn-secondary-soft:active,
.landing-page .btn-secondary-soft.active,
.landing-page .show > .btn-secondary-soft.dropdown-toggle {
  color: #000;
  background-color: #e9f4fd;
  border-color: #e6f2fc;
}
.landing-page .btn-check:checked + .btn-secondary-soft:focus,
.landing-page .btn-check:active + .btn-secondary-soft:focus,
.landing-page .btn-secondary-soft:active:focus,
.landing-page .btn-secondary-soft.active:focus,
.landing-page .show > .btn-secondary-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(193, 205, 214, 0.5);
}
.landing-page .btn-secondary-soft:disabled,
.landing-page .btn-secondary-soft.disabled {
  color: #000;
  background-color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .btn-success-soft {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-success-soft:hover {
  color: #000;
  background-color: #e0f1f0;
  border-color: #def1ef;
}
.landing-page .btn-check:focus + .btn-success-soft,
.landing-page .btn-success-soft:focus {
  color: #000;
  background-color: #e0f1f0;
  border-color: #def1ef;
  box-shadow: 0 0 0 0.25rem rgba(185, 203, 201, 0.5);
}
.landing-page .btn-check:checked + .btn-success-soft,
.landing-page .btn-check:active + .btn-success-soft,
.landing-page .btn-success-soft:active,
.landing-page .btn-success-soft.active,
.landing-page .show > .btn-success-soft.dropdown-toggle {
  color: #000;
  background-color: #e1f2f1;
  border-color: #def1ef;
}
.landing-page .btn-check:checked + .btn-success-soft:focus,
.landing-page .btn-check:active + .btn-success-soft:focus,
.landing-page .btn-success-soft:active:focus,
.landing-page .btn-success-soft.active:focus,
.landing-page .show > .btn-success-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 203, 201, 0.5);
}
.landing-page .btn-success-soft:disabled,
.landing-page .btn-success-soft.disabled {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-info-soft {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-info-soft:hover {
  color: #000;
  background-color: #e0f4f9;
  border-color: #def3f9;
}
.landing-page .btn-check:focus + .btn-info-soft,
.landing-page .btn-info-soft:focus {
  color: #000;
  background-color: #e0f4f9;
  border-color: #def3f9;
  box-shadow: 0 0 0 0.25rem rgba(185, 206, 211, 0.5);
}
.landing-page .btn-check:checked + .btn-info-soft,
.landing-page .btn-check:active + .btn-info-soft,
.landing-page .btn-info-soft:active,
.landing-page .btn-info-soft.active,
.landing-page .show > .btn-info-soft.dropdown-toggle {
  color: #000;
  background-color: #e1f5f9;
  border-color: #def3f9;
}
.landing-page .btn-check:checked + .btn-info-soft:focus,
.landing-page .btn-check:active + .btn-info-soft:focus,
.landing-page .btn-info-soft:active:focus,
.landing-page .btn-info-soft.active:focus,
.landing-page .show > .btn-info-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(185, 206, 211, 0.5);
}
.landing-page .btn-info-soft:disabled,
.landing-page .btn-info-soft.disabled {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-warning-soft {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-warning-soft:hover {
  color: #000;
  background-color: #f4f1e7;
  border-color: #f3f0e6;
}
.landing-page .btn-check:focus + .btn-warning-soft,
.landing-page .btn-warning-soft:focus {
  color: #000;
  background-color: #f4f1e7;
  border-color: #f3f0e6;
  box-shadow: 0 0 0 0.25rem rgba(206, 202, 193, 0.5);
}
.landing-page .btn-check:checked + .btn-warning-soft,
.landing-page .btn-check:active + .btn-warning-soft,
.landing-page .btn-warning-soft:active,
.landing-page .btn-warning-soft.active,
.landing-page .show > .btn-warning-soft.dropdown-toggle {
  color: #000;
  background-color: #f5f1e9;
  border-color: #f3f0e6;
}
.landing-page .btn-check:checked + .btn-warning-soft:focus,
.landing-page .btn-check:active + .btn-warning-soft:focus,
.landing-page .btn-warning-soft:active:focus,
.landing-page .btn-warning-soft.active:focus,
.landing-page .show > .btn-warning-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(206, 202, 193, 0.5);
}
.landing-page .btn-warning-soft:disabled,
.landing-page .btn-warning-soft.disabled {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-danger-soft {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-danger-soft:hover {
  color: #000;
  background-color: #f3e5e7;
  border-color: #f2e3e6;
}
.landing-page .btn-check:focus + .btn-danger-soft,
.landing-page .btn-danger-soft:focus {
  color: #000;
  background-color: #f3e5e7;
  border-color: #f2e3e6;
  box-shadow: 0 0 0 0.25rem rgba(205, 190, 193, 0.5);
}
.landing-page .btn-check:checked + .btn-danger-soft,
.landing-page .btn-check:active + .btn-danger-soft,
.landing-page .btn-danger-soft:active,
.landing-page .btn-danger-soft.active,
.landing-page .show > .btn-danger-soft.dropdown-toggle {
  color: #000;
  background-color: #f4e6e9;
  border-color: #f2e3e6;
}
.landing-page .btn-check:checked + .btn-danger-soft:focus,
.landing-page .btn-check:active + .btn-danger-soft:focus,
.landing-page .btn-danger-soft:active:focus,
.landing-page .btn-danger-soft.active:focus,
.landing-page .show > .btn-danger-soft.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(205, 190, 193, 0.5);
}
.landing-page .btn-danger-soft:disabled,
.landing-page .btn-danger-soft.disabled {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-outline-primary {
  color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-outline-primary:hover {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-check:focus + .btn-outline-primary,
.landing-page .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-primary,
.landing-page .btn-check:active + .btn-outline-primary,
.landing-page .btn-outline-primary:active,
.landing-page .btn-outline-primary.active,
.landing-page .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-check:checked + .btn-outline-primary:focus,
.landing-page .btn-check:active + .btn-outline-primary:focus,
.landing-page .btn-outline-primary:active:focus,
.landing-page .btn-outline-primary.active:focus,
.landing-page .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.5);
}
.landing-page .btn-outline-primary:disabled,
.landing-page .btn-outline-primary.disabled {
  color: #0061f2;
  background-color: transparent;
}
.landing-page .btn-outline-secondary {
  color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .btn-outline-secondary:hover {
  color: #fff;
  background-color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .btn-check:focus + .btn-outline-secondary,
.landing-page .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(91, 194, 255, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-secondary,
.landing-page .btn-check:active + .btn-outline-secondary,
.landing-page .btn-outline-secondary:active,
.landing-page .btn-outline-secondary.active,
.landing-page .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .btn-check:checked + .btn-outline-secondary:focus,
.landing-page .btn-check:active + .btn-outline-secondary:focus,
.landing-page .btn-outline-secondary:active:focus,
.landing-page .btn-outline-secondary.active:focus,
.landing-page .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(91, 194, 255, 0.5);
}
.landing-page .btn-outline-secondary:disabled,
.landing-page .btn-outline-secondary.disabled {
  color: #5bc2ff;
  background-color: transparent;
}
.landing-page .btn-outline-success {
  color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-outline-success:hover {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-check:focus + .btn-outline-success,
.landing-page .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-success,
.landing-page .btn-check:active + .btn-outline-success,
.landing-page .btn-outline-success:active,
.landing-page .btn-outline-success.active,
.landing-page .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-check:checked + .btn-outline-success:focus,
.landing-page .btn-check:active + .btn-outline-success:focus,
.landing-page .btn-outline-success:active:focus,
.landing-page .btn-outline-success.active:focus,
.landing-page .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.5);
}
.landing-page .btn-outline-success:disabled,
.landing-page .btn-outline-success.disabled {
  color: #00ac69;
  background-color: transparent;
}
.landing-page .btn-outline-info {
  color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-outline-info:hover {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-check:focus + .btn-outline-info,
.landing-page .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 213, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-info,
.landing-page .btn-check:active + .btn-outline-info,
.landing-page .btn-outline-info:active,
.landing-page .btn-outline-info.active,
.landing-page .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-check:checked + .btn-outline-info:focus,
.landing-page .btn-check:active + .btn-outline-info:focus,
.landing-page .btn-outline-info:active:focus,
.landing-page .btn-outline-info.active:focus,
.landing-page .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 213, 0.5);
}
.landing-page .btn-outline-info:disabled,
.landing-page .btn-outline-info.disabled {
  color: #00cfd5;
  background-color: transparent;
}
.landing-page .btn-outline-warning {
  color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-outline-warning:hover {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-check:focus + .btn-outline-warning,
.landing-page .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 161, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-warning,
.landing-page .btn-check:active + .btn-outline-warning,
.landing-page .btn-outline-warning:active,
.landing-page .btn-outline-warning.active,
.landing-page .btn-outline-warning.dropdown-toggle.show {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-check:checked + .btn-outline-warning:focus,
.landing-page .btn-check:active + .btn-outline-warning:focus,
.landing-page .btn-outline-warning:active:focus,
.landing-page .btn-outline-warning.active:focus,
.landing-page .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 161, 0, 0.5);
}
.landing-page .btn-outline-warning:disabled,
.landing-page .btn-outline-warning.disabled {
  color: #f4a100;
  background-color: transparent;
}
.landing-page .btn-outline-danger {
  color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-outline-danger:hover {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-check:focus + .btn-outline-danger,
.landing-page .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-danger,
.landing-page .btn-check:active + .btn-outline-danger,
.landing-page .btn-outline-danger:active,
.landing-page .btn-outline-danger.active,
.landing-page .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-check:checked + .btn-outline-danger:focus,
.landing-page .btn-check:active + .btn-outline-danger:focus,
.landing-page .btn-outline-danger:active:focus,
.landing-page .btn-outline-danger.active:focus,
.landing-page .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.5);
}
.landing-page .btn-outline-danger:disabled,
.landing-page .btn-outline-danger.disabled {
  color: #e81500;
  background-color: transparent;
}
.landing-page .btn-outline-light {
  color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .btn-outline-light:hover {
  color: #000;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .btn-check:focus + .btn-outline-light,
.landing-page .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 246, 252, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-light,
.landing-page .btn-check:active + .btn-outline-light,
.landing-page .btn-outline-light:active,
.landing-page .btn-outline-light.active,
.landing-page .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .btn-check:checked + .btn-outline-light:focus,
.landing-page .btn-check:active + .btn-outline-light:focus,
.landing-page .btn-outline-light:active:focus,
.landing-page .btn-outline-light.active:focus,
.landing-page .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 246, 252, 0.5);
}
.landing-page .btn-outline-light:disabled,
.landing-page .btn-outline-light.disabled {
  color: #f2f6fc;
  background-color: transparent;
}
.landing-page .btn-outline-dark {
  color: #212832;
  border-color: #212832;
}
.landing-page .btn-outline-dark:hover {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.landing-page .btn-check:focus + .btn-outline-dark,
.landing-page .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 40, 50, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-dark,
.landing-page .btn-check:active + .btn-outline-dark,
.landing-page .btn-outline-dark:active,
.landing-page .btn-outline-dark.active,
.landing-page .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.landing-page .btn-check:checked + .btn-outline-dark:focus,
.landing-page .btn-check:active + .btn-outline-dark:focus,
.landing-page .btn-outline-dark:active:focus,
.landing-page .btn-outline-dark.active:focus,
.landing-page .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 40, 50, 0.5);
}
.landing-page .btn-outline-dark:disabled,
.landing-page .btn-outline-dark.disabled {
  color: #212832;
  background-color: transparent;
}
.landing-page .btn-outline-black {
  color: #000;
  border-color: #000;
}
.landing-page .btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.landing-page .btn-check:focus + .btn-outline-black,
.landing-page .btn-outline-black:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-black,
.landing-page .btn-check:active + .btn-outline-black,
.landing-page .btn-outline-black:active,
.landing-page .btn-outline-black.active,
.landing-page .btn-outline-black.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.landing-page .btn-check:checked + .btn-outline-black:focus,
.landing-page .btn-check:active + .btn-outline-black:focus,
.landing-page .btn-outline-black:active:focus,
.landing-page .btn-outline-black.active:focus,
.landing-page .btn-outline-black.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.landing-page .btn-outline-black:disabled,
.landing-page .btn-outline-black.disabled {
  color: #000;
  background-color: transparent;
}
.landing-page .btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.landing-page .btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.landing-page .btn-check:focus + .btn-outline-white,
.landing-page .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-white,
.landing-page .btn-check:active + .btn-outline-white,
.landing-page .btn-outline-white:active,
.landing-page .btn-outline-white.active,
.landing-page .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.landing-page .btn-check:checked + .btn-outline-white:focus,
.landing-page .btn-check:active + .btn-outline-white:focus,
.landing-page .btn-outline-white:active:focus,
.landing-page .btn-outline-white.active:focus,
.landing-page .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.landing-page .btn-outline-white:disabled,
.landing-page .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}
.landing-page .btn-outline-red {
  color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-outline-red:hover {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-check:focus + .btn-outline-red,
.landing-page .btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-red,
.landing-page .btn-check:active + .btn-outline-red,
.landing-page .btn-outline-red:active,
.landing-page .btn-outline-red.active,
.landing-page .btn-outline-red.dropdown-toggle.show {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .btn-check:checked + .btn-outline-red:focus,
.landing-page .btn-check:active + .btn-outline-red:focus,
.landing-page .btn-outline-red:active:focus,
.landing-page .btn-outline-red.active:focus,
.landing-page .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(232, 21, 0, 0.5);
}
.landing-page .btn-outline-red:disabled,
.landing-page .btn-outline-red.disabled {
  color: #e81500;
  background-color: transparent;
}
.landing-page .btn-outline-orange {
  color: #f76400;
  border-color: #f76400;
}
.landing-page .btn-outline-orange:hover {
  color: #fff;
  background-color: #f76400;
  border-color: #f76400;
}
.landing-page .btn-check:focus + .btn-outline-orange,
.landing-page .btn-outline-orange:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 100, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-orange,
.landing-page .btn-check:active + .btn-outline-orange,
.landing-page .btn-outline-orange:active,
.landing-page .btn-outline-orange.active,
.landing-page .btn-outline-orange.dropdown-toggle.show {
  color: #fff;
  background-color: #f76400;
  border-color: #f76400;
}
.landing-page .btn-check:checked + .btn-outline-orange:focus,
.landing-page .btn-check:active + .btn-outline-orange:focus,
.landing-page .btn-outline-orange:active:focus,
.landing-page .btn-outline-orange.active:focus,
.landing-page .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 100, 0, 0.5);
}
.landing-page .btn-outline-orange:disabled,
.landing-page .btn-outline-orange.disabled {
  color: #f76400;
  background-color: transparent;
}
.landing-page .btn-outline-yellow {
  color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-outline-yellow:hover {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-check:focus + .btn-outline-yellow,
.landing-page .btn-outline-yellow:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 161, 0, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-yellow,
.landing-page .btn-check:active + .btn-outline-yellow,
.landing-page .btn-outline-yellow:active,
.landing-page .btn-outline-yellow.active,
.landing-page .btn-outline-yellow.dropdown-toggle.show {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .btn-check:checked + .btn-outline-yellow:focus,
.landing-page .btn-check:active + .btn-outline-yellow:focus,
.landing-page .btn-outline-yellow:active:focus,
.landing-page .btn-outline-yellow.active:focus,
.landing-page .btn-outline-yellow.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(244, 161, 0, 0.5);
}
.landing-page .btn-outline-yellow:disabled,
.landing-page .btn-outline-yellow.disabled {
  color: #f4a100;
  background-color: transparent;
}
.landing-page .btn-outline-green {
  color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-outline-green:hover {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-check:focus + .btn-outline-green,
.landing-page .btn-outline-green:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-green,
.landing-page .btn-check:active + .btn-outline-green,
.landing-page .btn-outline-green:active,
.landing-page .btn-outline-green.active,
.landing-page .btn-outline-green.dropdown-toggle.show {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .btn-check:checked + .btn-outline-green:focus,
.landing-page .btn-check:active + .btn-outline-green:focus,
.landing-page .btn-outline-green:active:focus,
.landing-page .btn-outline-green.active:focus,
.landing-page .btn-outline-green.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 172, 105, 0.5);
}
.landing-page .btn-outline-green:disabled,
.landing-page .btn-outline-green.disabled {
  color: #00ac69;
  background-color: transparent;
}
.landing-page .btn-outline-teal {
  color: #00ba94;
  border-color: #00ba94;
}
.landing-page .btn-outline-teal:hover {
  color: #fff;
  background-color: #00ba94;
  border-color: #00ba94;
}
.landing-page .btn-check:focus + .btn-outline-teal,
.landing-page .btn-outline-teal:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 186, 148, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-teal,
.landing-page .btn-check:active + .btn-outline-teal,
.landing-page .btn-outline-teal:active,
.landing-page .btn-outline-teal.active,
.landing-page .btn-outline-teal.dropdown-toggle.show {
  color: #fff;
  background-color: #00ba94;
  border-color: #00ba94;
}
.landing-page .btn-check:checked + .btn-outline-teal:focus,
.landing-page .btn-check:active + .btn-outline-teal:focus,
.landing-page .btn-outline-teal:active:focus,
.landing-page .btn-outline-teal.active:focus,
.landing-page .btn-outline-teal.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 186, 148, 0.5);
}
.landing-page .btn-outline-teal:disabled,
.landing-page .btn-outline-teal.disabled {
  color: #00ba94;
  background-color: transparent;
}
.landing-page .btn-outline-cyan {
  color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-outline-cyan:hover {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-check:focus + .btn-outline-cyan,
.landing-page .btn-outline-cyan:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 213, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-cyan,
.landing-page .btn-check:active + .btn-outline-cyan,
.landing-page .btn-outline-cyan:active,
.landing-page .btn-outline-cyan.active,
.landing-page .btn-outline-cyan.dropdown-toggle.show {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .btn-check:checked + .btn-outline-cyan:focus,
.landing-page .btn-check:active + .btn-outline-cyan:focus,
.landing-page .btn-outline-cyan:active:focus,
.landing-page .btn-outline-cyan.active:focus,
.landing-page .btn-outline-cyan.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 207, 213, 0.5);
}
.landing-page .btn-outline-cyan:disabled,
.landing-page .btn-outline-cyan.disabled {
  color: #00cfd5;
  background-color: transparent;
}
.landing-page .btn-outline-blue {
  color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-outline-blue:hover {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-check:focus + .btn-outline-blue,
.landing-page .btn-outline-blue:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-blue,
.landing-page .btn-check:active + .btn-outline-blue,
.landing-page .btn-outline-blue:active,
.landing-page .btn-outline-blue.active,
.landing-page .btn-outline-blue.dropdown-toggle.show {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .btn-check:checked + .btn-outline-blue:focus,
.landing-page .btn-check:active + .btn-outline-blue:focus,
.landing-page .btn-outline-blue:active:focus,
.landing-page .btn-outline-blue.active:focus,
.landing-page .btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.5);
}
.landing-page .btn-outline-blue:disabled,
.landing-page .btn-outline-blue.disabled {
  color: #0061f2;
  background-color: transparent;
}
.landing-page .btn-outline-indigo {
  color: #5800e8;
  border-color: #5800e8;
}
.landing-page .btn-outline-indigo:hover {
  color: #fff;
  background-color: #5800e8;
  border-color: #5800e8;
}
.landing-page .btn-check:focus + .btn-outline-indigo,
.landing-page .btn-outline-indigo:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 0, 232, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-indigo,
.landing-page .btn-check:active + .btn-outline-indigo,
.landing-page .btn-outline-indigo:active,
.landing-page .btn-outline-indigo.active,
.landing-page .btn-outline-indigo.dropdown-toggle.show {
  color: #fff;
  background-color: #5800e8;
  border-color: #5800e8;
}
.landing-page .btn-check:checked + .btn-outline-indigo:focus,
.landing-page .btn-check:active + .btn-outline-indigo:focus,
.landing-page .btn-outline-indigo:active:focus,
.landing-page .btn-outline-indigo.active:focus,
.landing-page .btn-outline-indigo.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 0, 232, 0.5);
}
.landing-page .btn-outline-indigo:disabled,
.landing-page .btn-outline-indigo.disabled {
  color: #5800e8;
  background-color: transparent;
}
.landing-page .btn-outline-purple {
  color: #6900c7;
  border-color: #6900c7;
}
.landing-page .btn-outline-purple:hover {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.landing-page .btn-check:focus + .btn-outline-purple,
.landing-page .btn-outline-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(105, 0, 199, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-purple,
.landing-page .btn-check:active + .btn-outline-purple,
.landing-page .btn-outline-purple:active,
.landing-page .btn-outline-purple.active,
.landing-page .btn-outline-purple.dropdown-toggle.show {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.landing-page .btn-check:checked + .btn-outline-purple:focus,
.landing-page .btn-check:active + .btn-outline-purple:focus,
.landing-page .btn-outline-purple:active:focus,
.landing-page .btn-outline-purple.active:focus,
.landing-page .btn-outline-purple.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(105, 0, 199, 0.5);
}
.landing-page .btn-outline-purple:disabled,
.landing-page .btn-outline-purple.disabled {
  color: #6900c7;
  background-color: transparent;
}
.landing-page .btn-outline-pink {
  color: #e30059;
  border-color: #e30059;
}
.landing-page .btn-outline-pink:hover {
  color: #fff;
  background-color: #e30059;
  border-color: #e30059;
}
.landing-page .btn-check:focus + .btn-outline-pink,
.landing-page .btn-outline-pink:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 0, 89, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-pink,
.landing-page .btn-check:active + .btn-outline-pink,
.landing-page .btn-outline-pink:active,
.landing-page .btn-outline-pink.active,
.landing-page .btn-outline-pink.dropdown-toggle.show {
  color: #fff;
  background-color: #e30059;
  border-color: #e30059;
}
.landing-page .btn-check:checked + .btn-outline-pink:focus,
.landing-page .btn-check:active + .btn-outline-pink:focus,
.landing-page .btn-outline-pink:active:focus,
.landing-page .btn-outline-pink.active:focus,
.landing-page .btn-outline-pink.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 0, 89, 0.5);
}
.landing-page .btn-outline-pink:disabled,
.landing-page .btn-outline-pink.disabled {
  color: #e30059;
  background-color: transparent;
}
.landing-page .btn-outline-red-soft {
  color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-outline-red-soft:hover {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-check:focus + .btn-outline-red-soft,
.landing-page .btn-outline-red-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 224, 227, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-red-soft,
.landing-page .btn-check:active + .btn-outline-red-soft,
.landing-page .btn-outline-red-soft:active,
.landing-page .btn-outline-red-soft.active,
.landing-page .btn-outline-red-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-check:checked + .btn-outline-red-soft:focus,
.landing-page .btn-check:active + .btn-outline-red-soft:focus,
.landing-page .btn-outline-red-soft:active:focus,
.landing-page .btn-outline-red-soft.active:focus,
.landing-page .btn-outline-red-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 224, 227, 0.5);
}
.landing-page .btn-outline-red-soft:disabled,
.landing-page .btn-outline-red-soft.disabled {
  color: #f1e0e3;
  background-color: transparent;
}
.landing-page .btn-outline-orange-soft {
  color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .btn-outline-orange-soft:hover {
  color: #000;
  background-color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .btn-check:focus + .btn-outline-orange-soft,
.landing-page .btn-outline-orange-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 231, 227, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-orange-soft,
.landing-page .btn-check:active + .btn-outline-orange-soft,
.landing-page .btn-outline-orange-soft:active,
.landing-page .btn-outline-orange-soft.active,
.landing-page .btn-outline-orange-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .btn-check:checked + .btn-outline-orange-soft:focus,
.landing-page .btn-check:active + .btn-outline-orange-soft:focus,
.landing-page .btn-outline-orange-soft:active:focus,
.landing-page .btn-outline-orange-soft.active:focus,
.landing-page .btn-outline-orange-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(243, 231, 227, 0.5);
}
.landing-page .btn-outline-orange-soft:disabled,
.landing-page .btn-outline-orange-soft.disabled {
  color: #f3e7e3;
  background-color: transparent;
}
.landing-page .btn-outline-yellow-soft {
  color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-outline-yellow-soft:hover {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-check:focus + .btn-outline-yellow-soft,
.landing-page .btn-outline-yellow-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 238, 227, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-yellow-soft,
.landing-page .btn-check:active + .btn-outline-yellow-soft,
.landing-page .btn-outline-yellow-soft:active,
.landing-page .btn-outline-yellow-soft.active,
.landing-page .btn-outline-yellow-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-check:checked + .btn-outline-yellow-soft:focus,
.landing-page .btn-check:active + .btn-outline-yellow-soft:focus,
.landing-page .btn-outline-yellow-soft:active:focus,
.landing-page .btn-outline-yellow-soft.active:focus,
.landing-page .btn-outline-yellow-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 238, 227, 0.5);
}
.landing-page .btn-outline-yellow-soft:disabled,
.landing-page .btn-outline-yellow-soft.disabled {
  color: #f2eee3;
  background-color: transparent;
}
.landing-page .btn-outline-green-soft {
  color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-outline-green-soft:hover {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-check:focus + .btn-outline-green-soft,
.landing-page .btn-outline-green-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 239, 237, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-green-soft,
.landing-page .btn-check:active + .btn-outline-green-soft,
.landing-page .btn-outline-green-soft:active,
.landing-page .btn-outline-green-soft.active,
.landing-page .btn-outline-green-soft.dropdown-toggle.show {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-check:checked + .btn-outline-green-soft:focus,
.landing-page .btn-check:active + .btn-outline-green-soft:focus,
.landing-page .btn-outline-green-soft:active:focus,
.landing-page .btn-outline-green-soft.active:focus,
.landing-page .btn-outline-green-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 239, 237, 0.5);
}
.landing-page .btn-outline-green-soft:disabled,
.landing-page .btn-outline-green-soft.disabled {
  color: #daefed;
  background-color: transparent;
}
.landing-page .btn-outline-teal-soft {
  color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .btn-outline-teal-soft:hover {
  color: #000;
  background-color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .btn-check:focus + .btn-outline-teal-soft,
.landing-page .btn-outline-teal-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 240, 242, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-teal-soft,
.landing-page .btn-check:active + .btn-outline-teal-soft,
.landing-page .btn-outline-teal-soft:active,
.landing-page .btn-outline-teal-soft.active,
.landing-page .btn-outline-teal-soft.dropdown-toggle.show {
  color: #000;
  background-color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .btn-check:checked + .btn-outline-teal-soft:focus,
.landing-page .btn-check:active + .btn-outline-teal-soft:focus,
.landing-page .btn-outline-teal-soft:active:focus,
.landing-page .btn-outline-teal-soft.active:focus,
.landing-page .btn-outline-teal-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 240, 242, 0.5);
}
.landing-page .btn-outline-teal-soft:disabled,
.landing-page .btn-outline-teal-soft.disabled {
  color: #daf0f2;
  background-color: transparent;
}
.landing-page .btn-outline-cyan-soft {
  color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-outline-cyan-soft:hover {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-check:focus + .btn-outline-cyan-soft,
.landing-page .btn-outline-cyan-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 242, 248, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-cyan-soft,
.landing-page .btn-check:active + .btn-outline-cyan-soft,
.landing-page .btn-outline-cyan-soft:active,
.landing-page .btn-outline-cyan-soft.active,
.landing-page .btn-outline-cyan-soft.dropdown-toggle.show {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-check:checked + .btn-outline-cyan-soft:focus,
.landing-page .btn-check:active + .btn-outline-cyan-soft:focus,
.landing-page .btn-outline-cyan-soft:active:focus,
.landing-page .btn-outline-cyan-soft.active:focus,
.landing-page .btn-outline-cyan-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 242, 248, 0.5);
}
.landing-page .btn-outline-cyan-soft:disabled,
.landing-page .btn-outline-cyan-soft.disabled {
  color: #daf2f8;
  background-color: transparent;
}
.landing-page .btn-outline-blue-soft {
  color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-outline-blue-soft:hover {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-check:focus + .btn-outline-blue-soft,
.landing-page .btn-outline-blue-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 231, 251, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-blue-soft,
.landing-page .btn-check:active + .btn-outline-blue-soft,
.landing-page .btn-outline-blue-soft:active,
.landing-page .btn-outline-blue-soft.active,
.landing-page .btn-outline-blue-soft.dropdown-toggle.show {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-check:checked + .btn-outline-blue-soft:focus,
.landing-page .btn-check:active + .btn-outline-blue-soft:focus,
.landing-page .btn-outline-blue-soft:active:focus,
.landing-page .btn-outline-blue-soft.active:focus,
.landing-page .btn-outline-blue-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 231, 251, 0.5);
}
.landing-page .btn-outline-blue-soft:disabled,
.landing-page .btn-outline-blue-soft.disabled {
  color: #dae7fb;
  background-color: transparent;
}
.landing-page .btn-outline-indigo-soft {
  color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .btn-outline-indigo-soft:hover {
  color: #000;
  background-color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .btn-check:focus + .btn-outline-indigo-soft,
.landing-page .btn-outline-indigo-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 221, 250, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-indigo-soft,
.landing-page .btn-check:active + .btn-outline-indigo-soft,
.landing-page .btn-outline-indigo-soft:active,
.landing-page .btn-outline-indigo-soft.active,
.landing-page .btn-outline-indigo-soft.dropdown-toggle.show {
  color: #000;
  background-color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .btn-check:checked + .btn-outline-indigo-soft:focus,
.landing-page .btn-check:active + .btn-outline-indigo-soft:focus,
.landing-page .btn-outline-indigo-soft:active:focus,
.landing-page .btn-outline-indigo-soft.active:focus,
.landing-page .btn-outline-indigo-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 221, 250, 0.5);
}
.landing-page .btn-outline-indigo-soft:disabled,
.landing-page .btn-outline-indigo-soft.disabled {
  color: #e3ddfa;
  background-color: transparent;
}
.landing-page .btn-outline-purple-soft {
  color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .btn-outline-purple-soft:hover {
  color: #000;
  background-color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .btn-check:focus + .btn-outline-purple-soft,
.landing-page .btn-outline-purple-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 221, 247, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-purple-soft,
.landing-page .btn-check:active + .btn-outline-purple-soft,
.landing-page .btn-outline-purple-soft:active,
.landing-page .btn-outline-purple-soft.active,
.landing-page .btn-outline-purple-soft.dropdown-toggle.show {
  color: #000;
  background-color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .btn-check:checked + .btn-outline-purple-soft:focus,
.landing-page .btn-check:active + .btn-outline-purple-soft:focus,
.landing-page .btn-outline-purple-soft:active:focus,
.landing-page .btn-outline-purple-soft.active:focus,
.landing-page .btn-outline-purple-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(228, 221, 247, 0.5);
}
.landing-page .btn-outline-purple-soft:disabled,
.landing-page .btn-outline-purple-soft.disabled {
  color: #e4ddf7;
  background-color: transparent;
}
.landing-page .btn-outline-pink-soft {
  color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .btn-outline-pink-soft:hover {
  color: #000;
  background-color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .btn-check:focus + .btn-outline-pink-soft,
.landing-page .btn-outline-pink-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 221, 236, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-pink-soft,
.landing-page .btn-check:active + .btn-outline-pink-soft,
.landing-page .btn-outline-pink-soft:active,
.landing-page .btn-outline-pink-soft.active,
.landing-page .btn-outline-pink-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .btn-check:checked + .btn-outline-pink-soft:focus,
.landing-page .btn-check:active + .btn-outline-pink-soft:focus,
.landing-page .btn-outline-pink-soft:active:focus,
.landing-page .btn-outline-pink-soft.active:focus,
.landing-page .btn-outline-pink-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 221, 236, 0.5);
}
.landing-page .btn-outline-pink-soft:disabled,
.landing-page .btn-outline-pink-soft.disabled {
  color: #f1ddec;
  background-color: transparent;
}
.landing-page .btn-outline-primary-soft {
  color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-outline-primary-soft:hover {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-check:focus + .btn-outline-primary-soft,
.landing-page .btn-outline-primary-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 231, 251, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-primary-soft,
.landing-page .btn-check:active + .btn-outline-primary-soft,
.landing-page .btn-outline-primary-soft:active,
.landing-page .btn-outline-primary-soft.active,
.landing-page .btn-outline-primary-soft.dropdown-toggle.show {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .btn-check:checked + .btn-outline-primary-soft:focus,
.landing-page .btn-check:active + .btn-outline-primary-soft:focus,
.landing-page .btn-outline-primary-soft:active:focus,
.landing-page .btn-outline-primary-soft.active:focus,
.landing-page .btn-outline-primary-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 231, 251, 0.5);
}
.landing-page .btn-outline-primary-soft:disabled,
.landing-page .btn-outline-primary-soft.disabled {
  color: #dae7fb;
  background-color: transparent;
}
.landing-page .btn-outline-secondary-soft {
  color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .btn-outline-secondary-soft:hover {
  color: #000;
  background-color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .btn-check:focus + .btn-outline-secondary-soft,
.landing-page .btn-outline-secondary-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 241, 252, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-secondary-soft,
.landing-page .btn-check:active + .btn-outline-secondary-soft,
.landing-page .btn-outline-secondary-soft:active,
.landing-page .btn-outline-secondary-soft.active,
.landing-page .btn-outline-secondary-soft.dropdown-toggle.show {
  color: #000;
  background-color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .btn-check:checked + .btn-outline-secondary-soft:focus,
.landing-page .btn-check:active + .btn-outline-secondary-soft:focus,
.landing-page .btn-outline-secondary-soft:active:focus,
.landing-page .btn-outline-secondary-soft.active:focus,
.landing-page .btn-outline-secondary-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 241, 252, 0.5);
}
.landing-page .btn-outline-secondary-soft:disabled,
.landing-page .btn-outline-secondary-soft.disabled {
  color: #e3f1fc;
  background-color: transparent;
}
.landing-page .btn-outline-success-soft {
  color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-outline-success-soft:hover {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-check:focus + .btn-outline-success-soft,
.landing-page .btn-outline-success-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 239, 237, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-success-soft,
.landing-page .btn-check:active + .btn-outline-success-soft,
.landing-page .btn-outline-success-soft:active,
.landing-page .btn-outline-success-soft.active,
.landing-page .btn-outline-success-soft.dropdown-toggle.show {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .btn-check:checked + .btn-outline-success-soft:focus,
.landing-page .btn-check:active + .btn-outline-success-soft:focus,
.landing-page .btn-outline-success-soft:active:focus,
.landing-page .btn-outline-success-soft.active:focus,
.landing-page .btn-outline-success-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 239, 237, 0.5);
}
.landing-page .btn-outline-success-soft:disabled,
.landing-page .btn-outline-success-soft.disabled {
  color: #daefed;
  background-color: transparent;
}
.landing-page .btn-outline-info-soft {
  color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-outline-info-soft:hover {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-check:focus + .btn-outline-info-soft,
.landing-page .btn-outline-info-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 242, 248, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-info-soft,
.landing-page .btn-check:active + .btn-outline-info-soft,
.landing-page .btn-outline-info-soft:active,
.landing-page .btn-outline-info-soft.active,
.landing-page .btn-outline-info-soft.dropdown-toggle.show {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .btn-check:checked + .btn-outline-info-soft:focus,
.landing-page .btn-check:active + .btn-outline-info-soft:focus,
.landing-page .btn-outline-info-soft:active:focus,
.landing-page .btn-outline-info-soft.active:focus,
.landing-page .btn-outline-info-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 242, 248, 0.5);
}
.landing-page .btn-outline-info-soft:disabled,
.landing-page .btn-outline-info-soft.disabled {
  color: #daf2f8;
  background-color: transparent;
}
.landing-page .btn-outline-warning-soft {
  color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-outline-warning-soft:hover {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-check:focus + .btn-outline-warning-soft,
.landing-page .btn-outline-warning-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 238, 227, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-warning-soft,
.landing-page .btn-check:active + .btn-outline-warning-soft,
.landing-page .btn-outline-warning-soft:active,
.landing-page .btn-outline-warning-soft.active,
.landing-page .btn-outline-warning-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .btn-check:checked + .btn-outline-warning-soft:focus,
.landing-page .btn-check:active + .btn-outline-warning-soft:focus,
.landing-page .btn-outline-warning-soft:active:focus,
.landing-page .btn-outline-warning-soft.active:focus,
.landing-page .btn-outline-warning-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(242, 238, 227, 0.5);
}
.landing-page .btn-outline-warning-soft:disabled,
.landing-page .btn-outline-warning-soft.disabled {
  color: #f2eee3;
  background-color: transparent;
}
.landing-page .btn-outline-danger-soft {
  color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-outline-danger-soft:hover {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-check:focus + .btn-outline-danger-soft,
.landing-page .btn-outline-danger-soft:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 224, 227, 0.5);
}
.landing-page .btn-check:checked + .btn-outline-danger-soft,
.landing-page .btn-check:active + .btn-outline-danger-soft,
.landing-page .btn-outline-danger-soft:active,
.landing-page .btn-outline-danger-soft.active,
.landing-page .btn-outline-danger-soft.dropdown-toggle.show {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .btn-check:checked + .btn-outline-danger-soft:focus,
.landing-page .btn-check:active + .btn-outline-danger-soft:focus,
.landing-page .btn-outline-danger-soft:active:focus,
.landing-page .btn-outline-danger-soft.active:focus,
.landing-page .btn-outline-danger-soft.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 224, 227, 0.5);
}
.landing-page .btn-outline-danger-soft:disabled,
.landing-page .btn-outline-danger-soft.disabled {
  color: #f1e0e3;
  background-color: transparent;
}
.landing-page .btn-link {
  font-weight: 400;
  color: #0061f2;
  text-decoration: none;
}
.landing-page .btn-link:hover {
  color: #004ec2;
  text-decoration: underline;
}
.landing-page .btn-link:focus {
  text-decoration: underline;
}
.landing-page .btn-link:disabled,
.landing-page .btn-link.disabled {
  color: #69707a;
}
.landing-page .btn-lg,
.landing-page .btn-group-lg > .btn {
  padding: 1.125rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.landing-page .btn-sm,
.landing-page .btn-group-sm > .btn {
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.landing-page .fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .fade {
    transition: none;
  }
}
.landing-page .fade:not(.show) {
  opacity: 0;
}
.landing-page .collapse:not(.show) {
  display: none;
}
.landing-page .collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .collapsing {
    transition: none;
  }
}
.landing-page .dropup,
.landing-page .dropend,
.landing-page .dropdown,
.landing-page .dropstart {
  position: relative;
}
.landing-page .dropdown-toggle {
  white-space: nowrap;
}
.landing-page .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.landing-page .dropdown-toggle:empty::after {
  margin-left: 0;
}
.landing-page .dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #69707a;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e5ec;
  border-radius: 0.35rem;
}
.landing-page .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.landing-page .dropdown-menu-start {
  --bs-position: start;
}
.landing-page .dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.landing-page .dropdown-menu-end {
  --bs-position: end;
}
.landing-page .dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .landing-page .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .landing-page .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .landing-page .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .landing-page .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .landing-page .dropdown-menu-md-start {
    --bs-position: start;
  }
  .landing-page .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .landing-page .dropdown-menu-md-end {
    --bs-position: end;
  }
  .landing-page .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .landing-page .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .landing-page .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .landing-page .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .landing-page .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .landing-page .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .landing-page .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .landing-page .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .landing-page .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .landing-page .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .landing-page .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .landing-page .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .landing-page .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.landing-page .dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.landing-page .dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.landing-page .dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.landing-page .dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.landing-page .dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.landing-page .dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.landing-page .dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.landing-page .dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.landing-page .dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.landing-page .dropstart .dropdown-toggle::after {
  display: none;
}
.landing-page .dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.landing-page .dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.landing-page .dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.landing-page .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e0e5ec;
}
.landing-page .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212832;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.landing-page .dropdown-item:hover,
.landing-page .dropdown-item:focus {
  color: #1e242d;
  text-decoration: none;
  background-color: #e0e5ec;
}
.landing-page .dropdown-item.active,
.landing-page .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0061f2;
}
.landing-page .dropdown-item.disabled,
.landing-page .dropdown-item:disabled {
  color: #a7aeb8;
  pointer-events: none;
  background-color: transparent;
}
.landing-page .dropdown-menu.show {
  display: block;
}
.landing-page .dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #69707a;
  white-space: nowrap;
}
.landing-page .dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212832;
}
.landing-page .dropdown-menu-dark {
  color: #d4dae3;
  background-color: #363d47;
  border-color: #e0e5ec;
}
.landing-page .dropdown-menu-dark .dropdown-item {
  color: #d4dae3;
}
.landing-page .dropdown-menu-dark .dropdown-item:hover,
.landing-page .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.landing-page .dropdown-menu-dark .dropdown-item.active,
.landing-page .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0061f2;
}
.landing-page .dropdown-menu-dark .dropdown-item.disabled,
.landing-page .dropdown-menu-dark .dropdown-item:disabled {
  color: #a7aeb8;
}
.landing-page .dropdown-menu-dark .dropdown-divider {
  border-color: #e0e5ec;
}
.landing-page .dropdown-menu-dark .dropdown-item-text {
  color: #d4dae3;
}
.landing-page .dropdown-menu-dark .dropdown-header {
  color: #a7aeb8;
}
.landing-page .btn-group,
.landing-page .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.landing-page .btn-group > .btn,
.landing-page .btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.landing-page .btn-group > .btn-check:checked + .btn,
.landing-page .btn-group > .btn-check:focus + .btn,
.landing-page .btn-group > .btn:hover,
.landing-page .btn-group > .btn:focus,
.landing-page .btn-group > .btn:active,
.landing-page .btn-group > .btn.active,
.landing-page .btn-group-vertical > .btn-check:checked + .btn,
.landing-page .btn-group-vertical > .btn-check:focus + .btn,
.landing-page .btn-group-vertical > .btn:hover,
.landing-page .btn-group-vertical > .btn:focus,
.landing-page .btn-group-vertical > .btn:active,
.landing-page .btn-group-vertical > .btn.active {
  z-index: 1;
}
.landing-page .btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.landing-page .btn-toolbar .input-group {
  width: auto;
}
.landing-page .btn-group > .btn:not(:first-child),
.landing-page .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.landing-page .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.landing-page .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.landing-page .btn-group > .btn:nth-child(n+3),
.landing-page .btn-group > :not(.btn-check) + .btn,
.landing-page .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.landing-page .dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}
.landing-page .dropdown-toggle-split::after,
.landing-page .dropup .dropdown-toggle-split::after,
.landing-page .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.landing-page .dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.landing-page .btn-sm + .dropdown-toggle-split,
.landing-page .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.landing-page .btn-lg + .dropdown-toggle-split,
.landing-page .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.landing-page .btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.landing-page .btn-group-vertical > .btn,
.landing-page .btn-group-vertical > .btn-group {
  width: 100%;
}
.landing-page .btn-group-vertical > .btn:not(:first-child),
.landing-page .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.landing-page .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.landing-page .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.landing-page .btn-group-vertical > .btn ~ .btn,
.landing-page .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.landing-page .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.landing-page .nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0061f2;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .nav-link {
    transition: none;
  }
}
.landing-page .nav-link:hover,
.landing-page .nav-link:focus {
  color: #004ec2;
  text-decoration: none;
}
.landing-page .nav-link.disabled {
  color: #69707a;
  pointer-events: none;
  cursor: default;
}
.landing-page .nav-tabs {
  border-bottom: 1px solid #d4dae3;
}
.landing-page .nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.landing-page .nav-tabs .nav-link:hover,
.landing-page .nav-tabs .nav-link:focus {
  border-color: #e0e5ec #e0e5ec #d4dae3;
  isolation: isolate;
}
.landing-page .nav-tabs .nav-link.disabled {
  color: #69707a;
  background-color: transparent;
  border-color: transparent;
}
.landing-page .nav-tabs .nav-link.active,
.landing-page .nav-tabs .nav-item.show .nav-link {
  color: #4a515b;
  background-color: #f2f6fc;
  border-color: #d4dae3 #d4dae3 #f2f6fc;
}
.landing-page .nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.landing-page .nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.35rem;
}
.landing-page .nav-pills .nav-link.active,
.landing-page .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0061f2;
}
.landing-page .nav-fill > .nav-link,
.landing-page .nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.landing-page .nav-justified > .nav-link,
.landing-page .nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.landing-page .nav-fill .nav-item .nav-link,
.landing-page .nav-justified .nav-item .nav-link {
  width: 100%;
}
.landing-page .tab-content > .tab-pane {
  display: none;
}
.landing-page .tab-content > .active {
  display: block;
}
.landing-page .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.landing-page .navbar > .container,
.landing-page .navbar > .container-fluid,
.landing-page .navbar > .container-sm,
.landing-page .navbar > .container-md,
.landing-page .navbar > .container-lg,
.landing-page .navbar > .container-xl,
.landing-page .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.landing-page .navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}
.landing-page .navbar-brand:hover,
.landing-page .navbar-brand:focus {
  text-decoration: none;
}
.landing-page .navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.landing-page .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.landing-page .navbar-nav .dropdown-menu {
  position: static;
}
.landing-page .navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.landing-page .navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.landing-page .navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.35rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .navbar-toggler {
    transition: none;
  }
}
.landing-page .navbar-toggler:hover {
  text-decoration: none;
}
.landing-page .navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}
.landing-page .navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.landing-page .navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .landing-page .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .landing-page .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .landing-page .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .landing-page .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .landing-page .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .landing-page .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .landing-page .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .landing-page .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .landing-page .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .landing-page .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .landing-page .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .landing-page .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .landing-page .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .landing-page .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .landing-page .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .landing-page .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .landing-page .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .landing-page .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .landing-page .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .landing-page .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .landing-page .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .landing-page .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .landing-page .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .landing-page .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .landing-page .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .landing-page .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .landing-page .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .landing-page .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1500px) {
  .landing-page .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .landing-page .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .landing-page .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .landing-page .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .landing-page .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .landing-page .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .landing-page .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.landing-page .navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.landing-page .navbar-expand .navbar-nav {
  flex-direction: row;
}
.landing-page .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.landing-page .navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.landing-page .navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.landing-page .navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.landing-page .navbar-expand .navbar-toggler {
  display: none;
}
.landing-page .navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.landing-page .navbar-light .navbar-brand:hover,
.landing-page .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.landing-page .navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.landing-page .navbar-light .navbar-nav .nav-link:hover,
.landing-page .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.landing-page .navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.landing-page .navbar-light .navbar-nav .show > .nav-link,
.landing-page .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.landing-page .navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.landing-page .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.landing-page .navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.landing-page .navbar-light .navbar-text a,
.landing-page .navbar-light .navbar-text a:hover,
.landing-page .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.landing-page .navbar-dark .navbar-brand {
  color: #fff;
}
.landing-page .navbar-dark .navbar-brand:hover,
.landing-page .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.landing-page .navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.landing-page .navbar-dark .navbar-nav .nav-link:hover,
.landing-page .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.landing-page .navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.landing-page .navbar-dark .navbar-nav .show > .nav-link,
.landing-page .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.landing-page .navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.landing-page .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.landing-page .navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.landing-page .navbar-dark .navbar-text a,
.landing-page .navbar-dark .navbar-text a:hover,
.landing-page .navbar-dark .navbar-text a:focus {
  color: #fff;
}
.landing-page .card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(33, 40, 50, 0.125);
  border-radius: 0.35rem;
}
.landing-page .card > hr {
  margin-right: 0;
  margin-left: 0;
}
.landing-page .card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.landing-page .card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.landing-page .card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.landing-page .card > .card-header + .list-group,
.landing-page .card > .list-group + .card-footer {
  border-top: 0;
}
.landing-page .card-body {
  flex: 1 1 auto;
  padding: 1.35rem 1.35rem;
}
.landing-page .card-title {
  margin-bottom: 0.5rem;
}
.landing-page .card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}
.landing-page .card-text:last-child {
  margin-bottom: 0;
}
.landing-page .card-link:hover {
  text-decoration: none;
}
.landing-page .card-link + .card-link {
  margin-left: 1.35rem;
}
.landing-page .card-header {
  padding: 1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(33, 40, 50, 0.125);
}
.landing-page .card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}
.landing-page .card-footer {
  padding: 1rem 1.35rem;
  background-color: rgba(33, 40, 50, 0.03);
  border-top: 1px solid rgba(33, 40, 50, 0.125);
}
.landing-page .card-footer:last-child {
  border-radius: 0 0 0.35rem 0.35rem;
}
.landing-page .card-header-tabs {
  margin-right: -0.675rem;
  margin-bottom: -1rem;
  margin-left: -0.675rem;
  border-bottom: 0;
}
.landing-page .card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}
.landing-page .card-header-pills {
  margin-right: -0.675rem;
  margin-left: -0.675rem;
}
.landing-page .card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.35rem;
}
.landing-page .card-img,
.landing-page .card-img-top,
.landing-page .card-img-bottom {
  width: 100%;
}
.landing-page .card-img,
.landing-page .card-img-top {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.landing-page .card-img,
.landing-page .card-img-bottom {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.landing-page .card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .landing-page .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .landing-page .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .landing-page .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .landing-page .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .landing-page .card-group > .card:not(:last-child) .card-img-top,
.landing-page .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .landing-page .card-group > .card:not(:last-child) .card-img-bottom,
.landing-page .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .landing-page .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .landing-page .card-group > .card:not(:first-child) .card-img-top,
.landing-page .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .landing-page .card-group > .card:not(:first-child) .card-img-bottom,
.landing-page .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.landing-page .accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #69707a;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .accordion-button {
    transition: none;
  }
}
.landing-page .accordion-button:not(.collapsed) {
  color: #0057da;
  background-color: #e6effe;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.landing-page .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230057da'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.landing-page .accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2369707a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .accordion-button::after {
    transition: none;
  }
}
.landing-page .accordion-button:hover {
  z-index: 2;
}
.landing-page .accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .accordion-header {
  margin-bottom: 0;
}
.landing-page .accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.landing-page .accordion-item:first-of-type {
  border-top-left-radius: 0.35rem;
  border-top-right-radius: 0.35rem;
}
.landing-page .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}
.landing-page .accordion-item:not(:first-of-type) {
  border-top: 0;
}
.landing-page .accordion-item:last-of-type {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.landing-page .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.35rem - 1px);
  border-bottom-left-radius: calc(0.35rem - 1px);
}
.landing-page .accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.landing-page .accordion-body {
  padding: 1rem 1.25rem;
}
.landing-page .accordion-flush .accordion-collapse {
  border-width: 0;
}
.landing-page .accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.landing-page .accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.landing-page .accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.landing-page .accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.landing-page .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}
.landing-page .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.landing-page .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #69707a;
  content: var(--bs-breadcrumb-divider, "/");
}
.landing-page .breadcrumb-item.active {
  color: #69707a;
}
.landing-page .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.landing-page .page-link {
  position: relative;
  display: block;
  color: #0061f2;
  background-color: #fff;
  border: 1px solid #d4dae3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .page-link {
    transition: none;
  }
}
.landing-page .page-link:hover {
  z-index: 2;
  color: #004ec2;
  text-decoration: none;
  background-color: #e0e5ec;
  border-color: #d4dae3;
}
.landing-page .page-link:focus {
  z-index: 3;
  color: #004ec2;
  background-color: #e0e5ec;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.landing-page .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .page-item.disabled .page-link {
  color: #69707a;
  pointer-events: none;
  background-color: #fff;
  border-color: #d4dae3;
}
.landing-page .page-link {
  padding: 0.375rem 0.75rem;
}
.landing-page .page-item:first-child .page-link {
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
}
.landing-page .page-item:last-child .page-link {
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
}
.landing-page .pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.landing-page .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.landing-page .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.landing-page .pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.landing-page .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.landing-page .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.landing-page .badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.35rem;
}
.landing-page .badge:empty {
  display: none;
}
.landing-page .btn .badge {
  position: relative;
  top: -1px;
}
.landing-page .alert {
  position: relative;
  padding: 1.25rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.35rem;
}
.landing-page .alert-heading {
  color: inherit;
}
.landing-page .alert-link {
  font-weight: 500;
}
.landing-page .alert-dismissible {
  padding-right: 3rem;
}
.landing-page .alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.5625rem 1rem;
}
.landing-page .alert-primary {
  color: #003a91;
  background-color: #ccdffc;
  border-color: #b3d0fb;
}
.landing-page .alert-primary .alert-link {
  color: #002e74;
}
.landing-page .alert-secondary {
  color: #377499;
  background-color: #def3ff;
  border-color: #ceedff;
}
.landing-page .alert-secondary .alert-link {
  color: #2c5d7a;
}
.landing-page .alert-success {
  color: #00673f;
  background-color: #cceee1;
  border-color: #b3e6d2;
}
.landing-page .alert-success .alert-link {
  color: #005232;
}
.landing-page .alert-info {
  color: #007c80;
  background-color: #ccf5f7;
  border-color: #b3f1f2;
}
.landing-page .alert-info .alert-link {
  color: #006366;
}
.landing-page .alert-warning {
  color: #926100;
  background-color: #fdeccc;
  border-color: #fce3b3;
}
.landing-page .alert-warning .alert-link {
  color: #754e00;
}
.landing-page .alert-danger {
  color: #8b0d00;
  background-color: #fad0cc;
  border-color: #f8b9b3;
}
.landing-page .alert-danger .alert-link {
  color: #6f0a00;
}
.landing-page .alert-light {
  color: #919497;
  background-color: #fcfdfe;
  border-color: #fbfcfe;
}
.landing-page .alert-light .alert-link {
  color: #747679;
}
.landing-page .alert-dark {
  color: #14181e;
  background-color: #d3d4d6;
  border-color: #bcbfc2;
}
.landing-page .alert-dark .alert-link {
  color: #101318;
}
.landing-page .alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b3b3b3;
}
.landing-page .alert-black .alert-link {
  color: black;
}
.landing-page .alert-white {
  color: #999999;
  background-color: white;
  border-color: white;
}
.landing-page .alert-white .alert-link {
  color: #7a7a7a;
}
.landing-page .alert-red {
  color: #8b0d00;
  background-color: #fad0cc;
  border-color: #f8b9b3;
}
.landing-page .alert-red .alert-link {
  color: #6f0a00;
}
.landing-page .alert-orange {
  color: #943c00;
  background-color: #fde0cc;
  border-color: #fdd1b3;
}
.landing-page .alert-orange .alert-link {
  color: #763000;
}
.landing-page .alert-yellow {
  color: #926100;
  background-color: #fdeccc;
  border-color: #fce3b3;
}
.landing-page .alert-yellow .alert-link {
  color: #754e00;
}
.landing-page .alert-green {
  color: #00673f;
  background-color: #cceee1;
  border-color: #b3e6d2;
}
.landing-page .alert-green .alert-link {
  color: #005232;
}
.landing-page .alert-teal {
  color: #007059;
  background-color: #ccf1ea;
  border-color: #b3eadf;
}
.landing-page .alert-teal .alert-link {
  color: #005a47;
}
.landing-page .alert-cyan {
  color: #007c80;
  background-color: #ccf5f7;
  border-color: #b3f1f2;
}
.landing-page .alert-cyan .alert-link {
  color: #006366;
}
.landing-page .alert-blue {
  color: #003a91;
  background-color: #ccdffc;
  border-color: #b3d0fb;
}
.landing-page .alert-blue .alert-link {
  color: #002e74;
}
.landing-page .alert-indigo {
  color: #35008b;
  background-color: #deccfa;
  border-color: #cdb3f8;
}
.landing-page .alert-indigo .alert-link {
  color: #2a006f;
}
.landing-page .alert-purple {
  color: #3f0077;
  background-color: #e1ccf4;
  border-color: #d2b3ee;
}
.landing-page .alert-purple .alert-link {
  color: #32005f;
}
.landing-page .alert-pink {
  color: #880035;
  background-color: #f9ccde;
  border-color: #f7b3cd;
}
.landing-page .alert-pink .alert-link {
  color: #6d002a;
}
.landing-page .alert-red-soft {
  color: #918688;
  background-color: #fcf9f9;
  border-color: #fbf6f7;
}
.landing-page .alert-red-soft .alert-link {
  color: #746b6d;
}
.landing-page .alert-orange-soft {
  color: #928b88;
  background-color: #fdfaf9;
  border-color: #fbf8f7;
}
.landing-page .alert-orange-soft .alert-link {
  color: #756f6d;
}
.landing-page .alert-yellow-soft {
  color: #918f88;
  background-color: #fcfcf9;
  border-color: #fbfaf7;
}
.landing-page .alert-yellow-soft .alert-link {
  color: #74726d;
}
.landing-page .alert-green-soft {
  color: #838f8e;
  background-color: #f8fcfb;
  border-color: #f4fafa;
}
.landing-page .alert-green-soft .alert-link {
  color: #697272;
}
.landing-page .alert-teal-soft {
  color: #839091;
  background-color: #f8fcfc;
  border-color: #f4fbfb;
}
.landing-page .alert-teal-soft .alert-link {
  color: #697374;
}
.landing-page .alert-cyan-soft {
  color: #839195;
  background-color: #f8fcfe;
  border-color: #f4fbfd;
}
.landing-page .alert-cyan-soft .alert-link {
  color: #697477;
}
.landing-page .alert-blue-soft {
  color: #838b97;
  background-color: #f8fafe;
  border-color: #f4f8fe;
}
.landing-page .alert-blue-soft .alert-link {
  color: #696f79;
}
.landing-page .alert-indigo-soft {
  color: #888596;
  background-color: #f9f8fe;
  border-color: #f7f5fe;
}
.landing-page .alert-indigo-soft .alert-link {
  color: #6d6a78;
}
.landing-page .alert-purple-soft {
  color: #898594;
  background-color: #faf8fd;
  border-color: #f7f5fd;
}
.landing-page .alert-purple-soft .alert-link {
  color: #6e6a76;
}
.landing-page .alert-pink-soft {
  color: #91858e;
  background-color: #fcf8fb;
  border-color: #fbf5f9;
}
.landing-page .alert-pink-soft .alert-link {
  color: #746a72;
}
.landing-page .alert-primary-soft {
  color: #838b97;
  background-color: #f8fafe;
  border-color: #f4f8fe;
}
.landing-page .alert-primary-soft .alert-link {
  color: #696f79;
}
.landing-page .alert-secondary-soft {
  color: #889197;
  background-color: #f9fcfe;
  border-color: #f7fbfe;
}
.landing-page .alert-secondary-soft .alert-link {
  color: #6d7479;
}
.landing-page .alert-success-soft {
  color: #838f8e;
  background-color: #f8fcfb;
  border-color: #f4fafa;
}
.landing-page .alert-success-soft .alert-link {
  color: #697272;
}
.landing-page .alert-info-soft {
  color: #839195;
  background-color: #f8fcfe;
  border-color: #f4fbfd;
}
.landing-page .alert-info-soft .alert-link {
  color: #697477;
}
.landing-page .alert-warning-soft {
  color: #918f88;
  background-color: #fcfcf9;
  border-color: #fbfaf7;
}
.landing-page .alert-warning-soft .alert-link {
  color: #74726d;
}
.landing-page .alert-danger-soft {
  color: #918688;
  background-color: #fcf9f9;
  border-color: #fbf6f7;
}
.landing-page .alert-danger-soft .alert-link {
  color: #746b6d;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.landing-page .progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e0e5ec;
  border-radius: 0.35rem;
}
.landing-page .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0061f2;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .progress-bar {
    transition: none;
  }
}
.landing-page .progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.landing-page .progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.landing-page .list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.35rem;
}
.landing-page .list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.landing-page .list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.landing-page .list-group-item-action {
  width: 100%;
  color: #4a515b;
  text-align: inherit;
}
.landing-page .list-group-item-action:hover,
.landing-page .list-group-item-action:focus {
  z-index: 1;
  color: #4a515b;
  text-decoration: none;
  background-color: #f2f6fc;
}
.landing-page .list-group-item-action:active {
  color: #69707a;
  background-color: #e0e5ec;
}
.landing-page .list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212832;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.landing-page .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.landing-page .list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.landing-page .list-group-item.disabled,
.landing-page .list-group-item:disabled {
  color: #69707a;
  pointer-events: none;
  background-color: #fff;
}
.landing-page .list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .list-group-item + .list-group-item {
  border-top-width: 0;
}
.landing-page .list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.landing-page .list-group-horizontal {
  flex-direction: row;
}
.landing-page .list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.35rem;
  border-top-right-radius: 0;
}
.landing-page .list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.35rem;
  border-bottom-left-radius: 0;
}
.landing-page .list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.landing-page .list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.landing-page .list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .landing-page .list-group-horizontal-sm {
    flex-direction: row;
  }
  .landing-page .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .landing-page .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .landing-page .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .landing-page .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .landing-page .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .landing-page .list-group-horizontal-md {
    flex-direction: row;
  }
  .landing-page .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .landing-page .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .landing-page .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .landing-page .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .landing-page .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .landing-page .list-group-horizontal-lg {
    flex-direction: row;
  }
  .landing-page .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .landing-page .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .landing-page .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .landing-page .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .landing-page .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .landing-page .list-group-horizontal-xl {
    flex-direction: row;
  }
  .landing-page .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .landing-page .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .landing-page .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .landing-page .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .landing-page .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1500px) {
  .landing-page .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .landing-page .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.35rem;
    border-top-right-radius: 0;
  }
  .landing-page .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.35rem;
    border-bottom-left-radius: 0;
  }
  .landing-page .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .landing-page .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .landing-page .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.landing-page .list-group-flush {
  border-radius: 0;
}
.landing-page .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.landing-page .list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.landing-page .list-group-item-primary {
  color: #003a91;
  background-color: #ccdffc;
}
.landing-page .list-group-item-primary.list-group-item-action:hover,
.landing-page .list-group-item-primary.list-group-item-action:focus {
  color: #003a91;
  background-color: #b8c9e3;
}
.landing-page .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #003a91;
  border-color: #003a91;
}
.landing-page .list-group-item-secondary {
  color: #377499;
  background-color: #def3ff;
}
.landing-page .list-group-item-secondary.list-group-item-action:hover,
.landing-page .list-group-item-secondary.list-group-item-action:focus {
  color: #377499;
  background-color: #c8dbe6;
}
.landing-page .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #377499;
  border-color: #377499;
}
.landing-page .list-group-item-success {
  color: #00673f;
  background-color: #cceee1;
}
.landing-page .list-group-item-success.list-group-item-action:hover,
.landing-page .list-group-item-success.list-group-item-action:focus {
  color: #00673f;
  background-color: #b8d6cb;
}
.landing-page .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #00673f;
  border-color: #00673f;
}
.landing-page .list-group-item-info {
  color: #007c80;
  background-color: #ccf5f7;
}
.landing-page .list-group-item-info.list-group-item-action:hover,
.landing-page .list-group-item-info.list-group-item-action:focus {
  color: #007c80;
  background-color: #b8ddde;
}
.landing-page .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007c80;
  border-color: #007c80;
}
.landing-page .list-group-item-warning {
  color: #926100;
  background-color: #fdeccc;
}
.landing-page .list-group-item-warning.list-group-item-action:hover,
.landing-page .list-group-item-warning.list-group-item-action:focus {
  color: #926100;
  background-color: #e4d4b8;
}
.landing-page .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #926100;
  border-color: #926100;
}
.landing-page .list-group-item-danger {
  color: #8b0d00;
  background-color: #fad0cc;
}
.landing-page .list-group-item-danger.list-group-item-action:hover,
.landing-page .list-group-item-danger.list-group-item-action:focus {
  color: #8b0d00;
  background-color: #e1bbb8;
}
.landing-page .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #8b0d00;
  border-color: #8b0d00;
}
.landing-page .list-group-item-light {
  color: #919497;
  background-color: #fcfdfe;
}
.landing-page .list-group-item-light.list-group-item-action:hover,
.landing-page .list-group-item-light.list-group-item-action:focus {
  color: #919497;
  background-color: #e3e4e5;
}
.landing-page .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #919497;
  border-color: #919497;
}
.landing-page .list-group-item-dark {
  color: #14181e;
  background-color: #d3d4d6;
}
.landing-page .list-group-item-dark.list-group-item-action:hover,
.landing-page .list-group-item-dark.list-group-item-action:focus {
  color: #14181e;
  background-color: #bebfc1;
}
.landing-page .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #14181e;
  border-color: #14181e;
}
.landing-page .list-group-item-black {
  color: black;
  background-color: #cccccc;
}
.landing-page .list-group-item-black.list-group-item-action:hover,
.landing-page .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.landing-page .list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}
.landing-page .list-group-item-white {
  color: #999999;
  background-color: white;
}
.landing-page .list-group-item-white.list-group-item-action:hover,
.landing-page .list-group-item-white.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.landing-page .list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
}
.landing-page .list-group-item-red {
  color: #8b0d00;
  background-color: #fad0cc;
}
.landing-page .list-group-item-red.list-group-item-action:hover,
.landing-page .list-group-item-red.list-group-item-action:focus {
  color: #8b0d00;
  background-color: #e1bbb8;
}
.landing-page .list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #8b0d00;
  border-color: #8b0d00;
}
.landing-page .list-group-item-orange {
  color: #943c00;
  background-color: #fde0cc;
}
.landing-page .list-group-item-orange.list-group-item-action:hover,
.landing-page .list-group-item-orange.list-group-item-action:focus {
  color: #943c00;
  background-color: #e4cab8;
}
.landing-page .list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #943c00;
  border-color: #943c00;
}
.landing-page .list-group-item-yellow {
  color: #926100;
  background-color: #fdeccc;
}
.landing-page .list-group-item-yellow.list-group-item-action:hover,
.landing-page .list-group-item-yellow.list-group-item-action:focus {
  color: #926100;
  background-color: #e4d4b8;
}
.landing-page .list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #926100;
  border-color: #926100;
}
.landing-page .list-group-item-green {
  color: #00673f;
  background-color: #cceee1;
}
.landing-page .list-group-item-green.list-group-item-action:hover,
.landing-page .list-group-item-green.list-group-item-action:focus {
  color: #00673f;
  background-color: #b8d6cb;
}
.landing-page .list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #00673f;
  border-color: #00673f;
}
.landing-page .list-group-item-teal {
  color: #007059;
  background-color: #ccf1ea;
}
.landing-page .list-group-item-teal.list-group-item-action:hover,
.landing-page .list-group-item-teal.list-group-item-action:focus {
  color: #007059;
  background-color: #b8d9d3;
}
.landing-page .list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #007059;
  border-color: #007059;
}
.landing-page .list-group-item-cyan {
  color: #007c80;
  background-color: #ccf5f7;
}
.landing-page .list-group-item-cyan.list-group-item-action:hover,
.landing-page .list-group-item-cyan.list-group-item-action:focus {
  color: #007c80;
  background-color: #b8ddde;
}
.landing-page .list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #007c80;
  border-color: #007c80;
}
.landing-page .list-group-item-blue {
  color: #003a91;
  background-color: #ccdffc;
}
.landing-page .list-group-item-blue.list-group-item-action:hover,
.landing-page .list-group-item-blue.list-group-item-action:focus {
  color: #003a91;
  background-color: #b8c9e3;
}
.landing-page .list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #003a91;
  border-color: #003a91;
}
.landing-page .list-group-item-indigo {
  color: #35008b;
  background-color: #deccfa;
}
.landing-page .list-group-item-indigo.list-group-item-action:hover,
.landing-page .list-group-item-indigo.list-group-item-action:focus {
  color: #35008b;
  background-color: #c8b8e1;
}
.landing-page .list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #35008b;
  border-color: #35008b;
}
.landing-page .list-group-item-purple {
  color: #3f0077;
  background-color: #e1ccf4;
}
.landing-page .list-group-item-purple.list-group-item-action:hover,
.landing-page .list-group-item-purple.list-group-item-action:focus {
  color: #3f0077;
  background-color: #cbb8dc;
}
.landing-page .list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #3f0077;
  border-color: #3f0077;
}
.landing-page .list-group-item-pink {
  color: #880035;
  background-color: #f9ccde;
}
.landing-page .list-group-item-pink.list-group-item-action:hover,
.landing-page .list-group-item-pink.list-group-item-action:focus {
  color: #880035;
  background-color: #e0b8c8;
}
.landing-page .list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #880035;
  border-color: #880035;
}
.landing-page .list-group-item-red-soft {
  color: #918688;
  background-color: #fcf9f9;
}
.landing-page .list-group-item-red-soft.list-group-item-action:hover,
.landing-page .list-group-item-red-soft.list-group-item-action:focus {
  color: #918688;
  background-color: #e3e0e0;
}
.landing-page .list-group-item-red-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918688;
  border-color: #918688;
}
.landing-page .list-group-item-orange-soft {
  color: #928b88;
  background-color: #fdfaf9;
}
.landing-page .list-group-item-orange-soft.list-group-item-action:hover,
.landing-page .list-group-item-orange-soft.list-group-item-action:focus {
  color: #928b88;
  background-color: #e4e1e0;
}
.landing-page .list-group-item-orange-soft.list-group-item-action.active {
  color: #fff;
  background-color: #928b88;
  border-color: #928b88;
}
.landing-page .list-group-item-yellow-soft {
  color: #918f88;
  background-color: #fcfcf9;
}
.landing-page .list-group-item-yellow-soft.list-group-item-action:hover,
.landing-page .list-group-item-yellow-soft.list-group-item-action:focus {
  color: #918f88;
  background-color: #e3e3e0;
}
.landing-page .list-group-item-yellow-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918f88;
  border-color: #918f88;
}
.landing-page .list-group-item-green-soft {
  color: #838f8e;
  background-color: #f8fcfb;
}
.landing-page .list-group-item-green-soft.list-group-item-action:hover,
.landing-page .list-group-item-green-soft.list-group-item-action:focus {
  color: #838f8e;
  background-color: #dfe3e2;
}
.landing-page .list-group-item-green-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838f8e;
  border-color: #838f8e;
}
.landing-page .list-group-item-teal-soft {
  color: #839091;
  background-color: #f8fcfc;
}
.landing-page .list-group-item-teal-soft.list-group-item-action:hover,
.landing-page .list-group-item-teal-soft.list-group-item-action:focus {
  color: #839091;
  background-color: #dfe3e3;
}
.landing-page .list-group-item-teal-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839091;
  border-color: #839091;
}
.landing-page .list-group-item-cyan-soft {
  color: #839195;
  background-color: #f8fcfe;
}
.landing-page .list-group-item-cyan-soft.list-group-item-action:hover,
.landing-page .list-group-item-cyan-soft.list-group-item-action:focus {
  color: #839195;
  background-color: #dfe3e5;
}
.landing-page .list-group-item-cyan-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839195;
  border-color: #839195;
}
.landing-page .list-group-item-blue-soft {
  color: #838b97;
  background-color: #f8fafe;
}
.landing-page .list-group-item-blue-soft.list-group-item-action:hover,
.landing-page .list-group-item-blue-soft.list-group-item-action:focus {
  color: #838b97;
  background-color: #dfe1e5;
}
.landing-page .list-group-item-blue-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838b97;
  border-color: #838b97;
}
.landing-page .list-group-item-indigo-soft {
  color: #888596;
  background-color: #f9f8fe;
}
.landing-page .list-group-item-indigo-soft.list-group-item-action:hover,
.landing-page .list-group-item-indigo-soft.list-group-item-action:focus {
  color: #888596;
  background-color: #e0dfe5;
}
.landing-page .list-group-item-indigo-soft.list-group-item-action.active {
  color: #fff;
  background-color: #888596;
  border-color: #888596;
}
.landing-page .list-group-item-purple-soft {
  color: #898594;
  background-color: #faf8fd;
}
.landing-page .list-group-item-purple-soft.list-group-item-action:hover,
.landing-page .list-group-item-purple-soft.list-group-item-action:focus {
  color: #898594;
  background-color: #e1dfe4;
}
.landing-page .list-group-item-purple-soft.list-group-item-action.active {
  color: #fff;
  background-color: #898594;
  border-color: #898594;
}
.landing-page .list-group-item-pink-soft {
  color: #91858e;
  background-color: #fcf8fb;
}
.landing-page .list-group-item-pink-soft.list-group-item-action:hover,
.landing-page .list-group-item-pink-soft.list-group-item-action:focus {
  color: #91858e;
  background-color: #e3dfe2;
}
.landing-page .list-group-item-pink-soft.list-group-item-action.active {
  color: #fff;
  background-color: #91858e;
  border-color: #91858e;
}
.landing-page .list-group-item-primary-soft {
  color: #838b97;
  background-color: #f8fafe;
}
.landing-page .list-group-item-primary-soft.list-group-item-action:hover,
.landing-page .list-group-item-primary-soft.list-group-item-action:focus {
  color: #838b97;
  background-color: #dfe1e5;
}
.landing-page .list-group-item-primary-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838b97;
  border-color: #838b97;
}
.landing-page .list-group-item-secondary-soft {
  color: #889197;
  background-color: #f9fcfe;
}
.landing-page .list-group-item-secondary-soft.list-group-item-action:hover,
.landing-page .list-group-item-secondary-soft.list-group-item-action:focus {
  color: #889197;
  background-color: #e0e3e5;
}
.landing-page .list-group-item-secondary-soft.list-group-item-action.active {
  color: #fff;
  background-color: #889197;
  border-color: #889197;
}
.landing-page .list-group-item-success-soft {
  color: #838f8e;
  background-color: #f8fcfb;
}
.landing-page .list-group-item-success-soft.list-group-item-action:hover,
.landing-page .list-group-item-success-soft.list-group-item-action:focus {
  color: #838f8e;
  background-color: #dfe3e2;
}
.landing-page .list-group-item-success-soft.list-group-item-action.active {
  color: #fff;
  background-color: #838f8e;
  border-color: #838f8e;
}
.landing-page .list-group-item-info-soft {
  color: #839195;
  background-color: #f8fcfe;
}
.landing-page .list-group-item-info-soft.list-group-item-action:hover,
.landing-page .list-group-item-info-soft.list-group-item-action:focus {
  color: #839195;
  background-color: #dfe3e5;
}
.landing-page .list-group-item-info-soft.list-group-item-action.active {
  color: #fff;
  background-color: #839195;
  border-color: #839195;
}
.landing-page .list-group-item-warning-soft {
  color: #918f88;
  background-color: #fcfcf9;
}
.landing-page .list-group-item-warning-soft.list-group-item-action:hover,
.landing-page .list-group-item-warning-soft.list-group-item-action:focus {
  color: #918f88;
  background-color: #e3e3e0;
}
.landing-page .list-group-item-warning-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918f88;
  border-color: #918f88;
}
.landing-page .list-group-item-danger-soft {
  color: #918688;
  background-color: #fcf9f9;
}
.landing-page .list-group-item-danger-soft.list-group-item-action:hover,
.landing-page .list-group-item-danger-soft.list-group-item-action:focus {
  color: #918688;
  background-color: #e3e0e0;
}
.landing-page .list-group-item-danger-soft.list-group-item-action.active {
  color: #fff;
  background-color: #918688;
  border-color: #918688;
}
.landing-page .btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.35rem;
  opacity: 0.5;
}
.landing-page .btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.landing-page .btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
  opacity: 1;
}
.landing-page .btn-close:disabled,
.landing-page .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.landing-page .btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.landing-page .toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  border-radius: 0.35rem;
}
.landing-page .toast:not(.showing):not(.show) {
  opacity: 0;
}
.landing-page .toast.hide {
  display: none;
}
.landing-page .toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.landing-page .toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}
.landing-page .toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #69707a;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.35rem - 1px);
  border-top-right-radius: calc(0.35rem - 1px);
}
.landing-page .toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.landing-page .toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}
.landing-page .modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.landing-page .modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.landing-page .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .modal.fade .modal-dialog {
    transition: none;
  }
}
.landing-page .modal.show .modal-dialog {
  transform: none;
}
.landing-page .modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.landing-page .modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.landing-page .modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.landing-page .modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.landing-page .modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.landing-page .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}
.landing-page .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.landing-page .modal-backdrop.fade {
  opacity: 0;
}
.landing-page .modal-backdrop.show {
  opacity: 0.5;
}
.landing-page .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e0e5ec;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.landing-page .modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.landing-page .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.landing-page .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.landing-page .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e0e5ec;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.landing-page .modal-footer > * {
  margin: 0.25rem;
}
@media (min-width: 576px) {
  .landing-page .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .landing-page .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .landing-page .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .landing-page .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .landing-page .modal-lg,
.landing-page .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .landing-page .modal-xl {
    max-width: 1140px;
  }
}
.landing-page .modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.landing-page .modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.landing-page .modal-fullscreen .modal-header {
  border-radius: 0;
}
.landing-page .modal-fullscreen .modal-body {
  overflow-y: auto;
}
.landing-page .modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .landing-page .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .landing-page .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .landing-page .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .landing-page .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .landing-page .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .landing-page .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .landing-page .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .landing-page .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .landing-page .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .landing-page .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .landing-page .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .landing-page .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1499.98px) {
  .landing-page .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .landing-page .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .landing-page .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .landing-page .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.landing-page .tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.landing-page .tooltip.show {
  opacity: 0.9;
}
.landing-page .tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.landing-page .tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.landing-page .bs-tooltip-top,
.landing-page .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.landing-page .bs-tooltip-top .tooltip-arrow,
.landing-page .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.landing-page .bs-tooltip-top .tooltip-arrow::before,
.landing-page .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.landing-page .bs-tooltip-end,
.landing-page .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.landing-page .bs-tooltip-end .tooltip-arrow,
.landing-page .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.landing-page .bs-tooltip-end .tooltip-arrow::before,
.landing-page .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.landing-page .bs-tooltip-bottom,
.landing-page .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.landing-page .bs-tooltip-bottom .tooltip-arrow,
.landing-page .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.landing-page .bs-tooltip-bottom .tooltip-arrow::before,
.landing-page .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.landing-page .bs-tooltip-start,
.landing-page .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.landing-page .bs-tooltip-start .tooltip-arrow,
.landing-page .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.landing-page .bs-tooltip-start .tooltip-arrow::before,
.landing-page .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.landing-page .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.35rem;
}
.landing-page .popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Metropolis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}
.landing-page .popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.landing-page .popover .popover-arrow::before,
.landing-page .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.landing-page .bs-popover-top > .popover-arrow,
.landing-page .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.landing-page .bs-popover-top > .popover-arrow::before,
.landing-page .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.landing-page .bs-popover-top > .popover-arrow::after,
.landing-page .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.landing-page .bs-popover-end > .popover-arrow,
.landing-page .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.landing-page .bs-popover-end > .popover-arrow::before,
.landing-page .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.landing-page .bs-popover-end > .popover-arrow::after,
.landing-page .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.landing-page .bs-popover-bottom > .popover-arrow,
.landing-page .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.landing-page .bs-popover-bottom > .popover-arrow::before,
.landing-page .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.landing-page .bs-popover-bottom > .popover-arrow::after,
.landing-page .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.landing-page .bs-popover-bottom .popover-header::before,
.landing-page .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}
.landing-page .bs-popover-start > .popover-arrow,
.landing-page .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.landing-page .bs-popover-start > .popover-arrow::before,
.landing-page .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.landing-page .bs-popover-start > .popover-arrow::after,
.landing-page .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.landing-page .popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #363d47;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.landing-page .popover-header:empty {
  display: none;
}
.landing-page .popover-body {
  padding: 1rem 1rem;
  color: #69707a;
}
.landing-page .carousel {
  position: relative;
}
.landing-page .carousel.pointer-event {
  touch-action: pan-y;
}
.landing-page .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.landing-page .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.landing-page .carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .carousel-item {
    transition: none;
  }
}
.landing-page .carousel-item.active,
.landing-page .carousel-item-next,
.landing-page .carousel-item-prev {
  display: block;
}
.landing-page .carousel-item-next:not(.carousel-item-start),
.landing-page .active.carousel-item-end {
  transform: translateX(100%);
}
.landing-page .carousel-item-prev:not(.carousel-item-end),
.landing-page .active.carousel-item-start {
  transform: translateX(-100%);
}
.landing-page .carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.landing-page .carousel-fade .carousel-item.active,
.landing-page .carousel-fade .carousel-item-next.carousel-item-start,
.landing-page .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.landing-page .carousel-fade .active.carousel-item-start,
.landing-page .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .carousel-fade .active.carousel-item-start,
.landing-page .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.landing-page .carousel-control-prev,
.landing-page .carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .carousel-control-prev,
.landing-page .carousel-control-next {
    transition: none;
  }
}
.landing-page .carousel-control-prev:hover,
.landing-page .carousel-control-prev:focus,
.landing-page .carousel-control-next:hover,
.landing-page .carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.landing-page .carousel-control-prev {
  left: 0;
}
.landing-page .carousel-control-next {
  right: 0;
}
.landing-page .carousel-control-prev-icon,
.landing-page .carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.landing-page .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.landing-page .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.landing-page .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.landing-page .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.landing-page .carousel-indicators .active {
  opacity: 1;
}
.landing-page .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.landing-page .carousel-dark .carousel-control-prev-icon,
.landing-page .carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.landing-page .carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.landing-page .carousel-dark .carousel-caption {
  color: #000;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.landing-page .spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.landing-page .spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.landing-page .spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}
.landing-page .spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .spinner-border,
.landing-page .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.landing-page .offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .landing-page .offcanvas {
    transition: none;
  }
}
.landing-page .offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.landing-page .offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}
.landing-page .offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.landing-page .offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}
.landing-page .offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}
.landing-page .offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}
.landing-page .offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}
.landing-page .offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}
.landing-page .offcanvas.show {
  transform: none;
}
.landing-page .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.landing-page .link-primary {
  color: #0061f2;
}
.landing-page .link-primary:hover,
.landing-page .link-primary:focus {
  color: #004ec2;
}
.landing-page .link-secondary {
  color: #5bc2ff;
}
.landing-page .link-secondary:hover,
.landing-page .link-secondary:focus {
  color: #499bcc;
}
.landing-page .link-success {
  color: #00ac69;
}
.landing-page .link-success:hover,
.landing-page .link-success:focus {
  color: #008a54;
}
.landing-page .link-info {
  color: #00cfd5;
}
.landing-page .link-info:hover,
.landing-page .link-info:focus {
  color: #00a6aa;
}
.landing-page .link-warning {
  color: #f4a100;
}
.landing-page .link-warning:hover,
.landing-page .link-warning:focus {
  color: #c38100;
}
.landing-page .link-danger {
  color: #e81500;
}
.landing-page .link-danger:hover,
.landing-page .link-danger:focus {
  color: #ba1100;
}
.landing-page .link-light {
  color: #f2f6fc;
}
.landing-page .link-light:hover,
.landing-page .link-light:focus {
  color: #f5f8fd;
}
.landing-page .link-dark {
  color: #212832;
}
.landing-page .link-dark:hover,
.landing-page .link-dark:focus {
  color: #1a2028;
}
.landing-page .link-black {
  color: #000;
}
.landing-page .link-black:hover,
.landing-page .link-black:focus {
  color: black;
}
.landing-page .link-white {
  color: #fff;
}
.landing-page .link-white:hover,
.landing-page .link-white:focus {
  color: white;
}
.landing-page .link-red {
  color: #e81500;
}
.landing-page .link-red:hover,
.landing-page .link-red:focus {
  color: #ba1100;
}
.landing-page .link-orange {
  color: #f76400;
}
.landing-page .link-orange:hover,
.landing-page .link-orange:focus {
  color: #c65000;
}
.landing-page .link-yellow {
  color: #f4a100;
}
.landing-page .link-yellow:hover,
.landing-page .link-yellow:focus {
  color: #c38100;
}
.landing-page .link-green {
  color: #00ac69;
}
.landing-page .link-green:hover,
.landing-page .link-green:focus {
  color: #008a54;
}
.landing-page .link-teal {
  color: #00ba94;
}
.landing-page .link-teal:hover,
.landing-page .link-teal:focus {
  color: #009576;
}
.landing-page .link-cyan {
  color: #00cfd5;
}
.landing-page .link-cyan:hover,
.landing-page .link-cyan:focus {
  color: #00a6aa;
}
.landing-page .link-blue {
  color: #0061f2;
}
.landing-page .link-blue:hover,
.landing-page .link-blue:focus {
  color: #004ec2;
}
.landing-page .link-indigo {
  color: #5800e8;
}
.landing-page .link-indigo:hover,
.landing-page .link-indigo:focus {
  color: #4600ba;
}
.landing-page .link-purple {
  color: #6900c7;
}
.landing-page .link-purple:hover,
.landing-page .link-purple:focus {
  color: #54009f;
}
.landing-page .link-pink {
  color: #e30059;
}
.landing-page .link-pink:hover,
.landing-page .link-pink:focus {
  color: #b60047;
}
.landing-page .link-red-soft {
  color: #f1e0e3;
}
.landing-page .link-red-soft:hover,
.landing-page .link-red-soft:focus {
  color: #f4e6e9;
}
.landing-page .link-orange-soft {
  color: #f3e7e3;
}
.landing-page .link-orange-soft:hover,
.landing-page .link-orange-soft:focus {
  color: #f5ece9;
}
.landing-page .link-yellow-soft {
  color: #f2eee3;
}
.landing-page .link-yellow-soft:hover,
.landing-page .link-yellow-soft:focus {
  color: #f5f1e9;
}
.landing-page .link-green-soft {
  color: #daefed;
}
.landing-page .link-green-soft:hover,
.landing-page .link-green-soft:focus {
  color: #e1f2f1;
}
.landing-page .link-teal-soft {
  color: #daf0f2;
}
.landing-page .link-teal-soft:hover,
.landing-page .link-teal-soft:focus {
  color: #e1f3f5;
}
.landing-page .link-cyan-soft {
  color: #daf2f8;
}
.landing-page .link-cyan-soft:hover,
.landing-page .link-cyan-soft:focus {
  color: #e1f5f9;
}
.landing-page .link-blue-soft {
  color: #dae7fb;
}
.landing-page .link-blue-soft:hover,
.landing-page .link-blue-soft:focus {
  color: #e1ecfc;
}
.landing-page .link-indigo-soft {
  color: #e3ddfa;
}
.landing-page .link-indigo-soft:hover,
.landing-page .link-indigo-soft:focus {
  color: #e9e4fb;
}
.landing-page .link-purple-soft {
  color: #e4ddf7;
}
.landing-page .link-purple-soft:hover,
.landing-page .link-purple-soft:focus {
  color: #e9e4f9;
}
.landing-page .link-pink-soft {
  color: #f1ddec;
}
.landing-page .link-pink-soft:hover,
.landing-page .link-pink-soft:focus {
  color: #f4e4f0;
}
.landing-page .link-primary-soft {
  color: #dae7fb;
}
.landing-page .link-primary-soft:hover,
.landing-page .link-primary-soft:focus {
  color: #e1ecfc;
}
.landing-page .link-secondary-soft {
  color: #e3f1fc;
}
.landing-page .link-secondary-soft:hover,
.landing-page .link-secondary-soft:focus {
  color: #e9f4fd;
}
.landing-page .link-success-soft {
  color: #daefed;
}
.landing-page .link-success-soft:hover,
.landing-page .link-success-soft:focus {
  color: #e1f2f1;
}
.landing-page .link-info-soft {
  color: #daf2f8;
}
.landing-page .link-info-soft:hover,
.landing-page .link-info-soft:focus {
  color: #e1f5f9;
}
.landing-page .link-warning-soft {
  color: #f2eee3;
}
.landing-page .link-warning-soft:hover,
.landing-page .link-warning-soft:focus {
  color: #f5f1e9;
}
.landing-page .link-danger-soft {
  color: #f1e0e3;
}
.landing-page .link-danger-soft:hover,
.landing-page .link-danger-soft:focus {
  color: #f4e6e9;
}
.landing-page .ratio {
  position: relative;
  width: 100%;
}
.landing-page .ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.landing-page .ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing-page .ratio-1x1 {
  --bs-aspect-ratio: 100%;
}
.landing-page .ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}
.landing-page .ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}
.landing-page .ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}
.landing-page .fixed-top,
.landing-page .page-header-ui.navbar-fixed .navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.landing-page .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
.landing-page .sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}
@media (min-width: 576px) {
  .landing-page .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .landing-page .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .landing-page .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .landing-page .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1500px) {
  .landing-page .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.landing-page .visually-hidden,
.landing-page .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.landing-page .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}
.landing-page .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.landing-page .align-baseline {
  vertical-align: baseline !important;
}
.landing-page .align-top {
  vertical-align: top !important;
}
.landing-page .align-middle {
  vertical-align: middle !important;
}
.landing-page .align-bottom {
  vertical-align: bottom !important;
}
.landing-page .align-text-bottom {
  vertical-align: text-bottom !important;
}
.landing-page .align-text-top {
  vertical-align: text-top !important;
}
.landing-page .float-start {
  float: left !important;
}
.landing-page .float-end {
  float: right !important;
}
.landing-page .float-none {
  float: none !important;
}
.landing-page .overflow-auto {
  overflow: auto !important;
}
.landing-page .overflow-hidden {
  overflow: hidden !important;
}
.landing-page .overflow-visible {
  overflow: visible !important;
}
.landing-page .overflow-scroll {
  overflow: scroll !important;
}
.landing-page .d-inline {
  display: inline !important;
}
.landing-page .d-inline-block {
  display: inline-block !important;
}
.landing-page .d-block {
  display: block !important;
}
.landing-page .d-grid {
  display: grid !important;
}
.landing-page .d-table {
  display: table !important;
}
.landing-page .d-table-row {
  display: table-row !important;
}
.landing-page .d-table-cell {
  display: table-cell !important;
}
.landing-page .d-flex {
  display: flex !important;
}
.landing-page .d-inline-flex {
  display: inline-flex !important;
}
.landing-page .d-none {
  display: none !important;
}
.landing-page .shadow {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2) !important;
}
.landing-page .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.landing-page .shadow-none {
  box-shadow: none !important;
}
.landing-page .position-static {
  position: static !important;
}
.landing-page .position-relative {
  position: relative !important;
}
.landing-page .position-absolute {
  position: absolute !important;
}
.landing-page .position-fixed {
  position: fixed !important;
}
.landing-page .position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.landing-page .top-0 {
  top: 0 !important;
}
.landing-page .top-50 {
  top: 50% !important;
}
.landing-page .top-100 {
  top: 100% !important;
}
.landing-page .bottom-0 {
  bottom: 0 !important;
}
.landing-page .bottom-50 {
  bottom: 50% !important;
}
.landing-page .bottom-100 {
  bottom: 100% !important;
}
.landing-page .start-0 {
  left: 0 !important;
}
.landing-page .start-50 {
  left: 50% !important;
}
.landing-page .start-100 {
  left: 100% !important;
}
.landing-page .end-0 {
  right: 0 !important;
}
.landing-page .end-50 {
  right: 50% !important;
}
.landing-page .end-100 {
  right: 100% !important;
}
.landing-page .translate-middle {
  transform: translate(-50%, -50%) !important;
}
.landing-page .translate-middle-x {
  transform: translateX(-50%) !important;
}
.landing-page .translate-middle-y {
  transform: translateY(-50%) !important;
}
.landing-page .border {
  border: 1px solid #e0e5ec !important;
}
.landing-page .border-0 {
  border: 0 !important;
}
.landing-page .border-top {
  border-top: 1px solid #e0e5ec !important;
}
.landing-page .border-top-0 {
  border-top: 0 !important;
}
.landing-page .border-end {
  border-right: 1px solid #e0e5ec !important;
}
.landing-page .border-end-0 {
  border-right: 0 !important;
}
.landing-page .border-bottom {
  border-bottom: 1px solid #e0e5ec !important;
}
.landing-page .border-bottom-0 {
  border-bottom: 0 !important;
}
.landing-page .border-start {
  border-left: 1px solid #e0e5ec !important;
}
.landing-page .border-start-0 {
  border-left: 0 !important;
}
.landing-page .border-primary {
  border-color: #0061f2 !important;
}
.landing-page .border-secondary {
  border-color: #5bc2ff !important;
}
.landing-page .border-success {
  border-color: #00ac69 !important;
}
.landing-page .border-info {
  border-color: #00cfd5 !important;
}
.landing-page .border-warning {
  border-color: #f4a100 !important;
}
.landing-page .border-danger {
  border-color: #e81500 !important;
}
.landing-page .border-light {
  border-color: #f2f6fc !important;
}
.landing-page .border-dark {
  border-color: #212832 !important;
}
.landing-page .border-black {
  border-color: #000 !important;
}
.landing-page .border-white {
  border-color: #fff !important;
}
.landing-page .border-red {
  border-color: #e81500 !important;
}
.landing-page .border-orange {
  border-color: #f76400 !important;
}
.landing-page .border-yellow {
  border-color: #f4a100 !important;
}
.landing-page .border-green {
  border-color: #00ac69 !important;
}
.landing-page .border-teal {
  border-color: #00ba94 !important;
}
.landing-page .border-cyan {
  border-color: #00cfd5 !important;
}
.landing-page .border-blue {
  border-color: #0061f2 !important;
}
.landing-page .border-indigo {
  border-color: #5800e8 !important;
}
.landing-page .border-purple {
  border-color: #6900c7 !important;
}
.landing-page .border-pink {
  border-color: #e30059 !important;
}
.landing-page .border-red-soft {
  border-color: #f1e0e3 !important;
}
.landing-page .border-orange-soft {
  border-color: #f3e7e3 !important;
}
.landing-page .border-yellow-soft {
  border-color: #f2eee3 !important;
}
.landing-page .border-green-soft {
  border-color: #daefed !important;
}
.landing-page .border-teal-soft {
  border-color: #daf0f2 !important;
}
.landing-page .border-cyan-soft {
  border-color: #daf2f8 !important;
}
.landing-page .border-blue-soft {
  border-color: #dae7fb !important;
}
.landing-page .border-indigo-soft {
  border-color: #e3ddfa !important;
}
.landing-page .border-purple-soft {
  border-color: #e4ddf7 !important;
}
.landing-page .border-pink-soft {
  border-color: #f1ddec !important;
}
.landing-page .border-primary-soft {
  border-color: #dae7fb !important;
}
.landing-page .border-secondary-soft {
  border-color: #e3f1fc !important;
}
.landing-page .border-success-soft {
  border-color: #daefed !important;
}
.landing-page .border-info-soft {
  border-color: #daf2f8 !important;
}
.landing-page .border-warning-soft {
  border-color: #f2eee3 !important;
}
.landing-page .border-danger-soft {
  border-color: #f1e0e3 !important;
}
.landing-page .border-1 {
  border-width: 1px !important;
}
.landing-page .border-2 {
  border-width: 2px !important;
}
.landing-page .border-3 {
  border-width: 3px !important;
}
.landing-page .border-4 {
  border-width: 4px !important;
}
.landing-page .border-5 {
  border-width: 5px !important;
}
.landing-page .w-25 {
  width: 25% !important;
}
.landing-page .w-50 {
  width: 50% !important;
}
.landing-page .w-75 {
  width: 75% !important;
}
.landing-page .w-100 {
  width: 100% !important;
}
.landing-page .w-auto {
  width: auto !important;
}
.landing-page .mw-100 {
  max-width: 100% !important;
}
.landing-page .vw-100 {
  width: 100vw !important;
}
.landing-page .min-vw-100 {
  min-width: 100vw !important;
}
.landing-page .h-25 {
  height: 25% !important;
}
.landing-page .h-50 {
  height: 50% !important;
}
.landing-page .h-75 {
  height: 75% !important;
}
.landing-page .h-100 {
  height: 100% !important;
}
.landing-page .h-auto {
  height: auto !important;
}
.landing-page .mh-100 {
  max-height: 100% !important;
}
.landing-page .vh-100 {
  height: 100vh !important;
}
.landing-page .min-vh-100 {
  min-height: 100vh !important;
}
.landing-page .flex-fill {
  flex: 1 1 auto !important;
}
.landing-page .flex-row {
  flex-direction: row !important;
}
.landing-page .flex-column {
  flex-direction: column !important;
}
.landing-page .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.landing-page .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.landing-page .flex-grow-0 {
  flex-grow: 0 !important;
}
.landing-page .flex-grow-1 {
  flex-grow: 1 !important;
}
.landing-page .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.landing-page .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.landing-page .flex-wrap {
  flex-wrap: wrap !important;
}
.landing-page .flex-nowrap {
  flex-wrap: nowrap !important;
}
.landing-page .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.landing-page .gap-0 {
  gap: 0 !important;
}
.landing-page .gap-1 {
  gap: 0.25rem !important;
}
.landing-page .gap-2 {
  gap: 0.5rem !important;
}
.landing-page .gap-3 {
  gap: 1rem !important;
}
.landing-page .gap-4 {
  gap: 1.5rem !important;
}
.landing-page .gap-5 {
  gap: 2.5rem !important;
}
.landing-page .gap-10 {
  gap: 6rem !important;
}
.landing-page .gap-15 {
  gap: 9rem !important;
}
.landing-page .justify-content-start {
  justify-content: flex-start !important;
}
.landing-page .justify-content-end {
  justify-content: flex-end !important;
}
.landing-page .justify-content-center {
  justify-content: center !important;
}
.landing-page .justify-content-between {
  justify-content: space-between !important;
}
.landing-page .justify-content-around {
  justify-content: space-around !important;
}
.landing-page .justify-content-evenly {
  justify-content: space-evenly !important;
}
.landing-page .align-items-start {
  align-items: flex-start !important;
}
.landing-page .align-items-end {
  align-items: flex-end !important;
}
.landing-page .align-items-center {
  align-items: center !important;
}
.landing-page .align-items-baseline {
  align-items: baseline !important;
}
.landing-page .align-items-stretch {
  align-items: stretch !important;
}
.landing-page .align-content-start {
  align-content: flex-start !important;
}
.landing-page .align-content-end {
  align-content: flex-end !important;
}
.landing-page .align-content-center {
  align-content: center !important;
}
.landing-page .align-content-between {
  align-content: space-between !important;
}
.landing-page .align-content-around {
  align-content: space-around !important;
}
.landing-page .align-content-stretch {
  align-content: stretch !important;
}
.landing-page .align-self-auto {
  align-self: auto !important;
}
.landing-page .align-self-start {
  align-self: flex-start !important;
}
.landing-page .align-self-end {
  align-self: flex-end !important;
}
.landing-page .align-self-center {
  align-self: center !important;
}
.landing-page .align-self-baseline {
  align-self: baseline !important;
}
.landing-page .align-self-stretch {
  align-self: stretch !important;
}
.landing-page .order-first {
  order: -1 !important;
}
.landing-page .order-0 {
  order: 0 !important;
}
.landing-page .order-1 {
  order: 1 !important;
}
.landing-page .order-2 {
  order: 2 !important;
}
.landing-page .order-3 {
  order: 3 !important;
}
.landing-page .order-4 {
  order: 4 !important;
}
.landing-page .order-5 {
  order: 5 !important;
}
.landing-page .order-last {
  order: 6 !important;
}
.landing-page .m-0 {
  margin: 0 !important;
}
.landing-page .m-1 {
  margin: 0.25rem !important;
}
.landing-page .m-2 {
  margin: 0.5rem !important;
}
.landing-page .m-3 {
  margin: 1rem !important;
}
.landing-page .m-4 {
  margin: 1.5rem !important;
}
.landing-page .m-5 {
  margin: 2.5rem !important;
}
.landing-page .m-10 {
  margin: 6rem !important;
}
.landing-page .m-15 {
  margin: 9rem !important;
}
.landing-page .m-auto {
  margin: auto !important;
}
.landing-page .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.landing-page .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.landing-page .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.landing-page .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.landing-page .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.landing-page .mx-5 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}
.landing-page .mx-10 {
  margin-right: 6rem !important;
  margin-left: 6rem !important;
}
.landing-page .mx-15 {
  margin-right: 9rem !important;
  margin-left: 9rem !important;
}
.landing-page .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.landing-page .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.landing-page .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.landing-page .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.landing-page .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.landing-page .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.landing-page .my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.landing-page .my-10 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}
.landing-page .my-15 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}
.landing-page .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.landing-page .mt-0 {
  margin-top: 0 !important;
}
.landing-page .mt-1 {
  margin-top: 0.25rem !important;
}
.landing-page .mt-2 {
  margin-top: 0.5rem !important;
}
.landing-page .mt-3 {
  margin-top: 1rem !important;
}
.landing-page .mt-4 {
  margin-top: 1.5rem !important;
}
.landing-page .mt-5 {
  margin-top: 2.5rem !important;
}
.landing-page .mt-10 {
  margin-top: 6rem !important;
}
.landing-page .mt-15 {
  margin-top: 9rem !important;
}
.landing-page .mt-auto {
  margin-top: auto !important;
}
.landing-page .me-0 {
  margin-right: 0 !important;
}
.landing-page .me-1 {
  margin-right: 0.25rem !important;
}
.landing-page .me-2 {
  margin-right: 0.5rem !important;
}
.landing-page .me-3 {
  margin-right: 1rem !important;
}
.landing-page .me-4 {
  margin-right: 1.5rem !important;
}
.landing-page .me-5 {
  margin-right: 2.5rem !important;
}
.landing-page .me-10 {
  margin-right: 6rem !important;
}
.landing-page .me-15 {
  margin-right: 9rem !important;
}
.landing-page .me-auto {
  margin-right: auto !important;
}
.landing-page .mb-0 {
  margin-bottom: 0 !important;
}
.landing-page .mb-1 {
  margin-bottom: 0.25rem !important;
}
.landing-page .mb-2 {
  margin-bottom: 0.5rem !important;
}
.landing-page .mb-3 {
  margin-bottom: 1rem !important;
}
.landing-page .mb-4 {
  margin-bottom: 1.5rem !important;
}
.landing-page .mb-5 {
  margin-bottom: 2.5rem !important;
}
.landing-page .mb-10 {
  margin-bottom: 6rem !important;
}
.landing-page .mb-15 {
  margin-bottom: 9rem !important;
}
.landing-page .mb-auto {
  margin-bottom: auto !important;
}
.landing-page .ms-0 {
  margin-left: 0 !important;
}
.landing-page .ms-1 {
  margin-left: 0.25rem !important;
}
.landing-page .ms-2 {
  margin-left: 0.5rem !important;
}
.landing-page .ms-3 {
  margin-left: 1rem !important;
}
.landing-page .ms-4 {
  margin-left: 1.5rem !important;
}
.landing-page .ms-5 {
  margin-left: 2.5rem !important;
}
.landing-page .ms-10 {
  margin-left: 6rem !important;
}
.landing-page .ms-15 {
  margin-left: 9rem !important;
}
.landing-page .ms-auto {
  margin-left: auto !important;
}
.landing-page .m-n1 {
  margin: -0.25rem !important;
}
.landing-page .m-n2 {
  margin: -0.5rem !important;
}
.landing-page .m-n3 {
  margin: -1rem !important;
}
.landing-page .m-n4 {
  margin: -1.5rem !important;
}
.landing-page .m-n5 {
  margin: -2.5rem !important;
}
.landing-page .m-n10 {
  margin: -6rem !important;
}
.landing-page .m-n15 {
  margin: -9rem !important;
}
.landing-page .mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
.landing-page .mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.landing-page .mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.landing-page .mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.landing-page .mx-n5 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}
.landing-page .mx-n10 {
  margin-right: -6rem !important;
  margin-left: -6rem !important;
}
.landing-page .mx-n15 {
  margin-right: -9rem !important;
  margin-left: -9rem !important;
}
.landing-page .my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.landing-page .my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.landing-page .my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.landing-page .my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.landing-page .my-n5 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}
.landing-page .my-n10 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}
.landing-page .my-n15 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}
.landing-page .mt-n1 {
  margin-top: -0.25rem !important;
}
.landing-page .mt-n2 {
  margin-top: -0.5rem !important;
}
.landing-page .mt-n3 {
  margin-top: -1rem !important;
}
.landing-page .mt-n4 {
  margin-top: -1.5rem !important;
}
.landing-page .mt-n5 {
  margin-top: -2.5rem !important;
}
.landing-page .mt-n10 {
  margin-top: -6rem !important;
}
.landing-page .mt-n15 {
  margin-top: -9rem !important;
}
.landing-page .me-n1 {
  margin-right: -0.25rem !important;
}
.landing-page .me-n2 {
  margin-right: -0.5rem !important;
}
.landing-page .me-n3 {
  margin-right: -1rem !important;
}
.landing-page .me-n4 {
  margin-right: -1.5rem !important;
}
.landing-page .me-n5 {
  margin-right: -2.5rem !important;
}
.landing-page .me-n10 {
  margin-right: -6rem !important;
}
.landing-page .me-n15 {
  margin-right: -9rem !important;
}
.landing-page .mb-n1 {
  margin-bottom: -0.25rem !important;
}
.landing-page .mb-n2 {
  margin-bottom: -0.5rem !important;
}
.landing-page .mb-n3 {
  margin-bottom: -1rem !important;
}
.landing-page .mb-n4 {
  margin-bottom: -1.5rem !important;
}
.landing-page .mb-n5 {
  margin-bottom: -2.5rem !important;
}
.landing-page .mb-n10 {
  margin-bottom: -6rem !important;
}
.landing-page .mb-n15 {
  margin-bottom: -9rem !important;
}
.landing-page .ms-n1 {
  margin-left: -0.25rem !important;
}
.landing-page .ms-n2 {
  margin-left: -0.5rem !important;
}
.landing-page .ms-n3 {
  margin-left: -1rem !important;
}
.landing-page .ms-n4 {
  margin-left: -1.5rem !important;
}
.landing-page .ms-n5 {
  margin-left: -2.5rem !important;
}
.landing-page .ms-n10 {
  margin-left: -6rem !important;
}
.landing-page .ms-n15 {
  margin-left: -9rem !important;
}
.landing-page .p-0 {
  padding: 0 !important;
}
.landing-page .p-1 {
  padding: 0.25rem !important;
}
.landing-page .p-2 {
  padding: 0.5rem !important;
}
.landing-page .p-3 {
  padding: 1rem !important;
}
.landing-page .p-4 {
  padding: 1.5rem !important;
}
.landing-page .p-5 {
  padding: 2.5rem !important;
}
.landing-page .p-10 {
  padding: 6rem !important;
}
.landing-page .p-15 {
  padding: 9rem !important;
}
.landing-page .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.landing-page .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.landing-page .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.landing-page .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.landing-page .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.landing-page .px-5 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}
.landing-page .px-10 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}
.landing-page .px-15 {
  padding-right: 9rem !important;
  padding-left: 9rem !important;
}
.landing-page .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.landing-page .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.landing-page .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.landing-page .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.landing-page .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.landing-page .py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.landing-page .py-10 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}
.landing-page .py-15 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}
.landing-page .pt-0 {
  padding-top: 0 !important;
}
.landing-page .pt-1 {
  padding-top: 0.25rem !important;
}
.landing-page .pt-2 {
  padding-top: 0.5rem !important;
}
.landing-page .pt-3 {
  padding-top: 1rem !important;
}
.landing-page .pt-4 {
  padding-top: 1.5rem !important;
}
.landing-page .pt-5 {
  padding-top: 2.5rem !important;
}
.landing-page .pt-10 {
  padding-top: 6rem !important;
}
.landing-page .pt-15 {
  padding-top: 9rem !important;
}
.landing-page .pe-0 {
  padding-right: 0 !important;
}
.landing-page .pe-1 {
  padding-right: 0.25rem !important;
}
.landing-page .pe-2 {
  padding-right: 0.5rem !important;
}
.landing-page .pe-3 {
  padding-right: 1rem !important;
}
.landing-page .pe-4 {
  padding-right: 1.5rem !important;
}
.landing-page .pe-5 {
  padding-right: 2.5rem !important;
}
.landing-page .pe-10 {
  padding-right: 6rem !important;
}
.landing-page .pe-15 {
  padding-right: 9rem !important;
}
.landing-page .pb-0 {
  padding-bottom: 0 !important;
}
.landing-page .pb-1 {
  padding-bottom: 0.25rem !important;
}
.landing-page .pb-2 {
  padding-bottom: 0.5rem !important;
}
.landing-page .pb-3 {
  padding-bottom: 1rem !important;
}
.landing-page .pb-4 {
  padding-bottom: 1.5rem !important;
}
.landing-page .pb-5 {
  padding-bottom: 2.5rem !important;
}
.landing-page .pb-10 {
  padding-bottom: 6rem !important;
}
.landing-page .pb-15 {
  padding-bottom: 9rem !important;
}
.landing-page .ps-0 {
  padding-left: 0 !important;
}
.landing-page .ps-1 {
  padding-left: 0.25rem !important;
}
.landing-page .ps-2 {
  padding-left: 0.5rem !important;
}
.landing-page .ps-3 {
  padding-left: 1rem !important;
}
.landing-page .ps-4 {
  padding-left: 1.5rem !important;
}
.landing-page .ps-5 {
  padding-left: 2.5rem !important;
}
.landing-page .ps-10 {
  padding-left: 6rem !important;
}
.landing-page .ps-15 {
  padding-left: 9rem !important;
}
.landing-page .font-monospace {
  font-family: var(--bs-font-monospace) !important;
}
.landing-page .fs-1 {
  font-size: calc(1.275rem + 0.3vw) !important;
}
.landing-page .fs-2 {
  font-size: calc(1.265rem + 0.18vw) !important;
}
.landing-page .fs-3 {
  font-size: calc(1.255rem + 0.06vw) !important;
}
.landing-page .fs-4 {
  font-size: 1.2rem !important;
}
.landing-page .fs-5 {
  font-size: 1.1rem !important;
}
.landing-page .fs-6 {
  font-size: 1rem !important;
}
.landing-page .fst-italic {
  font-style: italic !important;
}
.landing-page .fst-normal {
  font-style: normal !important;
}
.landing-page .fw-light {
  font-weight: 300 !important;
}
.landing-page .fw-lighter {
  font-weight: lighter !important;
}
.landing-page .fw-normal {
  font-weight: 400 !important;
}
.landing-page .fw-bold {
  font-weight: 500 !important;
}
.landing-page .fw-bolder {
  font-weight: bolder !important;
}
.landing-page .lh-1 {
  line-height: 1 !important;
}
.landing-page .lh-sm {
  line-height: 1.25 !important;
}
.landing-page .lh-base {
  line-height: 1.5 !important;
}
.landing-page .lh-lg {
  line-height: 2 !important;
}
.landing-page .text-start {
  text-align: left !important;
}
.landing-page .text-end {
  text-align: right !important;
}
.landing-page .text-center {
  text-align: center !important;
}
.landing-page .text-decoration-none {
  text-decoration: none !important;
}
.landing-page .text-decoration-underline {
  text-decoration: underline !important;
}
.landing-page .text-decoration-line-through {
  text-decoration: line-through !important;
}
.landing-page .text-lowercase {
  text-transform: lowercase !important;
}
.landing-page .text-uppercase {
  text-transform: uppercase !important;
}
.landing-page .text-capitalize {
  text-transform: capitalize !important;
}
.landing-page .text-wrap {
  white-space: normal !important;
}
.landing-page .text-nowrap {
  white-space: nowrap !important;
}
.landing-page .text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.landing-page .text-primary {
  color: #0061f2 !important;
}
.landing-page .text-secondary {
  color: #5bc2ff !important;
}
.landing-page .text-success {
  color: #00ac69 !important;
}
.landing-page .text-info {
  color: #00cfd5 !important;
}
.landing-page .text-warning {
  color: #f4a100 !important;
}
.landing-page .text-danger {
  color: #e81500 !important;
}
.landing-page .text-light,
.landing-page .page-header-ui-dark .btn-link {
  color: #f2f6fc !important;
}
.landing-page .text-dark,
.landing-page .page-header-ui-light .btn-link {
  color: #212832 !important;
}
.landing-page .text-black {
  color: #000 !important;
}
.landing-page .text-white {
  color: #fff !important;
}
.landing-page .text-red {
  color: #e81500 !important;
}
.landing-page .text-orange {
  color: #f76400 !important;
}
.landing-page .text-yellow {
  color: #f4a100 !important;
}
.landing-page .text-green {
  color: #00ac69 !important;
}
.landing-page .text-teal {
  color: #00ba94 !important;
}
.landing-page .text-cyan {
  color: #00cfd5 !important;
}
.landing-page .text-blue {
  color: #0061f2 !important;
}
.landing-page .text-indigo {
  color: #5800e8 !important;
}
.landing-page .text-purple {
  color: #6900c7 !important;
}
.landing-page .text-pink {
  color: #e30059 !important;
}
.landing-page .text-red-soft {
  color: #f1e0e3 !important;
}
.landing-page .text-orange-soft {
  color: #f3e7e3 !important;
}
.landing-page .text-yellow-soft {
  color: #f2eee3 !important;
}
.landing-page .text-green-soft {
  color: #daefed !important;
}
.landing-page .text-teal-soft {
  color: #daf0f2 !important;
}
.landing-page .text-cyan-soft {
  color: #daf2f8 !important;
}
.landing-page .text-blue-soft {
  color: #dae7fb !important;
}
.landing-page .text-indigo-soft {
  color: #e3ddfa !important;
}
.landing-page .text-purple-soft {
  color: #e4ddf7 !important;
}
.landing-page .text-pink-soft {
  color: #f1ddec !important;
}
.landing-page .text-primary-soft {
  color: #dae7fb !important;
}
.landing-page .text-secondary-soft {
  color: #e3f1fc !important;
}
.landing-page .text-success-soft {
  color: #daefed !important;
}
.landing-page .text-info-soft {
  color: #daf2f8 !important;
}
.landing-page .text-warning-soft {
  color: #f2eee3 !important;
}
.landing-page .text-danger-soft {
  color: #f1e0e3 !important;
}
.landing-page .text-body {
  color: #69707a !important;
}
.landing-page .text-muted {
  color: #a7aeb8 !important;
}
.landing-page .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.landing-page .text-white-50,
.landing-page .page-header-ui-dark .page-header-ui-text {
  color: rgba(255, 255, 255, 0.5) !important;
}
.landing-page .text-reset,
.landing-page .footer a {
  color: inherit !important;
}
.landing-page .bg-primary {
  background-color: #0061f2 !important;
}
.landing-page .bg-secondary {
  background-color: #5bc2ff !important;
}
.landing-page .bg-success {
  background-color: #00ac69 !important;
}
.landing-page .bg-info {
  background-color: #00cfd5 !important;
}
.landing-page .bg-warning {
  background-color: #f4a100 !important;
}
.landing-page .bg-danger {
  background-color: #e81500 !important;
}
.landing-page .bg-light {
  background-color: #f2f6fc !important;
}
.landing-page .bg-dark {
  background-color: #212832 !important;
}
.landing-page .bg-black {
  background-color: #000 !important;
}
.landing-page .bg-white {
  background-color: #fff !important;
}
.landing-page .bg-red {
  background-color: #e81500 !important;
}
.landing-page .bg-orange {
  background-color: #f76400 !important;
}
.landing-page .bg-yellow {
  background-color: #f4a100 !important;
}
.landing-page .bg-green {
  background-color: #00ac69 !important;
}
.landing-page .bg-teal {
  background-color: #00ba94 !important;
}
.landing-page .bg-cyan {
  background-color: #00cfd5 !important;
}
.landing-page .bg-blue {
  background-color: #0061f2 !important;
}
.landing-page .bg-indigo {
  background-color: #5800e8 !important;
}
.landing-page .bg-purple {
  background-color: #6900c7 !important;
}
.landing-page .bg-pink {
  background-color: #e30059 !important;
}
.landing-page .bg-red-soft {
  background-color: #f1e0e3 !important;
}
.landing-page .bg-orange-soft {
  background-color: #f3e7e3 !important;
}
.landing-page .bg-yellow-soft {
  background-color: #f2eee3 !important;
}
.landing-page .bg-green-soft {
  background-color: #daefed !important;
}
.landing-page .bg-teal-soft {
  background-color: #daf0f2 !important;
}
.landing-page .bg-cyan-soft {
  background-color: #daf2f8 !important;
}
.landing-page .bg-blue-soft {
  background-color: #dae7fb !important;
}
.landing-page .bg-indigo-soft {
  background-color: #e3ddfa !important;
}
.landing-page .bg-purple-soft {
  background-color: #e4ddf7 !important;
}
.landing-page .bg-pink-soft {
  background-color: #f1ddec !important;
}
.landing-page .bg-primary-soft {
  background-color: #dae7fb !important;
}
.landing-page .bg-secondary-soft {
  background-color: #e3f1fc !important;
}
.landing-page .bg-success-soft {
  background-color: #daefed !important;
}
.landing-page .bg-info-soft {
  background-color: #daf2f8 !important;
}
.landing-page .bg-warning-soft {
  background-color: #f2eee3 !important;
}
.landing-page .bg-danger-soft {
  background-color: #f1e0e3 !important;
}
.landing-page .bg-body {
  background-color: #f2f6fc !important;
}
.landing-page .bg-transparent {
  background-color: transparent !important;
}
.landing-page .bg-gradient {
  background-image: var(--bs-gradient) !important;
}
.landing-page .user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}
.landing-page .user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}
.landing-page .user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.landing-page .pe-none {
  pointer-events: none !important;
}
.landing-page .pe-auto {
  pointer-events: auto !important;
}
.landing-page .rounded {
  border-radius: 0.35rem !important;
}
.landing-page .rounded-0 {
  border-radius: 0 !important;
}
.landing-page .rounded-1 {
  border-radius: 0.25rem !important;
}
.landing-page .rounded-2 {
  border-radius: 0.35rem !important;
}
.landing-page .rounded-3 {
  border-radius: 0.5rem !important;
}
.landing-page .rounded-circle {
  border-radius: 50% !important;
}
.landing-page .rounded-pill {
  border-radius: 50rem !important;
}
.landing-page .rounded-top {
  border-top-left-radius: 0.35rem !important;
  border-top-right-radius: 0.35rem !important;
}
.landing-page .rounded-end {
  border-top-right-radius: 0.35rem !important;
  border-bottom-right-radius: 0.35rem !important;
}
.landing-page .rounded-bottom {
  border-bottom-right-radius: 0.35rem !important;
  border-bottom-left-radius: 0.35rem !important;
}
.landing-page .rounded-start {
  border-bottom-left-radius: 0.35rem !important;
  border-top-left-radius: 0.35rem !important;
}
.landing-page .visible {
  visibility: visible !important;
}
.landing-page .invisible {
  visibility: hidden !important;
}
@media (min-width: 576px) {
  .landing-page .float-sm-start {
    float: left !important;
  }
  .landing-page .float-sm-end {
    float: right !important;
  }
  .landing-page .float-sm-none {
    float: none !important;
  }
  .landing-page .d-sm-inline {
    display: inline !important;
  }
  .landing-page .d-sm-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-sm-block {
    display: block !important;
  }
  .landing-page .d-sm-grid {
    display: grid !important;
  }
  .landing-page .d-sm-table {
    display: table !important;
  }
  .landing-page .d-sm-table-row {
    display: table-row !important;
  }
  .landing-page .d-sm-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-sm-flex {
    display: flex !important;
  }
  .landing-page .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-sm-none {
    display: none !important;
  }
  .landing-page .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .landing-page .flex-sm-row {
    flex-direction: row !important;
  }
  .landing-page .flex-sm-column {
    flex-direction: column !important;
  }
  .landing-page .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .landing-page .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .landing-page .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .landing-page .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .landing-page .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .landing-page .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .landing-page .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .landing-page .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .landing-page .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .landing-page .gap-sm-0 {
    gap: 0 !important;
  }
  .landing-page .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .landing-page .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .landing-page .gap-sm-3 {
    gap: 1rem !important;
  }
  .landing-page .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .landing-page .gap-sm-5 {
    gap: 2.5rem !important;
  }
  .landing-page .gap-sm-10 {
    gap: 6rem !important;
  }
  .landing-page .gap-sm-15 {
    gap: 9rem !important;
  }
  .landing-page .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .landing-page .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .landing-page .justify-content-sm-center {
    justify-content: center !important;
  }
  .landing-page .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .landing-page .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .landing-page .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .landing-page .align-items-sm-start {
    align-items: flex-start !important;
  }
  .landing-page .align-items-sm-end {
    align-items: flex-end !important;
  }
  .landing-page .align-items-sm-center {
    align-items: center !important;
  }
  .landing-page .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .landing-page .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .landing-page .align-content-sm-start {
    align-content: flex-start !important;
  }
  .landing-page .align-content-sm-end {
    align-content: flex-end !important;
  }
  .landing-page .align-content-sm-center {
    align-content: center !important;
  }
  .landing-page .align-content-sm-between {
    align-content: space-between !important;
  }
  .landing-page .align-content-sm-around {
    align-content: space-around !important;
  }
  .landing-page .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .landing-page .align-self-sm-auto {
    align-self: auto !important;
  }
  .landing-page .align-self-sm-start {
    align-self: flex-start !important;
  }
  .landing-page .align-self-sm-end {
    align-self: flex-end !important;
  }
  .landing-page .align-self-sm-center {
    align-self: center !important;
  }
  .landing-page .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .landing-page .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .landing-page .order-sm-first {
    order: -1 !important;
  }
  .landing-page .order-sm-0 {
    order: 0 !important;
  }
  .landing-page .order-sm-1 {
    order: 1 !important;
  }
  .landing-page .order-sm-2 {
    order: 2 !important;
  }
  .landing-page .order-sm-3 {
    order: 3 !important;
  }
  .landing-page .order-sm-4 {
    order: 4 !important;
  }
  .landing-page .order-sm-5 {
    order: 5 !important;
  }
  .landing-page .order-sm-last {
    order: 6 !important;
  }
  .landing-page .m-sm-0 {
    margin: 0 !important;
  }
  .landing-page .m-sm-1 {
    margin: 0.25rem !important;
  }
  .landing-page .m-sm-2 {
    margin: 0.5rem !important;
  }
  .landing-page .m-sm-3 {
    margin: 1rem !important;
  }
  .landing-page .m-sm-4 {
    margin: 1.5rem !important;
  }
  .landing-page .m-sm-5 {
    margin: 2.5rem !important;
  }
  .landing-page .m-sm-10 {
    margin: 6rem !important;
  }
  .landing-page .m-sm-15 {
    margin: 9rem !important;
  }
  .landing-page .m-sm-auto {
    margin: auto !important;
  }
  .landing-page .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .landing-page .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .landing-page .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .landing-page .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .landing-page .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .landing-page .mx-sm-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .landing-page .mx-sm-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .landing-page .mx-sm-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .landing-page .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .landing-page .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .landing-page .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .landing-page .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .landing-page .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .landing-page .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .landing-page .my-sm-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .landing-page .my-sm-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .landing-page .my-sm-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .landing-page .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .landing-page .mt-sm-0 {
    margin-top: 0 !important;
  }
  .landing-page .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .landing-page .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .landing-page .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .landing-page .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .landing-page .mt-sm-5 {
    margin-top: 2.5rem !important;
  }
  .landing-page .mt-sm-10 {
    margin-top: 6rem !important;
  }
  .landing-page .mt-sm-15 {
    margin-top: 9rem !important;
  }
  .landing-page .mt-sm-auto {
    margin-top: auto !important;
  }
  .landing-page .me-sm-0 {
    margin-right: 0 !important;
  }
  .landing-page .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .landing-page .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .landing-page .me-sm-3 {
    margin-right: 1rem !important;
  }
  .landing-page .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .landing-page .me-sm-5 {
    margin-right: 2.5rem !important;
  }
  .landing-page .me-sm-10 {
    margin-right: 6rem !important;
  }
  .landing-page .me-sm-15 {
    margin-right: 9rem !important;
  }
  .landing-page .me-sm-auto {
    margin-right: auto !important;
  }
  .landing-page .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .landing-page .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .landing-page .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .landing-page .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .landing-page .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .landing-page .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }
  .landing-page .mb-sm-10 {
    margin-bottom: 6rem !important;
  }
  .landing-page .mb-sm-15 {
    margin-bottom: 9rem !important;
  }
  .landing-page .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .landing-page .ms-sm-0 {
    margin-left: 0 !important;
  }
  .landing-page .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .landing-page .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .landing-page .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .landing-page .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .landing-page .ms-sm-5 {
    margin-left: 2.5rem !important;
  }
  .landing-page .ms-sm-10 {
    margin-left: 6rem !important;
  }
  .landing-page .ms-sm-15 {
    margin-left: 9rem !important;
  }
  .landing-page .ms-sm-auto {
    margin-left: auto !important;
  }
  .landing-page .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .landing-page .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .landing-page .m-sm-n3 {
    margin: -1rem !important;
  }
  .landing-page .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .landing-page .m-sm-n5 {
    margin: -2.5rem !important;
  }
  .landing-page .m-sm-n10 {
    margin: -6rem !important;
  }
  .landing-page .m-sm-n15 {
    margin: -9rem !important;
  }
  .landing-page .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .landing-page .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .landing-page .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .landing-page .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .landing-page .mx-sm-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .landing-page .mx-sm-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .landing-page .mx-sm-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .landing-page .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .landing-page .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .landing-page .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .landing-page .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .landing-page .my-sm-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .landing-page .my-sm-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .landing-page .my-sm-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .landing-page .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .landing-page .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .landing-page .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .landing-page .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .landing-page .mt-sm-n5 {
    margin-top: -2.5rem !important;
  }
  .landing-page .mt-sm-n10 {
    margin-top: -6rem !important;
  }
  .landing-page .mt-sm-n15 {
    margin-top: -9rem !important;
  }
  .landing-page .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .landing-page .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .landing-page .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .landing-page .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .landing-page .me-sm-n5 {
    margin-right: -2.5rem !important;
  }
  .landing-page .me-sm-n10 {
    margin-right: -6rem !important;
  }
  .landing-page .me-sm-n15 {
    margin-right: -9rem !important;
  }
  .landing-page .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .landing-page .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .landing-page .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .landing-page .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .landing-page .mb-sm-n5 {
    margin-bottom: -2.5rem !important;
  }
  .landing-page .mb-sm-n10 {
    margin-bottom: -6rem !important;
  }
  .landing-page .mb-sm-n15 {
    margin-bottom: -9rem !important;
  }
  .landing-page .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .landing-page .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .landing-page .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .landing-page .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .landing-page .ms-sm-n5 {
    margin-left: -2.5rem !important;
  }
  .landing-page .ms-sm-n10 {
    margin-left: -6rem !important;
  }
  .landing-page .ms-sm-n15 {
    margin-left: -9rem !important;
  }
  .landing-page .p-sm-0 {
    padding: 0 !important;
  }
  .landing-page .p-sm-1 {
    padding: 0.25rem !important;
  }
  .landing-page .p-sm-2 {
    padding: 0.5rem !important;
  }
  .landing-page .p-sm-3 {
    padding: 1rem !important;
  }
  .landing-page .p-sm-4 {
    padding: 1.5rem !important;
  }
  .landing-page .p-sm-5 {
    padding: 2.5rem !important;
  }
  .landing-page .p-sm-10 {
    padding: 6rem !important;
  }
  .landing-page .p-sm-15 {
    padding: 9rem !important;
  }
  .landing-page .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .landing-page .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .landing-page .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .landing-page .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .landing-page .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .landing-page .px-sm-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .landing-page .px-sm-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .landing-page .px-sm-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .landing-page .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .landing-page .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .landing-page .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .landing-page .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .landing-page .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .landing-page .py-sm-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .landing-page .py-sm-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .landing-page .py-sm-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .landing-page .pt-sm-0 {
    padding-top: 0 !important;
  }
  .landing-page .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .landing-page .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .landing-page .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .landing-page .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .landing-page .pt-sm-5 {
    padding-top: 2.5rem !important;
  }
  .landing-page .pt-sm-10 {
    padding-top: 6rem !important;
  }
  .landing-page .pt-sm-15 {
    padding-top: 9rem !important;
  }
  .landing-page .pe-sm-0 {
    padding-right: 0 !important;
  }
  .landing-page .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .landing-page .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .landing-page .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .landing-page .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .landing-page .pe-sm-5 {
    padding-right: 2.5rem !important;
  }
  .landing-page .pe-sm-10 {
    padding-right: 6rem !important;
  }
  .landing-page .pe-sm-15 {
    padding-right: 9rem !important;
  }
  .landing-page .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .landing-page .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .landing-page .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .landing-page .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .landing-page .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .landing-page .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }
  .landing-page .pb-sm-10 {
    padding-bottom: 6rem !important;
  }
  .landing-page .pb-sm-15 {
    padding-bottom: 9rem !important;
  }
  .landing-page .ps-sm-0 {
    padding-left: 0 !important;
  }
  .landing-page .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .landing-page .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .landing-page .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .landing-page .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .landing-page .ps-sm-5 {
    padding-left: 2.5rem !important;
  }
  .landing-page .ps-sm-10 {
    padding-left: 6rem !important;
  }
  .landing-page .ps-sm-15 {
    padding-left: 9rem !important;
  }
  .landing-page .text-sm-start {
    text-align: left !important;
  }
  .landing-page .text-sm-end {
    text-align: right !important;
  }
  .landing-page .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .landing-page .float-md-start {
    float: left !important;
  }
  .landing-page .float-md-end {
    float: right !important;
  }
  .landing-page .float-md-none {
    float: none !important;
  }
  .landing-page .d-md-inline {
    display: inline !important;
  }
  .landing-page .d-md-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-md-block {
    display: block !important;
  }
  .landing-page .d-md-grid {
    display: grid !important;
  }
  .landing-page .d-md-table {
    display: table !important;
  }
  .landing-page .d-md-table-row {
    display: table-row !important;
  }
  .landing-page .d-md-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-md-flex {
    display: flex !important;
  }
  .landing-page .d-md-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-md-none {
    display: none !important;
  }
  .landing-page .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .landing-page .flex-md-row {
    flex-direction: row !important;
  }
  .landing-page .flex-md-column {
    flex-direction: column !important;
  }
  .landing-page .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .landing-page .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .landing-page .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .landing-page .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .landing-page .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .landing-page .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .landing-page .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .landing-page .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .landing-page .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .landing-page .gap-md-0 {
    gap: 0 !important;
  }
  .landing-page .gap-md-1 {
    gap: 0.25rem !important;
  }
  .landing-page .gap-md-2 {
    gap: 0.5rem !important;
  }
  .landing-page .gap-md-3 {
    gap: 1rem !important;
  }
  .landing-page .gap-md-4 {
    gap: 1.5rem !important;
  }
  .landing-page .gap-md-5 {
    gap: 2.5rem !important;
  }
  .landing-page .gap-md-10 {
    gap: 6rem !important;
  }
  .landing-page .gap-md-15 {
    gap: 9rem !important;
  }
  .landing-page .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .landing-page .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .landing-page .justify-content-md-center {
    justify-content: center !important;
  }
  .landing-page .justify-content-md-between {
    justify-content: space-between !important;
  }
  .landing-page .justify-content-md-around {
    justify-content: space-around !important;
  }
  .landing-page .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .landing-page .align-items-md-start {
    align-items: flex-start !important;
  }
  .landing-page .align-items-md-end {
    align-items: flex-end !important;
  }
  .landing-page .align-items-md-center {
    align-items: center !important;
  }
  .landing-page .align-items-md-baseline {
    align-items: baseline !important;
  }
  .landing-page .align-items-md-stretch {
    align-items: stretch !important;
  }
  .landing-page .align-content-md-start {
    align-content: flex-start !important;
  }
  .landing-page .align-content-md-end {
    align-content: flex-end !important;
  }
  .landing-page .align-content-md-center {
    align-content: center !important;
  }
  .landing-page .align-content-md-between {
    align-content: space-between !important;
  }
  .landing-page .align-content-md-around {
    align-content: space-around !important;
  }
  .landing-page .align-content-md-stretch {
    align-content: stretch !important;
  }
  .landing-page .align-self-md-auto {
    align-self: auto !important;
  }
  .landing-page .align-self-md-start {
    align-self: flex-start !important;
  }
  .landing-page .align-self-md-end {
    align-self: flex-end !important;
  }
  .landing-page .align-self-md-center {
    align-self: center !important;
  }
  .landing-page .align-self-md-baseline {
    align-self: baseline !important;
  }
  .landing-page .align-self-md-stretch {
    align-self: stretch !important;
  }
  .landing-page .order-md-first {
    order: -1 !important;
  }
  .landing-page .order-md-0 {
    order: 0 !important;
  }
  .landing-page .order-md-1 {
    order: 1 !important;
  }
  .landing-page .order-md-2 {
    order: 2 !important;
  }
  .landing-page .order-md-3 {
    order: 3 !important;
  }
  .landing-page .order-md-4 {
    order: 4 !important;
  }
  .landing-page .order-md-5 {
    order: 5 !important;
  }
  .landing-page .order-md-last {
    order: 6 !important;
  }
  .landing-page .m-md-0 {
    margin: 0 !important;
  }
  .landing-page .m-md-1 {
    margin: 0.25rem !important;
  }
  .landing-page .m-md-2 {
    margin: 0.5rem !important;
  }
  .landing-page .m-md-3 {
    margin: 1rem !important;
  }
  .landing-page .m-md-4 {
    margin: 1.5rem !important;
  }
  .landing-page .m-md-5 {
    margin: 2.5rem !important;
  }
  .landing-page .m-md-10 {
    margin: 6rem !important;
  }
  .landing-page .m-md-15 {
    margin: 9rem !important;
  }
  .landing-page .m-md-auto {
    margin: auto !important;
  }
  .landing-page .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .landing-page .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .landing-page .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .landing-page .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .landing-page .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .landing-page .mx-md-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .landing-page .mx-md-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .landing-page .mx-md-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .landing-page .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .landing-page .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .landing-page .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .landing-page .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .landing-page .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .landing-page .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .landing-page .my-md-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .landing-page .my-md-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .landing-page .my-md-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .landing-page .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .landing-page .mt-md-0 {
    margin-top: 0 !important;
  }
  .landing-page .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .landing-page .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .landing-page .mt-md-3 {
    margin-top: 1rem !important;
  }
  .landing-page .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .landing-page .mt-md-5 {
    margin-top: 2.5rem !important;
  }
  .landing-page .mt-md-10 {
    margin-top: 6rem !important;
  }
  .landing-page .mt-md-15 {
    margin-top: 9rem !important;
  }
  .landing-page .mt-md-auto {
    margin-top: auto !important;
  }
  .landing-page .me-md-0 {
    margin-right: 0 !important;
  }
  .landing-page .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .landing-page .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .landing-page .me-md-3 {
    margin-right: 1rem !important;
  }
  .landing-page .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .landing-page .me-md-5 {
    margin-right: 2.5rem !important;
  }
  .landing-page .me-md-10 {
    margin-right: 6rem !important;
  }
  .landing-page .me-md-15 {
    margin-right: 9rem !important;
  }
  .landing-page .me-md-auto {
    margin-right: auto !important;
  }
  .landing-page .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .landing-page .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .landing-page .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .landing-page .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .landing-page .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .landing-page .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }
  .landing-page .mb-md-10 {
    margin-bottom: 6rem !important;
  }
  .landing-page .mb-md-15 {
    margin-bottom: 9rem !important;
  }
  .landing-page .mb-md-auto {
    margin-bottom: auto !important;
  }
  .landing-page .ms-md-0 {
    margin-left: 0 !important;
  }
  .landing-page .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .landing-page .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .landing-page .ms-md-3 {
    margin-left: 1rem !important;
  }
  .landing-page .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .landing-page .ms-md-5 {
    margin-left: 2.5rem !important;
  }
  .landing-page .ms-md-10 {
    margin-left: 6rem !important;
  }
  .landing-page .ms-md-15 {
    margin-left: 9rem !important;
  }
  .landing-page .ms-md-auto {
    margin-left: auto !important;
  }
  .landing-page .m-md-n1 {
    margin: -0.25rem !important;
  }
  .landing-page .m-md-n2 {
    margin: -0.5rem !important;
  }
  .landing-page .m-md-n3 {
    margin: -1rem !important;
  }
  .landing-page .m-md-n4 {
    margin: -1.5rem !important;
  }
  .landing-page .m-md-n5 {
    margin: -2.5rem !important;
  }
  .landing-page .m-md-n10 {
    margin: -6rem !important;
  }
  .landing-page .m-md-n15 {
    margin: -9rem !important;
  }
  .landing-page .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .landing-page .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .landing-page .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .landing-page .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .landing-page .mx-md-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .landing-page .mx-md-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .landing-page .mx-md-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .landing-page .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .landing-page .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .landing-page .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .landing-page .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .landing-page .my-md-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .landing-page .my-md-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .landing-page .my-md-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .landing-page .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .landing-page .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .landing-page .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .landing-page .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .landing-page .mt-md-n5 {
    margin-top: -2.5rem !important;
  }
  .landing-page .mt-md-n10 {
    margin-top: -6rem !important;
  }
  .landing-page .mt-md-n15 {
    margin-top: -9rem !important;
  }
  .landing-page .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .landing-page .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .landing-page .me-md-n3 {
    margin-right: -1rem !important;
  }
  .landing-page .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .landing-page .me-md-n5 {
    margin-right: -2.5rem !important;
  }
  .landing-page .me-md-n10 {
    margin-right: -6rem !important;
  }
  .landing-page .me-md-n15 {
    margin-right: -9rem !important;
  }
  .landing-page .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .landing-page .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .landing-page .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .landing-page .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .landing-page .mb-md-n5 {
    margin-bottom: -2.5rem !important;
  }
  .landing-page .mb-md-n10 {
    margin-bottom: -6rem !important;
  }
  .landing-page .mb-md-n15 {
    margin-bottom: -9rem !important;
  }
  .landing-page .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .landing-page .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .landing-page .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .landing-page .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .landing-page .ms-md-n5 {
    margin-left: -2.5rem !important;
  }
  .landing-page .ms-md-n10 {
    margin-left: -6rem !important;
  }
  .landing-page .ms-md-n15 {
    margin-left: -9rem !important;
  }
  .landing-page .p-md-0 {
    padding: 0 !important;
  }
  .landing-page .p-md-1 {
    padding: 0.25rem !important;
  }
  .landing-page .p-md-2 {
    padding: 0.5rem !important;
  }
  .landing-page .p-md-3 {
    padding: 1rem !important;
  }
  .landing-page .p-md-4 {
    padding: 1.5rem !important;
  }
  .landing-page .p-md-5 {
    padding: 2.5rem !important;
  }
  .landing-page .p-md-10 {
    padding: 6rem !important;
  }
  .landing-page .p-md-15 {
    padding: 9rem !important;
  }
  .landing-page .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .landing-page .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .landing-page .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .landing-page .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .landing-page .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .landing-page .px-md-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .landing-page .px-md-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .landing-page .px-md-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .landing-page .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .landing-page .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .landing-page .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .landing-page .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .landing-page .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .landing-page .py-md-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .landing-page .py-md-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .landing-page .py-md-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .landing-page .pt-md-0 {
    padding-top: 0 !important;
  }
  .landing-page .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .landing-page .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .landing-page .pt-md-3 {
    padding-top: 1rem !important;
  }
  .landing-page .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .landing-page .pt-md-5 {
    padding-top: 2.5rem !important;
  }
  .landing-page .pt-md-10 {
    padding-top: 6rem !important;
  }
  .landing-page .pt-md-15 {
    padding-top: 9rem !important;
  }
  .landing-page .pe-md-0 {
    padding-right: 0 !important;
  }
  .landing-page .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .landing-page .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .landing-page .pe-md-3 {
    padding-right: 1rem !important;
  }
  .landing-page .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .landing-page .pe-md-5 {
    padding-right: 2.5rem !important;
  }
  .landing-page .pe-md-10 {
    padding-right: 6rem !important;
  }
  .landing-page .pe-md-15 {
    padding-right: 9rem !important;
  }
  .landing-page .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .landing-page .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .landing-page .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .landing-page .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .landing-page .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .landing-page .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }
  .landing-page .pb-md-10 {
    padding-bottom: 6rem !important;
  }
  .landing-page .pb-md-15 {
    padding-bottom: 9rem !important;
  }
  .landing-page .ps-md-0 {
    padding-left: 0 !important;
  }
  .landing-page .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .landing-page .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .landing-page .ps-md-3 {
    padding-left: 1rem !important;
  }
  .landing-page .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .landing-page .ps-md-5 {
    padding-left: 2.5rem !important;
  }
  .landing-page .ps-md-10 {
    padding-left: 6rem !important;
  }
  .landing-page .ps-md-15 {
    padding-left: 9rem !important;
  }
  .landing-page .text-md-start {
    text-align: left !important;
  }
  .landing-page .text-md-end {
    text-align: right !important;
  }
  .landing-page .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .landing-page .float-lg-start {
    float: left !important;
  }
  .landing-page .float-lg-end {
    float: right !important;
  }
  .landing-page .float-lg-none {
    float: none !important;
  }
  .landing-page .d-lg-inline {
    display: inline !important;
  }
  .landing-page .d-lg-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-lg-block {
    display: block !important;
  }
  .landing-page .d-lg-grid {
    display: grid !important;
  }
  .landing-page .d-lg-table {
    display: table !important;
  }
  .landing-page .d-lg-table-row {
    display: table-row !important;
  }
  .landing-page .d-lg-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-lg-flex {
    display: flex !important;
  }
  .landing-page .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-lg-none {
    display: none !important;
  }
  .landing-page .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .landing-page .flex-lg-row {
    flex-direction: row !important;
  }
  .landing-page .flex-lg-column {
    flex-direction: column !important;
  }
  .landing-page .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .landing-page .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .landing-page .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .landing-page .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .landing-page .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .landing-page .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .landing-page .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .landing-page .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .landing-page .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .landing-page .gap-lg-0 {
    gap: 0 !important;
  }
  .landing-page .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .landing-page .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .landing-page .gap-lg-3 {
    gap: 1rem !important;
  }
  .landing-page .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .landing-page .gap-lg-5 {
    gap: 2.5rem !important;
  }
  .landing-page .gap-lg-10 {
    gap: 6rem !important;
  }
  .landing-page .gap-lg-15 {
    gap: 9rem !important;
  }
  .landing-page .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .landing-page .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .landing-page .justify-content-lg-center {
    justify-content: center !important;
  }
  .landing-page .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .landing-page .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .landing-page .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .landing-page .align-items-lg-start {
    align-items: flex-start !important;
  }
  .landing-page .align-items-lg-end {
    align-items: flex-end !important;
  }
  .landing-page .align-items-lg-center {
    align-items: center !important;
  }
  .landing-page .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .landing-page .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .landing-page .align-content-lg-start {
    align-content: flex-start !important;
  }
  .landing-page .align-content-lg-end {
    align-content: flex-end !important;
  }
  .landing-page .align-content-lg-center {
    align-content: center !important;
  }
  .landing-page .align-content-lg-between {
    align-content: space-between !important;
  }
  .landing-page .align-content-lg-around {
    align-content: space-around !important;
  }
  .landing-page .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .landing-page .align-self-lg-auto {
    align-self: auto !important;
  }
  .landing-page .align-self-lg-start {
    align-self: flex-start !important;
  }
  .landing-page .align-self-lg-end {
    align-self: flex-end !important;
  }
  .landing-page .align-self-lg-center {
    align-self: center !important;
  }
  .landing-page .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .landing-page .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .landing-page .order-lg-first {
    order: -1 !important;
  }
  .landing-page .order-lg-0 {
    order: 0 !important;
  }
  .landing-page .order-lg-1 {
    order: 1 !important;
  }
  .landing-page .order-lg-2 {
    order: 2 !important;
  }
  .landing-page .order-lg-3 {
    order: 3 !important;
  }
  .landing-page .order-lg-4 {
    order: 4 !important;
  }
  .landing-page .order-lg-5 {
    order: 5 !important;
  }
  .landing-page .order-lg-last {
    order: 6 !important;
  }
  .landing-page .m-lg-0 {
    margin: 0 !important;
  }
  .landing-page .m-lg-1 {
    margin: 0.25rem !important;
  }
  .landing-page .m-lg-2 {
    margin: 0.5rem !important;
  }
  .landing-page .m-lg-3 {
    margin: 1rem !important;
  }
  .landing-page .m-lg-4 {
    margin: 1.5rem !important;
  }
  .landing-page .m-lg-5 {
    margin: 2.5rem !important;
  }
  .landing-page .m-lg-10 {
    margin: 6rem !important;
  }
  .landing-page .m-lg-15 {
    margin: 9rem !important;
  }
  .landing-page .m-lg-auto {
    margin: auto !important;
  }
  .landing-page .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .landing-page .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .landing-page .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .landing-page .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .landing-page .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .landing-page .mx-lg-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .landing-page .mx-lg-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .landing-page .mx-lg-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .landing-page .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .landing-page .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .landing-page .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .landing-page .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .landing-page .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .landing-page .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .landing-page .my-lg-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .landing-page .my-lg-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .landing-page .my-lg-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .landing-page .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .landing-page .mt-lg-0 {
    margin-top: 0 !important;
  }
  .landing-page .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .landing-page .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .landing-page .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .landing-page .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .landing-page .mt-lg-5 {
    margin-top: 2.5rem !important;
  }
  .landing-page .mt-lg-10 {
    margin-top: 6rem !important;
  }
  .landing-page .mt-lg-15 {
    margin-top: 9rem !important;
  }
  .landing-page .mt-lg-auto {
    margin-top: auto !important;
  }
  .landing-page .me-lg-0 {
    margin-right: 0 !important;
  }
  .landing-page .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .landing-page .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .landing-page .me-lg-3 {
    margin-right: 1rem !important;
  }
  .landing-page .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .landing-page .me-lg-5 {
    margin-right: 2.5rem !important;
  }
  .landing-page .me-lg-10 {
    margin-right: 6rem !important;
  }
  .landing-page .me-lg-15 {
    margin-right: 9rem !important;
  }
  .landing-page .me-lg-auto {
    margin-right: auto !important;
  }
  .landing-page .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .landing-page .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .landing-page .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .landing-page .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .landing-page .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .landing-page .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }
  .landing-page .mb-lg-10 {
    margin-bottom: 6rem !important;
  }
  .landing-page .mb-lg-15 {
    margin-bottom: 9rem !important;
  }
  .landing-page .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .landing-page .ms-lg-0 {
    margin-left: 0 !important;
  }
  .landing-page .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .landing-page .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .landing-page .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .landing-page .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .landing-page .ms-lg-5 {
    margin-left: 2.5rem !important;
  }
  .landing-page .ms-lg-10 {
    margin-left: 6rem !important;
  }
  .landing-page .ms-lg-15 {
    margin-left: 9rem !important;
  }
  .landing-page .ms-lg-auto {
    margin-left: auto !important;
  }
  .landing-page .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .landing-page .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .landing-page .m-lg-n3 {
    margin: -1rem !important;
  }
  .landing-page .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .landing-page .m-lg-n5 {
    margin: -2.5rem !important;
  }
  .landing-page .m-lg-n10 {
    margin: -6rem !important;
  }
  .landing-page .m-lg-n15 {
    margin: -9rem !important;
  }
  .landing-page .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .landing-page .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .landing-page .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .landing-page .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .landing-page .mx-lg-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .landing-page .mx-lg-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .landing-page .mx-lg-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .landing-page .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .landing-page .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .landing-page .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .landing-page .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .landing-page .my-lg-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .landing-page .my-lg-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .landing-page .my-lg-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .landing-page .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .landing-page .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .landing-page .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .landing-page .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .landing-page .mt-lg-n5 {
    margin-top: -2.5rem !important;
  }
  .landing-page .mt-lg-n10 {
    margin-top: -6rem !important;
  }
  .landing-page .mt-lg-n15 {
    margin-top: -9rem !important;
  }
  .landing-page .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .landing-page .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .landing-page .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .landing-page .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .landing-page .me-lg-n5 {
    margin-right: -2.5rem !important;
  }
  .landing-page .me-lg-n10 {
    margin-right: -6rem !important;
  }
  .landing-page .me-lg-n15 {
    margin-right: -9rem !important;
  }
  .landing-page .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .landing-page .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .landing-page .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .landing-page .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .landing-page .mb-lg-n5 {
    margin-bottom: -2.5rem !important;
  }
  .landing-page .mb-lg-n10 {
    margin-bottom: -6rem !important;
  }
  .landing-page .mb-lg-n15 {
    margin-bottom: -9rem !important;
  }
  .landing-page .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .landing-page .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .landing-page .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .landing-page .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .landing-page .ms-lg-n5 {
    margin-left: -2.5rem !important;
  }
  .landing-page .ms-lg-n10 {
    margin-left: -6rem !important;
  }
  .landing-page .ms-lg-n15 {
    margin-left: -9rem !important;
  }
  .landing-page .p-lg-0 {
    padding: 0 !important;
  }
  .landing-page .p-lg-1 {
    padding: 0.25rem !important;
  }
  .landing-page .p-lg-2 {
    padding: 0.5rem !important;
  }
  .landing-page .p-lg-3 {
    padding: 1rem !important;
  }
  .landing-page .p-lg-4 {
    padding: 1.5rem !important;
  }
  .landing-page .p-lg-5 {
    padding: 2.5rem !important;
  }
  .landing-page .p-lg-10 {
    padding: 6rem !important;
  }
  .landing-page .p-lg-15 {
    padding: 9rem !important;
  }
  .landing-page .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .landing-page .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .landing-page .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .landing-page .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .landing-page .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .landing-page .px-lg-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .landing-page .px-lg-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .landing-page .px-lg-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .landing-page .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .landing-page .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .landing-page .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .landing-page .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .landing-page .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .landing-page .py-lg-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .landing-page .py-lg-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .landing-page .py-lg-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .landing-page .pt-lg-0 {
    padding-top: 0 !important;
  }
  .landing-page .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .landing-page .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .landing-page .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .landing-page .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .landing-page .pt-lg-5 {
    padding-top: 2.5rem !important;
  }
  .landing-page .pt-lg-10 {
    padding-top: 6rem !important;
  }
  .landing-page .pt-lg-15 {
    padding-top: 9rem !important;
  }
  .landing-page .pe-lg-0 {
    padding-right: 0 !important;
  }
  .landing-page .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .landing-page .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .landing-page .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .landing-page .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .landing-page .pe-lg-5 {
    padding-right: 2.5rem !important;
  }
  .landing-page .pe-lg-10 {
    padding-right: 6rem !important;
  }
  .landing-page .pe-lg-15 {
    padding-right: 9rem !important;
  }
  .landing-page .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .landing-page .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .landing-page .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .landing-page .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .landing-page .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .landing-page .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }
  .landing-page .pb-lg-10 {
    padding-bottom: 6rem !important;
  }
  .landing-page .pb-lg-15 {
    padding-bottom: 9rem !important;
  }
  .landing-page .ps-lg-0 {
    padding-left: 0 !important;
  }
  .landing-page .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .landing-page .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .landing-page .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .landing-page .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .landing-page .ps-lg-5 {
    padding-left: 2.5rem !important;
  }
  .landing-page .ps-lg-10 {
    padding-left: 6rem !important;
  }
  .landing-page .ps-lg-15 {
    padding-left: 9rem !important;
  }
  .landing-page .text-lg-start {
    text-align: left !important;
  }
  .landing-page .text-lg-end {
    text-align: right !important;
  }
  .landing-page .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .landing-page .float-xl-start {
    float: left !important;
  }
  .landing-page .float-xl-end {
    float: right !important;
  }
  .landing-page .float-xl-none {
    float: none !important;
  }
  .landing-page .d-xl-inline {
    display: inline !important;
  }
  .landing-page .d-xl-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-xl-block {
    display: block !important;
  }
  .landing-page .d-xl-grid {
    display: grid !important;
  }
  .landing-page .d-xl-table {
    display: table !important;
  }
  .landing-page .d-xl-table-row {
    display: table-row !important;
  }
  .landing-page .d-xl-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-xl-flex {
    display: flex !important;
  }
  .landing-page .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-xl-none {
    display: none !important;
  }
  .landing-page .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .landing-page .flex-xl-row {
    flex-direction: row !important;
  }
  .landing-page .flex-xl-column {
    flex-direction: column !important;
  }
  .landing-page .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .landing-page .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .landing-page .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .landing-page .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .landing-page .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .landing-page .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .landing-page .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .landing-page .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .landing-page .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .landing-page .gap-xl-0 {
    gap: 0 !important;
  }
  .landing-page .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .landing-page .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .landing-page .gap-xl-3 {
    gap: 1rem !important;
  }
  .landing-page .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .landing-page .gap-xl-5 {
    gap: 2.5rem !important;
  }
  .landing-page .gap-xl-10 {
    gap: 6rem !important;
  }
  .landing-page .gap-xl-15 {
    gap: 9rem !important;
  }
  .landing-page .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .landing-page .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .landing-page .justify-content-xl-center {
    justify-content: center !important;
  }
  .landing-page .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .landing-page .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .landing-page .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .landing-page .align-items-xl-start {
    align-items: flex-start !important;
  }
  .landing-page .align-items-xl-end {
    align-items: flex-end !important;
  }
  .landing-page .align-items-xl-center {
    align-items: center !important;
  }
  .landing-page .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .landing-page .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .landing-page .align-content-xl-start {
    align-content: flex-start !important;
  }
  .landing-page .align-content-xl-end {
    align-content: flex-end !important;
  }
  .landing-page .align-content-xl-center {
    align-content: center !important;
  }
  .landing-page .align-content-xl-between {
    align-content: space-between !important;
  }
  .landing-page .align-content-xl-around {
    align-content: space-around !important;
  }
  .landing-page .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .landing-page .align-self-xl-auto {
    align-self: auto !important;
  }
  .landing-page .align-self-xl-start {
    align-self: flex-start !important;
  }
  .landing-page .align-self-xl-end {
    align-self: flex-end !important;
  }
  .landing-page .align-self-xl-center {
    align-self: center !important;
  }
  .landing-page .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .landing-page .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .landing-page .order-xl-first {
    order: -1 !important;
  }
  .landing-page .order-xl-0 {
    order: 0 !important;
  }
  .landing-page .order-xl-1 {
    order: 1 !important;
  }
  .landing-page .order-xl-2 {
    order: 2 !important;
  }
  .landing-page .order-xl-3 {
    order: 3 !important;
  }
  .landing-page .order-xl-4 {
    order: 4 !important;
  }
  .landing-page .order-xl-5 {
    order: 5 !important;
  }
  .landing-page .order-xl-last {
    order: 6 !important;
  }
  .landing-page .m-xl-0 {
    margin: 0 !important;
  }
  .landing-page .m-xl-1 {
    margin: 0.25rem !important;
  }
  .landing-page .m-xl-2 {
    margin: 0.5rem !important;
  }
  .landing-page .m-xl-3 {
    margin: 1rem !important;
  }
  .landing-page .m-xl-4 {
    margin: 1.5rem !important;
  }
  .landing-page .m-xl-5 {
    margin: 2.5rem !important;
  }
  .landing-page .m-xl-10 {
    margin: 6rem !important;
  }
  .landing-page .m-xl-15 {
    margin: 9rem !important;
  }
  .landing-page .m-xl-auto {
    margin: auto !important;
  }
  .landing-page .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .landing-page .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .landing-page .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .landing-page .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .landing-page .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .landing-page .mx-xl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .landing-page .mx-xl-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .landing-page .mx-xl-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .landing-page .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .landing-page .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .landing-page .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .landing-page .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .landing-page .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .landing-page .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .landing-page .my-xl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .landing-page .my-xl-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .landing-page .my-xl-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .landing-page .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .landing-page .mt-xl-0 {
    margin-top: 0 !important;
  }
  .landing-page .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .landing-page .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .landing-page .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .landing-page .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .landing-page .mt-xl-5 {
    margin-top: 2.5rem !important;
  }
  .landing-page .mt-xl-10 {
    margin-top: 6rem !important;
  }
  .landing-page .mt-xl-15 {
    margin-top: 9rem !important;
  }
  .landing-page .mt-xl-auto {
    margin-top: auto !important;
  }
  .landing-page .me-xl-0 {
    margin-right: 0 !important;
  }
  .landing-page .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .landing-page .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .landing-page .me-xl-3 {
    margin-right: 1rem !important;
  }
  .landing-page .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .landing-page .me-xl-5 {
    margin-right: 2.5rem !important;
  }
  .landing-page .me-xl-10 {
    margin-right: 6rem !important;
  }
  .landing-page .me-xl-15 {
    margin-right: 9rem !important;
  }
  .landing-page .me-xl-auto {
    margin-right: auto !important;
  }
  .landing-page .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .landing-page .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .landing-page .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .landing-page .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .landing-page .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .landing-page .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }
  .landing-page .mb-xl-10 {
    margin-bottom: 6rem !important;
  }
  .landing-page .mb-xl-15 {
    margin-bottom: 9rem !important;
  }
  .landing-page .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .landing-page .ms-xl-0 {
    margin-left: 0 !important;
  }
  .landing-page .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .landing-page .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .landing-page .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .landing-page .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .landing-page .ms-xl-5 {
    margin-left: 2.5rem !important;
  }
  .landing-page .ms-xl-10 {
    margin-left: 6rem !important;
  }
  .landing-page .ms-xl-15 {
    margin-left: 9rem !important;
  }
  .landing-page .ms-xl-auto {
    margin-left: auto !important;
  }
  .landing-page .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .landing-page .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .landing-page .m-xl-n3 {
    margin: -1rem !important;
  }
  .landing-page .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .landing-page .m-xl-n5 {
    margin: -2.5rem !important;
  }
  .landing-page .m-xl-n10 {
    margin: -6rem !important;
  }
  .landing-page .m-xl-n15 {
    margin: -9rem !important;
  }
  .landing-page .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .landing-page .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .landing-page .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .landing-page .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .landing-page .mx-xl-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .landing-page .mx-xl-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .landing-page .mx-xl-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .landing-page .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .landing-page .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .landing-page .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .landing-page .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .landing-page .my-xl-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .landing-page .my-xl-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .landing-page .my-xl-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .landing-page .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .landing-page .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .landing-page .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .landing-page .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .landing-page .mt-xl-n5 {
    margin-top: -2.5rem !important;
  }
  .landing-page .mt-xl-n10 {
    margin-top: -6rem !important;
  }
  .landing-page .mt-xl-n15 {
    margin-top: -9rem !important;
  }
  .landing-page .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .landing-page .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .landing-page .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .landing-page .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .landing-page .me-xl-n5 {
    margin-right: -2.5rem !important;
  }
  .landing-page .me-xl-n10 {
    margin-right: -6rem !important;
  }
  .landing-page .me-xl-n15 {
    margin-right: -9rem !important;
  }
  .landing-page .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .landing-page .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .landing-page .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .landing-page .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .landing-page .mb-xl-n5 {
    margin-bottom: -2.5rem !important;
  }
  .landing-page .mb-xl-n10 {
    margin-bottom: -6rem !important;
  }
  .landing-page .mb-xl-n15 {
    margin-bottom: -9rem !important;
  }
  .landing-page .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .landing-page .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .landing-page .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .landing-page .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .landing-page .ms-xl-n5 {
    margin-left: -2.5rem !important;
  }
  .landing-page .ms-xl-n10 {
    margin-left: -6rem !important;
  }
  .landing-page .ms-xl-n15 {
    margin-left: -9rem !important;
  }
  .landing-page .p-xl-0 {
    padding: 0 !important;
  }
  .landing-page .p-xl-1 {
    padding: 0.25rem !important;
  }
  .landing-page .p-xl-2 {
    padding: 0.5rem !important;
  }
  .landing-page .p-xl-3 {
    padding: 1rem !important;
  }
  .landing-page .p-xl-4 {
    padding: 1.5rem !important;
  }
  .landing-page .p-xl-5 {
    padding: 2.5rem !important;
  }
  .landing-page .p-xl-10 {
    padding: 6rem !important;
  }
  .landing-page .p-xl-15 {
    padding: 9rem !important;
  }
  .landing-page .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .landing-page .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .landing-page .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .landing-page .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .landing-page .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .landing-page .px-xl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .landing-page .px-xl-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .landing-page .px-xl-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .landing-page .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .landing-page .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .landing-page .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .landing-page .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .landing-page .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .landing-page .py-xl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .landing-page .py-xl-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .landing-page .py-xl-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .landing-page .pt-xl-0 {
    padding-top: 0 !important;
  }
  .landing-page .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .landing-page .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .landing-page .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .landing-page .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .landing-page .pt-xl-5 {
    padding-top: 2.5rem !important;
  }
  .landing-page .pt-xl-10 {
    padding-top: 6rem !important;
  }
  .landing-page .pt-xl-15 {
    padding-top: 9rem !important;
  }
  .landing-page .pe-xl-0 {
    padding-right: 0 !important;
  }
  .landing-page .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .landing-page .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .landing-page .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .landing-page .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .landing-page .pe-xl-5 {
    padding-right: 2.5rem !important;
  }
  .landing-page .pe-xl-10 {
    padding-right: 6rem !important;
  }
  .landing-page .pe-xl-15 {
    padding-right: 9rem !important;
  }
  .landing-page .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .landing-page .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .landing-page .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .landing-page .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .landing-page .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .landing-page .pb-xl-5 {
    padding-bottom: 2.5rem !important;
  }
  .landing-page .pb-xl-10 {
    padding-bottom: 6rem !important;
  }
  .landing-page .pb-xl-15 {
    padding-bottom: 9rem !important;
  }
  .landing-page .ps-xl-0 {
    padding-left: 0 !important;
  }
  .landing-page .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .landing-page .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .landing-page .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .landing-page .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .landing-page .ps-xl-5 {
    padding-left: 2.5rem !important;
  }
  .landing-page .ps-xl-10 {
    padding-left: 6rem !important;
  }
  .landing-page .ps-xl-15 {
    padding-left: 9rem !important;
  }
  .landing-page .text-xl-start {
    text-align: left !important;
  }
  .landing-page .text-xl-end {
    text-align: right !important;
  }
  .landing-page .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .landing-page .float-xxl-start {
    float: left !important;
  }
  .landing-page .float-xxl-end {
    float: right !important;
  }
  .landing-page .float-xxl-none {
    float: none !important;
  }
  .landing-page .d-xxl-inline {
    display: inline !important;
  }
  .landing-page .d-xxl-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-xxl-block {
    display: block !important;
  }
  .landing-page .d-xxl-grid {
    display: grid !important;
  }
  .landing-page .d-xxl-table {
    display: table !important;
  }
  .landing-page .d-xxl-table-row {
    display: table-row !important;
  }
  .landing-page .d-xxl-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-xxl-flex {
    display: flex !important;
  }
  .landing-page .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-xxl-none {
    display: none !important;
  }
  .landing-page .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .landing-page .flex-xxl-row {
    flex-direction: row !important;
  }
  .landing-page .flex-xxl-column {
    flex-direction: column !important;
  }
  .landing-page .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .landing-page .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .landing-page .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .landing-page .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .landing-page .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .landing-page .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .landing-page .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .landing-page .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .landing-page .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .landing-page .gap-xxl-0 {
    gap: 0 !important;
  }
  .landing-page .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .landing-page .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .landing-page .gap-xxl-3 {
    gap: 1rem !important;
  }
  .landing-page .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .landing-page .gap-xxl-5 {
    gap: 2.5rem !important;
  }
  .landing-page .gap-xxl-10 {
    gap: 6rem !important;
  }
  .landing-page .gap-xxl-15 {
    gap: 9rem !important;
  }
  .landing-page .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .landing-page .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .landing-page .justify-content-xxl-center {
    justify-content: center !important;
  }
  .landing-page .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .landing-page .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .landing-page .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .landing-page .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .landing-page .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .landing-page .align-items-xxl-center {
    align-items: center !important;
  }
  .landing-page .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .landing-page .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .landing-page .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .landing-page .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .landing-page .align-content-xxl-center {
    align-content: center !important;
  }
  .landing-page .align-content-xxl-between {
    align-content: space-between !important;
  }
  .landing-page .align-content-xxl-around {
    align-content: space-around !important;
  }
  .landing-page .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .landing-page .align-self-xxl-auto {
    align-self: auto !important;
  }
  .landing-page .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .landing-page .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .landing-page .align-self-xxl-center {
    align-self: center !important;
  }
  .landing-page .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .landing-page .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .landing-page .order-xxl-first {
    order: -1 !important;
  }
  .landing-page .order-xxl-0 {
    order: 0 !important;
  }
  .landing-page .order-xxl-1 {
    order: 1 !important;
  }
  .landing-page .order-xxl-2 {
    order: 2 !important;
  }
  .landing-page .order-xxl-3 {
    order: 3 !important;
  }
  .landing-page .order-xxl-4 {
    order: 4 !important;
  }
  .landing-page .order-xxl-5 {
    order: 5 !important;
  }
  .landing-page .order-xxl-last {
    order: 6 !important;
  }
  .landing-page .m-xxl-0 {
    margin: 0 !important;
  }
  .landing-page .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .landing-page .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .landing-page .m-xxl-3 {
    margin: 1rem !important;
  }
  .landing-page .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .landing-page .m-xxl-5 {
    margin: 2.5rem !important;
  }
  .landing-page .m-xxl-10 {
    margin: 6rem !important;
  }
  .landing-page .m-xxl-15 {
    margin: 9rem !important;
  }
  .landing-page .m-xxl-auto {
    margin: auto !important;
  }
  .landing-page .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .landing-page .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .landing-page .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .landing-page .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .landing-page .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .landing-page .mx-xxl-5 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .landing-page .mx-xxl-10 {
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .landing-page .mx-xxl-15 {
    margin-right: 9rem !important;
    margin-left: 9rem !important;
  }
  .landing-page .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .landing-page .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .landing-page .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .landing-page .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .landing-page .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .landing-page .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .landing-page .my-xxl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .landing-page .my-xxl-10 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }
  .landing-page .my-xxl-15 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }
  .landing-page .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .landing-page .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .landing-page .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .landing-page .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .landing-page .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .landing-page .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .landing-page .mt-xxl-5 {
    margin-top: 2.5rem !important;
  }
  .landing-page .mt-xxl-10 {
    margin-top: 6rem !important;
  }
  .landing-page .mt-xxl-15 {
    margin-top: 9rem !important;
  }
  .landing-page .mt-xxl-auto {
    margin-top: auto !important;
  }
  .landing-page .me-xxl-0 {
    margin-right: 0 !important;
  }
  .landing-page .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .landing-page .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .landing-page .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .landing-page .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .landing-page .me-xxl-5 {
    margin-right: 2.5rem !important;
  }
  .landing-page .me-xxl-10 {
    margin-right: 6rem !important;
  }
  .landing-page .me-xxl-15 {
    margin-right: 9rem !important;
  }
  .landing-page .me-xxl-auto {
    margin-right: auto !important;
  }
  .landing-page .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .landing-page .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .landing-page .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .landing-page .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .landing-page .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .landing-page .mb-xxl-5 {
    margin-bottom: 2.5rem !important;
  }
  .landing-page .mb-xxl-10 {
    margin-bottom: 6rem !important;
  }
  .landing-page .mb-xxl-15 {
    margin-bottom: 9rem !important;
  }
  .landing-page .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .landing-page .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .landing-page .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .landing-page .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .landing-page .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .landing-page .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .landing-page .ms-xxl-5 {
    margin-left: 2.5rem !important;
  }
  .landing-page .ms-xxl-10 {
    margin-left: 6rem !important;
  }
  .landing-page .ms-xxl-15 {
    margin-left: 9rem !important;
  }
  .landing-page .ms-xxl-auto {
    margin-left: auto !important;
  }
  .landing-page .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .landing-page .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .landing-page .m-xxl-n3 {
    margin: -1rem !important;
  }
  .landing-page .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .landing-page .m-xxl-n5 {
    margin: -2.5rem !important;
  }
  .landing-page .m-xxl-n10 {
    margin: -6rem !important;
  }
  .landing-page .m-xxl-n15 {
    margin: -9rem !important;
  }
  .landing-page .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .landing-page .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .landing-page .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .landing-page .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .landing-page .mx-xxl-n5 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .landing-page .mx-xxl-n10 {
    margin-right: -6rem !important;
    margin-left: -6rem !important;
  }
  .landing-page .mx-xxl-n15 {
    margin-right: -9rem !important;
    margin-left: -9rem !important;
  }
  .landing-page .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .landing-page .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .landing-page .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .landing-page .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .landing-page .my-xxl-n5 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .landing-page .my-xxl-n10 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }
  .landing-page .my-xxl-n15 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }
  .landing-page .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .landing-page .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .landing-page .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .landing-page .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .landing-page .mt-xxl-n5 {
    margin-top: -2.5rem !important;
  }
  .landing-page .mt-xxl-n10 {
    margin-top: -6rem !important;
  }
  .landing-page .mt-xxl-n15 {
    margin-top: -9rem !important;
  }
  .landing-page .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .landing-page .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .landing-page .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .landing-page .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .landing-page .me-xxl-n5 {
    margin-right: -2.5rem !important;
  }
  .landing-page .me-xxl-n10 {
    margin-right: -6rem !important;
  }
  .landing-page .me-xxl-n15 {
    margin-right: -9rem !important;
  }
  .landing-page .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .landing-page .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .landing-page .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .landing-page .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .landing-page .mb-xxl-n5 {
    margin-bottom: -2.5rem !important;
  }
  .landing-page .mb-xxl-n10 {
    margin-bottom: -6rem !important;
  }
  .landing-page .mb-xxl-n15 {
    margin-bottom: -9rem !important;
  }
  .landing-page .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .landing-page .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .landing-page .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .landing-page .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .landing-page .ms-xxl-n5 {
    margin-left: -2.5rem !important;
  }
  .landing-page .ms-xxl-n10 {
    margin-left: -6rem !important;
  }
  .landing-page .ms-xxl-n15 {
    margin-left: -9rem !important;
  }
  .landing-page .p-xxl-0 {
    padding: 0 !important;
  }
  .landing-page .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .landing-page .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .landing-page .p-xxl-3 {
    padding: 1rem !important;
  }
  .landing-page .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .landing-page .p-xxl-5 {
    padding: 2.5rem !important;
  }
  .landing-page .p-xxl-10 {
    padding: 6rem !important;
  }
  .landing-page .p-xxl-15 {
    padding: 9rem !important;
  }
  .landing-page .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .landing-page .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .landing-page .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .landing-page .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .landing-page .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .landing-page .px-xxl-5 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .landing-page .px-xxl-10 {
    padding-right: 6rem !important;
    padding-left: 6rem !important;
  }
  .landing-page .px-xxl-15 {
    padding-right: 9rem !important;
    padding-left: 9rem !important;
  }
  .landing-page .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .landing-page .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .landing-page .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .landing-page .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .landing-page .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .landing-page .py-xxl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .landing-page .py-xxl-10 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .landing-page .py-xxl-15 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }
  .landing-page .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .landing-page .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .landing-page .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .landing-page .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .landing-page .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .landing-page .pt-xxl-5 {
    padding-top: 2.5rem !important;
  }
  .landing-page .pt-xxl-10 {
    padding-top: 6rem !important;
  }
  .landing-page .pt-xxl-15 {
    padding-top: 9rem !important;
  }
  .landing-page .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .landing-page .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .landing-page .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .landing-page .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .landing-page .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .landing-page .pe-xxl-5 {
    padding-right: 2.5rem !important;
  }
  .landing-page .pe-xxl-10 {
    padding-right: 6rem !important;
  }
  .landing-page .pe-xxl-15 {
    padding-right: 9rem !important;
  }
  .landing-page .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .landing-page .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .landing-page .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .landing-page .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .landing-page .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .landing-page .pb-xxl-5 {
    padding-bottom: 2.5rem !important;
  }
  .landing-page .pb-xxl-10 {
    padding-bottom: 6rem !important;
  }
  .landing-page .pb-xxl-15 {
    padding-bottom: 9rem !important;
  }
  .landing-page .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .landing-page .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .landing-page .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .landing-page .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .landing-page .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .landing-page .ps-xxl-5 {
    padding-left: 2.5rem !important;
  }
  .landing-page .ps-xxl-10 {
    padding-left: 6rem !important;
  }
  .landing-page .ps-xxl-15 {
    padding-left: 9rem !important;
  }
  .landing-page .text-xxl-start {
    text-align: left !important;
  }
  .landing-page .text-xxl-end {
    text-align: right !important;
  }
  .landing-page .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .landing-page .fs-1 {
    font-size: 1.5rem !important;
  }
  .landing-page .fs-2 {
    font-size: 1.4rem !important;
  }
  .landing-page .fs-3 {
    font-size: 1.3rem !important;
  }
}
@media print {
  .landing-page .d-print-inline {
    display: inline !important;
  }
  .landing-page .d-print-inline-block {
    display: inline-block !important;
  }
  .landing-page .d-print-block {
    display: block !important;
  }
  .landing-page .d-print-grid {
    display: grid !important;
  }
  .landing-page .d-print-table {
    display: table !important;
  }
  .landing-page .d-print-table-row {
    display: table-row !important;
  }
  .landing-page .d-print-table-cell {
    display: table-cell !important;
  }
  .landing-page .d-print-flex {
    display: flex !important;
  }
  .landing-page .d-print-inline-flex {
    display: inline-flex !important;
  }
  .landing-page .d-print-none {
    display: none !important;
  }
}
.landing-page html,
.landing-page body {
  height: 100%;
}
.landing-page body {
  overflow-x: hidden;
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Thin.otf");
    font-weight: 100;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-ThinItalic.otf");
    font-weight: 100;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-ExtraLight.otf");
    font-weight: 200;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-ExtraLightItalic.otf");
    font-weight: 200;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Light.otf");
    font-weight: 300;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-LightItalic.otf");
    font-weight: 300;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Regular.otf");
    font-weight: 400;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-RegularItalic.otf");
    font-weight: 400;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Medium.otf");
    font-weight: 500;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-MediumItalic.otf");
    font-weight: 500;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-SemiBold.otf");
    font-weight: 600;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-SemiBoldItalic.otf");
    font-weight: 600;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Bold.otf");
    font-weight: 700;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-BoldItalic.otf");
    font-weight: 700;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-ExtraBold.otf");
    font-weight: 800;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-ExtraBoldItalic.otf");
    font-weight: 800;
    font-style: italic;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-Black.otf");
    font-weight: 800;
    font-style: normal;
  }
}
@font-face {
  .landing-page {
    font-family: "Metropolis";
    src: url("../fonts/metropolis/Metropolis-BlackItalic.otf");
    font-weight: 800;
    font-style: italic;
  }
}
.landing-page #layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.landing-page #layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}
.landing-page #layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}
.landing-page #layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.landing-page #layoutError #layoutError_content {
  min-width: 0;
  flex-grow: 1;
}
.landing-page #layoutError #layoutError_footer {
  min-width: 0;
}
.landing-page .img-error {
  max-width: 20rem;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 0.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    margin-top: 0.75rem;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.landing-page .animated--fade-in-up {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-timing-function: margin cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: margin cubic-bezier(0.18, 1.25, 0.4, 1), opacity cubic-bezier(0, 1, 0.4, 1);
}
.landing-page .animated--fade-in-up.dropdown-menu {
  margin-top: 0;
  top: 0.125rem !important;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.landing-page .animated--fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: opacity cubic-bezier(0, 1, 0.4, 1);
}
.landing-page .bg-gradient-primary-to-secondary {
  background-color: #0061f2 !important;
  background-image: linear-gradient(135deg, #0061f2 0%, rgba(91, 194, 255, 0.8) 100%) !important;
}
.landing-page .bg-transparent-light {
  color: rgba(255, 255, 255, 0.5) !important;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.landing-page .bg-transparent-dark {
  color: rgba(33, 40, 50, 0.5) !important;
  background-color: rgba(33, 40, 50, 0.8) !important;
}
.landing-page .bg-gray-100 {
  background-color: #f2f6fc !important;
}
.landing-page .bg-gray-200 {
  background-color: #e0e5ec !important;
}
.landing-page .bg-gray-300 {
  background-color: #d4dae3 !important;
}
.landing-page .bg-gray-400 {
  background-color: #c5ccd6 !important;
}
.landing-page .bg-gray-500 {
  background-color: #a7aeb8 !important;
}
.landing-page .bg-gray-600 {
  background-color: #69707a !important;
}
.landing-page .bg-gray-700 {
  background-color: #4a515b !important;
}
.landing-page .bg-gray-800 {
  background-color: #363d47 !important;
}
.landing-page .bg-gray-900 {
  background-color: #212832 !important;
}
.landing-page .bg-white-25 {
  background-color: rgba(255, 255, 255, 0.25) !important;
}
.landing-page .bg-img-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.landing-page .bg-img-repeat {
  background-position: top left;
  background-repeat: repeat;
}
.landing-page .overlay {
  position: relative;
}
.landing-page .overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}
.landing-page .overlay-primary:before {
  background-color: #0061f2 !important;
}
.landing-page .overlay-secondary:before {
  background-color: #5bc2ff !important;
}
.landing-page .overlay-success:before {
  background-color: #00ac69 !important;
}
.landing-page .overlay-info:before {
  background-color: #00cfd5 !important;
}
.landing-page .overlay-warning:before {
  background-color: #f4a100 !important;
}
.landing-page .overlay-danger:before {
  background-color: #e81500 !important;
}
.landing-page .overlay-light:before {
  background-color: #f2f6fc !important;
}
.landing-page .overlay-dark:before {
  background-color: #212832 !important;
}
.landing-page .overlay-black:before {
  background-color: #000 !important;
}
.landing-page .overlay-white:before {
  background-color: #fff !important;
}
.landing-page .overlay-red:before {
  background-color: #e81500 !important;
}
.landing-page .overlay-orange:before {
  background-color: #f76400 !important;
}
.landing-page .overlay-yellow:before {
  background-color: #f4a100 !important;
}
.landing-page .overlay-green:before {
  background-color: #00ac69 !important;
}
.landing-page .overlay-teal:before {
  background-color: #00ba94 !important;
}
.landing-page .overlay-cyan:before {
  background-color: #00cfd5 !important;
}
.landing-page .overlay-blue:before {
  background-color: #0061f2 !important;
}
.landing-page .overlay-indigo:before {
  background-color: #5800e8 !important;
}
.landing-page .overlay-purple:before {
  background-color: #6900c7 !important;
}
.landing-page .overlay-pink:before {
  background-color: #e30059 !important;
}
.landing-page .overlay-red-soft:before {
  background-color: #f1e0e3 !important;
}
.landing-page .overlay-orange-soft:before {
  background-color: #f3e7e3 !important;
}
.landing-page .overlay-yellow-soft:before {
  background-color: #f2eee3 !important;
}
.landing-page .overlay-green-soft:before {
  background-color: #daefed !important;
}
.landing-page .overlay-teal-soft:before {
  background-color: #daf0f2 !important;
}
.landing-page .overlay-cyan-soft:before {
  background-color: #daf2f8 !important;
}
.landing-page .overlay-blue-soft:before {
  background-color: #dae7fb !important;
}
.landing-page .overlay-indigo-soft:before {
  background-color: #e3ddfa !important;
}
.landing-page .overlay-purple-soft:before {
  background-color: #e4ddf7 !important;
}
.landing-page .overlay-pink-soft:before {
  background-color: #f1ddec !important;
}
.landing-page .overlay-primary-soft:before {
  background-color: #dae7fb !important;
}
.landing-page .overlay-secondary-soft:before {
  background-color: #e3f1fc !important;
}
.landing-page .overlay-success-soft:before {
  background-color: #daefed !important;
}
.landing-page .overlay-info-soft:before {
  background-color: #daf2f8 !important;
}
.landing-page .overlay-warning-soft:before {
  background-color: #f2eee3 !important;
}
.landing-page .overlay-danger-soft:before {
  background-color: #f1e0e3 !important;
}
.landing-page .overlay-10:before {
  opacity: 0.1;
}
.landing-page .overlay-20:before {
  opacity: 0.2;
}
.landing-page .overlay-30:before {
  opacity: 0.3;
}
.landing-page .overlay-40:before {
  opacity: 0.4;
}
.landing-page .overlay-50:before {
  opacity: 0.5;
}
.landing-page .overlay-60:before {
  opacity: 0.6;
}
.landing-page .overlay-70:before {
  opacity: 0.7;
}
.landing-page .overlay-80:before {
  opacity: 0.8;
}
.landing-page .overlay-90:before {
  opacity: 0.9;
}
.landing-page .border-lg {
  border-width: 0.25rem !important;
}
.landing-page .border-top-lg {
  border-top-width: 0.25rem !important;
}
.landing-page .border-end-lg {
  border-right-width: 0.25rem !important;
}
.landing-page .border-bottom-lg {
  border-bottom-width: 0.25rem !important;
}
.landing-page .border-start-lg {
  border-left-width: 0.25rem !important;
}
.landing-page .border-primary {
  border-color: #0061f2 !important;
}
.landing-page .border-secondary {
  border-color: #5bc2ff !important;
}
.landing-page .border-success {
  border-color: #00ac69 !important;
}
.landing-page .border-info {
  border-color: #00cfd5 !important;
}
.landing-page .border-warning {
  border-color: #f4a100 !important;
}
.landing-page .border-danger {
  border-color: #e81500 !important;
}
.landing-page .border-light {
  border-color: #f2f6fc !important;
}
.landing-page .border-dark {
  border-color: #212832 !important;
}
.landing-page .border-black {
  border-color: #000 !important;
}
.landing-page .border-white {
  border-color: #fff !important;
}
.landing-page .border-red {
  border-color: #e81500 !important;
}
.landing-page .border-orange {
  border-color: #f76400 !important;
}
.landing-page .border-yellow {
  border-color: #f4a100 !important;
}
.landing-page .border-green {
  border-color: #00ac69 !important;
}
.landing-page .border-teal {
  border-color: #00ba94 !important;
}
.landing-page .border-cyan {
  border-color: #00cfd5 !important;
}
.landing-page .border-blue {
  border-color: #0061f2 !important;
}
.landing-page .border-indigo {
  border-color: #5800e8 !important;
}
.landing-page .border-purple {
  border-color: #6900c7 !important;
}
.landing-page .border-pink {
  border-color: #e30059 !important;
}
.landing-page .border-red-soft {
  border-color: #f1e0e3 !important;
}
.landing-page .border-orange-soft {
  border-color: #f3e7e3 !important;
}
.landing-page .border-yellow-soft {
  border-color: #f2eee3 !important;
}
.landing-page .border-green-soft {
  border-color: #daefed !important;
}
.landing-page .border-teal-soft {
  border-color: #daf0f2 !important;
}
.landing-page .border-cyan-soft {
  border-color: #daf2f8 !important;
}
.landing-page .border-blue-soft {
  border-color: #dae7fb !important;
}
.landing-page .border-indigo-soft {
  border-color: #e3ddfa !important;
}
.landing-page .border-purple-soft {
  border-color: #e4ddf7 !important;
}
.landing-page .border-pink-soft {
  border-color: #f1ddec !important;
}
.landing-page .border-primary-soft {
  border-color: #dae7fb !important;
}
.landing-page .border-secondary-soft {
  border-color: #e3f1fc !important;
}
.landing-page .border-success-soft {
  border-color: #daefed !important;
}
.landing-page .border-info-soft {
  border-color: #daf2f8 !important;
}
.landing-page .border-warning-soft {
  border-color: #f2eee3 !important;
}
.landing-page .border-danger-soft {
  border-color: #f1e0e3 !important;
}
.landing-page .border-start-primary {
  border-left-color: #0061f2 !important;
}
.landing-page .border-start-secondary {
  border-left-color: #5bc2ff !important;
}
.landing-page .border-start-success {
  border-left-color: #00ac69 !important;
}
.landing-page .border-start-info {
  border-left-color: #00cfd5 !important;
}
.landing-page .border-start-warning {
  border-left-color: #f4a100 !important;
}
.landing-page .border-start-danger {
  border-left-color: #e81500 !important;
}
.landing-page .border-start-light {
  border-left-color: #f2f6fc !important;
}
.landing-page .border-start-dark {
  border-left-color: #212832 !important;
}
.landing-page .border-start-black {
  border-left-color: #000 !important;
}
.landing-page .border-start-white {
  border-left-color: #fff !important;
}
.landing-page .border-start-red {
  border-left-color: #e81500 !important;
}
.landing-page .border-start-orange {
  border-left-color: #f76400 !important;
}
.landing-page .border-start-yellow {
  border-left-color: #f4a100 !important;
}
.landing-page .border-start-green {
  border-left-color: #00ac69 !important;
}
.landing-page .border-start-teal {
  border-left-color: #00ba94 !important;
}
.landing-page .border-start-cyan {
  border-left-color: #00cfd5 !important;
}
.landing-page .border-start-blue {
  border-left-color: #0061f2 !important;
}
.landing-page .border-start-indigo {
  border-left-color: #5800e8 !important;
}
.landing-page .border-start-purple {
  border-left-color: #6900c7 !important;
}
.landing-page .border-start-pink {
  border-left-color: #e30059 !important;
}
.landing-page .border-start-red-soft {
  border-left-color: #f1e0e3 !important;
}
.landing-page .border-start-orange-soft {
  border-left-color: #f3e7e3 !important;
}
.landing-page .border-start-yellow-soft {
  border-left-color: #f2eee3 !important;
}
.landing-page .border-start-green-soft {
  border-left-color: #daefed !important;
}
.landing-page .border-start-teal-soft {
  border-left-color: #daf0f2 !important;
}
.landing-page .border-start-cyan-soft {
  border-left-color: #daf2f8 !important;
}
.landing-page .border-start-blue-soft {
  border-left-color: #dae7fb !important;
}
.landing-page .border-start-indigo-soft {
  border-left-color: #e3ddfa !important;
}
.landing-page .border-start-purple-soft {
  border-left-color: #e4ddf7 !important;
}
.landing-page .border-start-pink-soft {
  border-left-color: #f1ddec !important;
}
.landing-page .border-start-primary-soft {
  border-left-color: #dae7fb !important;
}
.landing-page .border-start-secondary-soft {
  border-left-color: #e3f1fc !important;
}
.landing-page .border-start-success-soft {
  border-left-color: #daefed !important;
}
.landing-page .border-start-info-soft {
  border-left-color: #daf2f8 !important;
}
.landing-page .border-start-warning-soft {
  border-left-color: #f2eee3 !important;
}
.landing-page .border-start-danger-soft {
  border-left-color: #f1e0e3 !important;
}
.landing-page .border-end-primary {
  border-right-color: #0061f2 !important;
}
.landing-page .border-end-secondary {
  border-right-color: #5bc2ff !important;
}
.landing-page .border-end-success {
  border-right-color: #00ac69 !important;
}
.landing-page .border-end-info {
  border-right-color: #00cfd5 !important;
}
.landing-page .border-end-warning {
  border-right-color: #f4a100 !important;
}
.landing-page .border-end-danger {
  border-right-color: #e81500 !important;
}
.landing-page .border-end-light {
  border-right-color: #f2f6fc !important;
}
.landing-page .border-end-dark {
  border-right-color: #212832 !important;
}
.landing-page .border-end-black {
  border-right-color: #000 !important;
}
.landing-page .border-end-white {
  border-right-color: #fff !important;
}
.landing-page .border-end-red {
  border-right-color: #e81500 !important;
}
.landing-page .border-end-orange {
  border-right-color: #f76400 !important;
}
.landing-page .border-end-yellow {
  border-right-color: #f4a100 !important;
}
.landing-page .border-end-green {
  border-right-color: #00ac69 !important;
}
.landing-page .border-end-teal {
  border-right-color: #00ba94 !important;
}
.landing-page .border-end-cyan {
  border-right-color: #00cfd5 !important;
}
.landing-page .border-end-blue {
  border-right-color: #0061f2 !important;
}
.landing-page .border-end-indigo {
  border-right-color: #5800e8 !important;
}
.landing-page .border-end-purple {
  border-right-color: #6900c7 !important;
}
.landing-page .border-end-pink {
  border-right-color: #e30059 !important;
}
.landing-page .border-end-red-soft {
  border-right-color: #f1e0e3 !important;
}
.landing-page .border-end-orange-soft {
  border-right-color: #f3e7e3 !important;
}
.landing-page .border-end-yellow-soft {
  border-right-color: #f2eee3 !important;
}
.landing-page .border-end-green-soft {
  border-right-color: #daefed !important;
}
.landing-page .border-end-teal-soft {
  border-right-color: #daf0f2 !important;
}
.landing-page .border-end-cyan-soft {
  border-right-color: #daf2f8 !important;
}
.landing-page .border-end-blue-soft {
  border-right-color: #dae7fb !important;
}
.landing-page .border-end-indigo-soft {
  border-right-color: #e3ddfa !important;
}
.landing-page .border-end-purple-soft {
  border-right-color: #e4ddf7 !important;
}
.landing-page .border-end-pink-soft {
  border-right-color: #f1ddec !important;
}
.landing-page .border-end-primary-soft {
  border-right-color: #dae7fb !important;
}
.landing-page .border-end-secondary-soft {
  border-right-color: #e3f1fc !important;
}
.landing-page .border-end-success-soft {
  border-right-color: #daefed !important;
}
.landing-page .border-end-info-soft {
  border-right-color: #daf2f8 !important;
}
.landing-page .border-end-warning-soft {
  border-right-color: #f2eee3 !important;
}
.landing-page .border-end-danger-soft {
  border-right-color: #f1e0e3 !important;
}
.landing-page .border-top-primary {
  border-top-color: #0061f2 !important;
}
.landing-page .border-top-secondary {
  border-top-color: #5bc2ff !important;
}
.landing-page .border-top-success {
  border-top-color: #00ac69 !important;
}
.landing-page .border-top-info {
  border-top-color: #00cfd5 !important;
}
.landing-page .border-top-warning {
  border-top-color: #f4a100 !important;
}
.landing-page .border-top-danger {
  border-top-color: #e81500 !important;
}
.landing-page .border-top-light {
  border-top-color: #f2f6fc !important;
}
.landing-page .border-top-dark {
  border-top-color: #212832 !important;
}
.landing-page .border-top-black {
  border-top-color: #000 !important;
}
.landing-page .border-top-white {
  border-top-color: #fff !important;
}
.landing-page .border-top-red {
  border-top-color: #e81500 !important;
}
.landing-page .border-top-orange {
  border-top-color: #f76400 !important;
}
.landing-page .border-top-yellow {
  border-top-color: #f4a100 !important;
}
.landing-page .border-top-green {
  border-top-color: #00ac69 !important;
}
.landing-page .border-top-teal {
  border-top-color: #00ba94 !important;
}
.landing-page .border-top-cyan {
  border-top-color: #00cfd5 !important;
}
.landing-page .border-top-blue {
  border-top-color: #0061f2 !important;
}
.landing-page .border-top-indigo {
  border-top-color: #5800e8 !important;
}
.landing-page .border-top-purple {
  border-top-color: #6900c7 !important;
}
.landing-page .border-top-pink {
  border-top-color: #e30059 !important;
}
.landing-page .border-top-red-soft {
  border-top-color: #f1e0e3 !important;
}
.landing-page .border-top-orange-soft {
  border-top-color: #f3e7e3 !important;
}
.landing-page .border-top-yellow-soft {
  border-top-color: #f2eee3 !important;
}
.landing-page .border-top-green-soft {
  border-top-color: #daefed !important;
}
.landing-page .border-top-teal-soft {
  border-top-color: #daf0f2 !important;
}
.landing-page .border-top-cyan-soft {
  border-top-color: #daf2f8 !important;
}
.landing-page .border-top-blue-soft {
  border-top-color: #dae7fb !important;
}
.landing-page .border-top-indigo-soft {
  border-top-color: #e3ddfa !important;
}
.landing-page .border-top-purple-soft {
  border-top-color: #e4ddf7 !important;
}
.landing-page .border-top-pink-soft {
  border-top-color: #f1ddec !important;
}
.landing-page .border-top-primary-soft {
  border-top-color: #dae7fb !important;
}
.landing-page .border-top-secondary-soft {
  border-top-color: #e3f1fc !important;
}
.landing-page .border-top-success-soft {
  border-top-color: #daefed !important;
}
.landing-page .border-top-info-soft {
  border-top-color: #daf2f8 !important;
}
.landing-page .border-top-warning-soft {
  border-top-color: #f2eee3 !important;
}
.landing-page .border-top-danger-soft {
  border-top-color: #f1e0e3 !important;
}
.landing-page .border-bottom-primary {
  border-bottom-color: #0061f2 !important;
}
.landing-page .border-bottom-secondary {
  border-bottom-color: #5bc2ff !important;
}
.landing-page .border-bottom-success {
  border-bottom-color: #00ac69 !important;
}
.landing-page .border-bottom-info {
  border-bottom-color: #00cfd5 !important;
}
.landing-page .border-bottom-warning {
  border-bottom-color: #f4a100 !important;
}
.landing-page .border-bottom-danger {
  border-bottom-color: #e81500 !important;
}
.landing-page .border-bottom-light {
  border-bottom-color: #f2f6fc !important;
}
.landing-page .border-bottom-dark {
  border-bottom-color: #212832 !important;
}
.landing-page .border-bottom-black {
  border-bottom-color: #000 !important;
}
.landing-page .border-bottom-white {
  border-bottom-color: #fff !important;
}
.landing-page .border-bottom-red {
  border-bottom-color: #e81500 !important;
}
.landing-page .border-bottom-orange {
  border-bottom-color: #f76400 !important;
}
.landing-page .border-bottom-yellow {
  border-bottom-color: #f4a100 !important;
}
.landing-page .border-bottom-green {
  border-bottom-color: #00ac69 !important;
}
.landing-page .border-bottom-teal {
  border-bottom-color: #00ba94 !important;
}
.landing-page .border-bottom-cyan {
  border-bottom-color: #00cfd5 !important;
}
.landing-page .border-bottom-blue {
  border-bottom-color: #0061f2 !important;
}
.landing-page .border-bottom-indigo {
  border-bottom-color: #5800e8 !important;
}
.landing-page .border-bottom-purple {
  border-bottom-color: #6900c7 !important;
}
.landing-page .border-bottom-pink {
  border-bottom-color: #e30059 !important;
}
.landing-page .border-bottom-red-soft {
  border-bottom-color: #f1e0e3 !important;
}
.landing-page .border-bottom-orange-soft {
  border-bottom-color: #f3e7e3 !important;
}
.landing-page .border-bottom-yellow-soft {
  border-bottom-color: #f2eee3 !important;
}
.landing-page .border-bottom-green-soft {
  border-bottom-color: #daefed !important;
}
.landing-page .border-bottom-teal-soft {
  border-bottom-color: #daf0f2 !important;
}
.landing-page .border-bottom-cyan-soft {
  border-bottom-color: #daf2f8 !important;
}
.landing-page .border-bottom-blue-soft {
  border-bottom-color: #dae7fb !important;
}
.landing-page .border-bottom-indigo-soft {
  border-bottom-color: #e3ddfa !important;
}
.landing-page .border-bottom-purple-soft {
  border-bottom-color: #e4ddf7 !important;
}
.landing-page .border-bottom-pink-soft {
  border-bottom-color: #f1ddec !important;
}
.landing-page .border-bottom-primary-soft {
  border-bottom-color: #dae7fb !important;
}
.landing-page .border-bottom-secondary-soft {
  border-bottom-color: #e3f1fc !important;
}
.landing-page .border-bottom-success-soft {
  border-bottom-color: #daefed !important;
}
.landing-page .border-bottom-info-soft {
  border-bottom-color: #daf2f8 !important;
}
.landing-page .border-bottom-warning-soft {
  border-bottom-color: #f2eee3 !important;
}
.landing-page .border-bottom-danger-soft {
  border-bottom-color: #f1e0e3 !important;
}
.landing-page .rounded-xl {
  border-radius: 1rem !important;
}
.landing-page .border-gray-100 {
  border-color: #f2f6fc !important;
}
.landing-page .border-gray-200 {
  border-color: #e0e5ec !important;
}
.landing-page .border-gray-300 {
  border-color: #d4dae3 !important;
}
.landing-page .border-gray-400 {
  border-color: #c5ccd6 !important;
}
.landing-page .border-gray-500 {
  border-color: #a7aeb8 !important;
}
.landing-page .border-gray-600 {
  border-color: #69707a !important;
}
.landing-page .border-gray-700 {
  border-color: #4a515b !important;
}
.landing-page .border-gray-800 {
  border-color: #363d47 !important;
}
.landing-page .border-gray-900 {
  border-color: #212832 !important;
}
.landing-page .no-caret .dropdown-toggle::after {
  display: none;
}
.landing-page .pointer {
  cursor: pointer !important;
}
.landing-page .no-highlight {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.landing-page .lift {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.landing-page .lift:hover {
  transform: translateY(-0.3333333333rem);
  box-shadow: 0 0.5rem 2rem 0 rgba(33, 40, 50, 0.25);
}
.landing-page .lift:active {
  transform: none;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.landing-page .lift-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}
.landing-page .lift-sm:hover {
  transform: translateY(-0.1666666667rem);
  box-shadow: 0 0.25rem 1rem 0 rgba(33, 40, 50, 0.25);
}
.landing-page .lift-sm:active {
  transform: none;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}
.landing-page .card.lift {
  text-decoration: none;
  color: inherit;
}
.landing-page .o-visible {
  overflow: visible !important;
}
.landing-page .o-hidden {
  overflow: hidden !important;
}
.landing-page .shadow-right-sm {
  box-shadow: 0.125rem 0 0.25rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-right {
  box-shadow: 0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-right-lg {
  box-shadow: 1rem 0 3rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-left-sm {
  box-shadow: -0.125rem 0 0.25rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-left {
  box-shadow: -0.15rem 0 1.75rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .shadow-left-lg {
  box-shadow: -1rem 0 3rem 0 rgba(33, 40, 50, 0.15) !important;
}
.landing-page .content-skewed {
  perspective: 1500px !important;
  transform-style: preserve-3d !important;
}
.landing-page .content-skewed-right {
  perspective-origin: right center !important;
}
.landing-page .content-skewed-right .content-skewed-item {
  transform: rotateY(30deg) rotateX(15deg) !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}
.landing-page .content-skewed-left {
  perspective-origin: left center !important;
}
.landing-page .content-skewed-left .content-skewed-item {
  transform: rotateY(-30deg) rotateX(15deg) !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}
.landing-page .text-gray-100 {
  color: #f2f6fc !important;
}
.landing-page .text-gray-200 {
  color: #e0e5ec !important;
}
.landing-page .text-gray-300 {
  color: #d4dae3 !important;
}
.landing-page .text-gray-400 {
  color: #c5ccd6 !important;
}
.landing-page .text-gray-500 {
  color: #a7aeb8 !important;
}
.landing-page .text-gray-600 {
  color: #69707a !important;
}
.landing-page .text-gray-700 {
  color: #4a515b !important;
}
.landing-page .text-gray-800 {
  color: #363d47 !important;
}
.landing-page .text-gray-900 {
  color: #212832 !important;
}
.landing-page .fw-100 {
  font-weight: 100 !important;
}
.landing-page .fw-200 {
  font-weight: 200 !important;
}
.landing-page .fw-300 {
  font-weight: 300 !important;
}
.landing-page .fw-400 {
  font-weight: 400 !important;
}
.landing-page .fw-500 {
  font-weight: 500 !important;
}
.landing-page .fw-600 {
  font-weight: 600 !important;
}
.landing-page .fw-700 {
  font-weight: 700 !important;
}
.landing-page .fw-800 {
  font-weight: 800 !important;
}
.landing-page .fw-900 {
  font-weight: 900 !important;
}
.landing-page .text-white-75,
.landing-page .page-header-ui-dark .page-header-ui-text a {
  color: rgba(255, 255, 255, 0.75) !important;
}
.landing-page .text-white-25 {
  color: rgba(255, 255, 255, 0.25) !important;
}
.landing-page .text-black-75 {
  color: rgba(0, 0, 0, 0.75) !important;
}
.landing-page .text-black-25 {
  color: rgba(0, 0, 0, 0.25) !important;
}
.landing-page .text-xs {
  font-size: 0.75rem !important;
}
.landing-page .text-sm {
  font-size: 0.875rem !important;
}
.landing-page .text-lg {
  font-size: 1.25rem !important;
}
.landing-page .text-xl {
  font-size: 2.5rem !important;
}
.landing-page .text-uppercase-expanded,
.landing-page .navbar .dropdown-menu .dropdown-header {
  font-weight: 700;
  letter-spacing: 0.125em;
  text-transform: uppercase;
}
.landing-page .text-arrow-icon {
  line-height: 1;
  display: inline-flex;
  align-items: center;
}
.landing-page .text-arrow-icon svg {
  margin-left: 0.25rem;
}
.landing-page .text-arrow-icon.small svg {
  height: 0.875rem;
  width: 0.875rem;
}
.landing-page .line-height-normal {
  line-height: normal !important;
}
.landing-page .feather-sm {
  height: 0.875rem !important;
  width: 0.875rem !important;
}
.landing-page .feather-lg {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.landing-page .feather-xl {
  height: 2.5rem !important;
  width: 2.5rem !important;
}
.landing-page .z-1 {
  z-index: 1 !important;
  position: relative !important;
}
.landing-page .z-2 {
  z-index: 2 !important;
  position: relative !important;
}
.landing-page .alert-icon {
  position: relative;
  display: flex;
  padding: 0;
}
.landing-page .alert-icon button.btn-close {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}
.landing-page .alert-icon .alert-icon-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  font-size: 1.5rem;
}
.landing-page .alert-icon .alert-icon-aside svg.feather,
.landing-page .alert-icon .alert-icon-aside svg,
.landing-page .alert-icon .alert-icon-aside i {
  height: 1.5rem;
  width: 1.5rem;
}
.landing-page .alert-icon .alert-icon-content {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.landing-page .alert-primary.alert-solid {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .alert-primary.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-secondary.alert-solid {
  color: #fff;
  background-color: #5bc2ff;
  border-color: #5bc2ff;
}
.landing-page .alert-secondary.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-success.alert-solid {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .alert-success.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-info.alert-solid {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .alert-info.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-warning.alert-solid {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .alert-warning.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-danger.alert-solid {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .alert-danger.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-light.alert-solid {
  color: #000;
  background-color: #f2f6fc;
  border-color: #f2f6fc;
}
.landing-page .alert-light.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-dark.alert-solid {
  color: #fff;
  background-color: #212832;
  border-color: #212832;
}
.landing-page .alert-dark.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-black.alert-solid {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.landing-page .alert-black.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-white.alert-solid {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.landing-page .alert-white.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-red.alert-solid {
  color: #fff;
  background-color: #e81500;
  border-color: #e81500;
}
.landing-page .alert-red.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-orange.alert-solid {
  color: #fff;
  background-color: #f76400;
  border-color: #f76400;
}
.landing-page .alert-orange.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-yellow.alert-solid {
  color: #fff;
  background-color: #f4a100;
  border-color: #f4a100;
}
.landing-page .alert-yellow.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-green.alert-solid {
  color: #fff;
  background-color: #00ac69;
  border-color: #00ac69;
}
.landing-page .alert-green.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-teal.alert-solid {
  color: #fff;
  background-color: #00ba94;
  border-color: #00ba94;
}
.landing-page .alert-teal.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-cyan.alert-solid {
  color: #fff;
  background-color: #00cfd5;
  border-color: #00cfd5;
}
.landing-page .alert-cyan.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-blue.alert-solid {
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}
.landing-page .alert-blue.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-indigo.alert-solid {
  color: #fff;
  background-color: #5800e8;
  border-color: #5800e8;
}
.landing-page .alert-indigo.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-purple.alert-solid {
  color: #fff;
  background-color: #6900c7;
  border-color: #6900c7;
}
.landing-page .alert-purple.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-pink.alert-solid {
  color: #fff;
  background-color: #e30059;
  border-color: #e30059;
}
.landing-page .alert-pink.alert-solid .alert-link {
  color: #cccccc;
}
.landing-page .alert-red-soft.alert-solid {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .alert-red-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-orange-soft.alert-solid {
  color: #000;
  background-color: #f3e7e3;
  border-color: #f3e7e3;
}
.landing-page .alert-orange-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-yellow-soft.alert-solid {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .alert-yellow-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-green-soft.alert-solid {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .alert-green-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-teal-soft.alert-solid {
  color: #000;
  background-color: #daf0f2;
  border-color: #daf0f2;
}
.landing-page .alert-teal-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-cyan-soft.alert-solid {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .alert-cyan-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-blue-soft.alert-solid {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .alert-blue-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-indigo-soft.alert-solid {
  color: #000;
  background-color: #e3ddfa;
  border-color: #e3ddfa;
}
.landing-page .alert-indigo-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-purple-soft.alert-solid {
  color: #000;
  background-color: #e4ddf7;
  border-color: #e4ddf7;
}
.landing-page .alert-purple-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-pink-soft.alert-solid {
  color: #000;
  background-color: #f1ddec;
  border-color: #f1ddec;
}
.landing-page .alert-pink-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-primary-soft.alert-solid {
  color: #000;
  background-color: #dae7fb;
  border-color: #dae7fb;
}
.landing-page .alert-primary-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-secondary-soft.alert-solid {
  color: #000;
  background-color: #e3f1fc;
  border-color: #e3f1fc;
}
.landing-page .alert-secondary-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-success-soft.alert-solid {
  color: #000;
  background-color: #daefed;
  border-color: #daefed;
}
.landing-page .alert-success-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-info-soft.alert-solid {
  color: #000;
  background-color: #daf2f8;
  border-color: #daf2f8;
}
.landing-page .alert-info-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-warning-soft.alert-solid {
  color: #000;
  background-color: #f2eee3;
  border-color: #f2eee3;
}
.landing-page .alert-warning-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .alert-danger-soft.alert-solid {
  color: #000;
  background-color: #f1e0e3;
  border-color: #f1e0e3;
}
.landing-page .alert-danger-soft.alert-solid .alert-link {
  color: black;
}
.landing-page .avatar {
  display: inline-flex;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
}
.landing-page .avatar .avatar-img {
  border-radius: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  height: 2rem;
  width: auto;
}
.landing-page .avatar-xs {
  height: 1.25rem;
  width: 1.25rem;
}
.landing-page .avatar-xs .avatar-img {
  height: 1rem;
}
.landing-page .avatar-sm {
  height: 1.75rem;
  width: 1.75rem;
}
.landing-page .avatar-sm .avatar-img {
  height: 1.5rem;
}
.landing-page .avatar-lg {
  height: 2.5rem;
  width: 2.5rem;
}
.landing-page .avatar-lg .avatar-img {
  height: 2.5rem;
}
.landing-page .avatar-xl {
  height: 3rem;
  width: 3rem;
}
.landing-page .avatar-xl .avatar-img {
  height: 3rem;
}
.landing-page .avatar-xxl {
  height: 3.75rem;
  width: 3.75rem;
}
.landing-page .avatar-xxl .avatar-img {
  height: 3.75rem;
}
.landing-page .avatar-busy::before,
.landing-page .avatar-idle::before,
.landing-page .avatar-offline::before,
.landing-page .avatar-online::before {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background-color: #d4dae3;
  border: 0.0625rem solid #fff;
}
.landing-page .avatar-busy::before {
  background-color: #e81500;
}
.landing-page .avatar-idle::before {
  background-color: #f4a100;
}
.landing-page .avatar-offline::before {
  background-color: #d4dae3;
}
.landing-page .avatar-online::before {
  background-color: #00ac69;
}
.landing-page .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.landing-page .btn-icon {
  padding: 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: calc(2.625rem + 2px) !important;
  width: calc(2.625rem + 2px) !important;
}
.landing-page .btn-icon .feather {
  margin-top: 0 !important;
}
.landing-page .btn-icon.btn-xl {
  height: calc(3.625rem + 2px) !important;
  width: calc(3.625rem + 2px) !important;
  border-radius: 100%;
}
.landing-page .btn-icon.btn-lg,
.landing-page .btn-group-lg > .btn-icon.btn {
  height: calc(3.25rem + 2px) !important;
  width: calc(3.25rem + 2px) !important;
}
.landing-page .btn-icon.btn-sm,
.landing-page .btn-group-sm > .btn-icon.btn {
  height: calc(1.75rem + 2px) !important;
  width: calc(1.75rem + 2px) !important;
}
.landing-page .btn-icon.btn-xs {
  height: calc(1.2rem + 2px) !important;
  width: calc(1.2rem + 2px) !important;
  border-radius: 100%;
}
.landing-page .btn-icon.btn-link {
  text-decoration: none;
}
.landing-page .btn .feather {
  margin-top: -1px;
  height: 0.875rem;
  width: 0.875rem;
}
.landing-page .btn-lg .feather,
.landing-page .btn-group-lg > .btn .feather {
  height: 1rem;
  width: 1rem;
}
.landing-page .btn-sm .feather,
.landing-page .btn-group-sm > .btn .feather {
  height: 0.75rem;
  width: 0.75rem;
}
.landing-page .btn-xs .feather {
  height: 0.7rem;
  width: 0.7rem;
}
.landing-page .btn-xl .feather {
  height: 1.125rem;
  width: 1.125rem;
}
.landing-page .btn-xs {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 0.35rem;
  line-height: 1;
}
.landing-page .btn-xl {
  padding: 1.25rem 1.5rem;
  font-size: 1.125rem;
  border-radius: 0.5rem;
}
.landing-page .btn-facebook {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.landing-page .btn-facebook:hover {
  color: #fff;
  background-color: #324c81;
  border-color: #2f477a;
}
.landing-page .btn-check:focus + .btn-facebook,
.landing-page .btn-facebook:focus {
  color: #fff;
  background-color: #324c81;
  border-color: #2f477a;
  box-shadow: 0 0 0 0.25rem rgba(88, 114, 167, 0.5);
}
.landing-page .btn-check:checked + .btn-facebook,
.landing-page .btn-check:active + .btn-facebook,
.landing-page .btn-facebook:active,
.landing-page .btn-facebook.active,
.landing-page .show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #2f477a;
  border-color: #2c4372;
}
.landing-page .btn-check:checked + .btn-facebook:focus,
.landing-page .btn-check:active + .btn-facebook:focus,
.landing-page .btn-facebook:active:focus,
.landing-page .btn-facebook.active:focus,
.landing-page .show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 114, 167, 0.5);
}
.landing-page .btn-facebook:disabled,
.landing-page .btn-facebook.disabled {
  color: #fff;
  background-color: #3b5998;
  border-color: #3b5998;
}
.landing-page .btn-github {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.landing-page .btn-github:hover {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #292929;
}
.landing-page .btn-check:focus + .btn-github,
.landing-page .btn-github:focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #292929;
  box-shadow: 0 0 0 0.25rem rgba(82, 82, 82, 0.5);
}
.landing-page .btn-check:checked + .btn-github,
.landing-page .btn-check:active + .btn-github,
.landing-page .btn-github:active,
.landing-page .btn-github.active,
.landing-page .show > .btn-github.dropdown-toggle {
  color: #fff;
  background-color: #292929;
  border-color: #262626;
}
.landing-page .btn-check:checked + .btn-github:focus,
.landing-page .btn-check:active + .btn-github:focus,
.landing-page .btn-github:active:focus,
.landing-page .btn-github.active:focus,
.landing-page .show > .btn-github.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 82, 82, 0.5);
}
.landing-page .btn-github:disabled,
.landing-page .btn-github.disabled {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}
.landing-page .btn-google {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.landing-page .btn-google:hover {
  color: #fff;
  background-color: #c7392d;
  border-color: #bb362a;
}
.landing-page .btn-check:focus + .btn-google,
.landing-page .btn-google:focus {
  color: #fff;
  background-color: #c7392d;
  border-color: #bb362a;
  box-shadow: 0 0 0 0.25rem rgba(237, 95, 83, 0.5);
}
.landing-page .btn-check:checked + .btn-google,
.landing-page .btn-check:active + .btn-google,
.landing-page .btn-google:active,
.landing-page .btn-google.active,
.landing-page .show > .btn-google.dropdown-toggle {
  color: #fff;
  background-color: #bb362a;
  border-color: #b03228;
}
.landing-page .btn-check:checked + .btn-google:focus,
.landing-page .btn-check:active + .btn-google:focus,
.landing-page .btn-google:active:focus,
.landing-page .btn-google.active:focus,
.landing-page .show > .btn-google.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(237, 95, 83, 0.5);
}
.landing-page .btn-google:disabled,
.landing-page .btn-google.disabled {
  color: #fff;
  background-color: #ea4335;
  border-color: #ea4335;
}
.landing-page .btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.landing-page .btn-twitter:hover {
  color: #fff;
  background-color: #1989ce;
  border-color: #1781c2;
}
.landing-page .btn-check:focus + .btn-twitter,
.landing-page .btn-twitter:focus {
  color: #fff;
  background-color: #1989ce;
  border-color: #1781c2;
  box-shadow: 0 0 0 0.25rem rgba(63, 175, 244, 0.5);
}
.landing-page .btn-check:checked + .btn-twitter,
.landing-page .btn-check:active + .btn-twitter,
.landing-page .btn-twitter:active,
.landing-page .btn-twitter.active,
.landing-page .show > .btn-twitter.dropdown-toggle {
  color: #fff;
  background-color: #1781c2;
  border-color: #1679b6;
}
.landing-page .btn-check:checked + .btn-twitter:focus,
.landing-page .btn-check:active + .btn-twitter:focus,
.landing-page .btn-twitter:active:focus,
.landing-page .btn-twitter.active:focus,
.landing-page .show > .btn-twitter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(63, 175, 244, 0.5);
}
.landing-page .btn-twitter:disabled,
.landing-page .btn-twitter.disabled {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}
.landing-page .btn-transparent-dark {
  color: rgba(33, 40, 50, 0.5);
  background-color: transparent;
  border-color: transparent;
  color: rgba(33, 40, 50, 0.5) !important;
}
.landing-page .btn-transparent-dark:hover {
  color: rgba(33, 40, 50, 0.5);
  background-color: rgba(33, 40, 50, 0.1);
  border-color: transparent;
}
.landing-page .btn-check:focus + .btn-transparent-dark,
.landing-page .btn-transparent-dark:focus {
  color: rgba(33, 40, 50, 0.5);
  background-color: rgba(33, 40, 50, 0.1);
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(11, 14, 17, 0.5);
}
.landing-page .btn-check:checked + .btn-transparent-dark,
.landing-page .btn-check:active + .btn-transparent-dark,
.landing-page .btn-transparent-dark:active,
.landing-page .btn-transparent-dark.active,
.landing-page .show > .btn-transparent-dark.dropdown-toggle {
  color: rgba(33, 40, 50, 0.5);
  background-color: rgba(33, 40, 50, 0.2);
  border-color: transparent;
}
.landing-page .btn-check:checked + .btn-transparent-dark:focus,
.landing-page .btn-check:active + .btn-transparent-dark:focus,
.landing-page .btn-transparent-dark:active:focus,
.landing-page .btn-transparent-dark.active:focus,
.landing-page .show > .btn-transparent-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 14, 17, 0.5);
}
.landing-page .btn-transparent-dark:disabled,
.landing-page .btn-transparent-dark.disabled {
  color: rgba(33, 40, 50, 0.35);
  background-color: rgba(33, 40, 50, 0.1);
  border-color: transparent;
}
.landing-page .btn-transparent-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 40, 50, 0.25) !important;
}
.landing-page .btn-transparent-light {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-color: transparent;
  color: rgba(255, 255, 255, 0.5) !important;
}
.landing-page .btn-transparent-light:hover {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}
.landing-page .btn-check:focus + .btn-transparent-light,
.landing-page .btn-transparent-light:focus {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
}
.landing-page .btn-check:checked + .btn-transparent-light,
.landing-page .btn-check:active + .btn-transparent-light,
.landing-page .btn-transparent-light:active,
.landing-page .btn-transparent-light.active,
.landing-page .show > .btn-transparent-light.dropdown-toggle {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.landing-page .btn-check:checked + .btn-transparent-light:focus,
.landing-page .btn-check:active + .btn-transparent-light:focus,
.landing-page .btn-transparent-light:active:focus,
.landing-page .btn-transparent-light.active:focus,
.landing-page .show > .btn-transparent-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
}
.landing-page .btn-transparent-light:disabled,
.landing-page .btn-transparent-light.disabled {
  color: rgba(255, 255, 255, 0.35);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}
.landing-page .btn-transparent-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}
.landing-page .btn-white-10 {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.5) !important;
}
.landing-page .btn-white-10:hover {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
}
.landing-page .btn-check:focus + .btn-white-10,
.landing-page .btn-white-10:focus {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.15);
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
}
.landing-page .btn-check:checked + .btn-white-10,
.landing-page .btn-check:active + .btn-white-10,
.landing-page .btn-white-10:active,
.landing-page .btn-white-10.active,
.landing-page .show > .btn-white-10.dropdown-toggle {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.landing-page .btn-check:checked + .btn-white-10:focus,
.landing-page .btn-check:active + .btn-white-10:focus,
.landing-page .btn-white-10:active:focus,
.landing-page .btn-white-10.active:focus,
.landing-page .show > .btn-white-10.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 88, 88, 0.5);
}
.landing-page .btn-white-10:disabled,
.landing-page .btn-white-10.disabled {
  color: rgba(255, 255, 255, 0.35);
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
}
.landing-page .btn-white-10:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
}
.landing-page .card {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.landing-page .card .card-header {
  font-weight: 500;
}
.landing-page .card:not([class*=bg-]) .card-header {
  color: #0061f2;
}
.landing-page .card.bg-dark .card-header,
.landing-page .card.bg-dark .card-footer {
  border-color: rgba(255, 255, 255, 0.15);
}
.landing-page .card .card-header .card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}
.landing-page .card-header-actions .card-header {
  height: 3.5625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
}
.landing-page .card-header-actions .card-header .dropdown-menu {
  margin-top: 0;
  top: 0.5625rem !important;
}
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse],
.landing-page .card-collapsable .card-header[data-toggle=collapse] {
  display: flex;
  text-decoration: none;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow,
.landing-page .card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow {
  height: 0.9rem;
  width: 0.9rem;
  display: inline-flex;
  font-size: 0.9rem;
  transition: transform 0.1s ease-in-out;
}
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow svg,
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow i,
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse] .card-collapsable-arrow .feather,
.landing-page .card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow svg,
.landing-page .card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow i,
.landing-page .card-collapsable .card-header[data-toggle=collapse] .card-collapsable-arrow .feather {
  height: 0.9rem;
  width: 0.9rem;
}
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse].collapsed,
.landing-page .card-collapsable .card-header[data-toggle=collapse].collapsed {
  border-radius: 0.35rem;
}
.landing-page .card-collapsable .card-header[data-bs-toggle=collapse].collapsed .card-collapsable-arrow,
.landing-page .card-collapsable .card-header[data-toggle=collapse].collapsed .card-collapsable-arrow {
  transform: rotate(-90deg);
}
.landing-page .card-icon {
  overflow: hidden;
}
.landing-page .card-icon .card-icon-aside {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  padding: 2rem;
}
.landing-page .card-icon .card-icon-aside i,
.landing-page .card-icon .card-icon-aside svg,
.landing-page .card-icon .card-icon-aside .feather {
  height: 3rem;
  width: 3rem;
}
.landing-page .card-link {
  text-decoration: none !important;
  color: inherit !important;
}
.landing-page .card-link:hover {
  color: inherit !important;
}
.landing-page .card-progress .progress {
  height: 0.5rem;
  background-color: rgba(33, 40, 50, 0.03);
}
.landing-page .card-scrollable .card-body {
  max-height: 15rem;
  overflow-y: auto;
}
.landing-page .card-waves .card-body,
.landing-page .card-angles .card-body {
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
}
.landing-page .card-flag {
  position: absolute;
  font-size: 0.7rem;
  padding: 0.3rem 0.5rem;
  line-height: 1;
}
.landing-page .card-flag-dark {
  background-color: rgba(33, 40, 50, 0.7);
  color: #fff;
}
.landing-page .card-flag-light {
  background-color: rgba(255, 255, 255, 0.7);
  color: #69707a;
}
.landing-page .card-flag-lg {
  font-size: 0.9rem;
  padding: 0.5rem 0.65rem;
}
.landing-page .card-flag-top-right {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  top: 0.5rem;
  right: 0;
}
.landing-page .card-flag-top-left {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  top: 0.5rem;
  left: 0;
}
.landing-page .card-flag-bottom-right {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  bottom: 0.5rem;
  right: 0;
}
.landing-page .card-flag-bottom-left {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  bottom: 0.5rem;
  left: 0;
}
.landing-page .dropdown-menu {
  font-size: 0.9rem;
  border: none;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 40, 50, 0.15);
}
.landing-page .dropdown-menu .dropdown-header {
  font-size: 0.75rem;
  font-weight: 700;
  display: flex;
  align-items: center;
}
.landing-page .dropdown-menu .dropdown-item {
  display: flex;
  align-items: center;
}
.landing-page .dropdown-menu .dropdown-item .dropdown-item-icon {
  margin-right: 0.5rem;
  line-height: 1;
}
.landing-page .dropdown-menu .dropdown-item .dropdown-item-icon svg {
  height: 0.9em;
  width: 0.9em;
}
.landing-page .dropdown-menu .dropdown-item.active .dropdown-item-icon,
.landing-page .dropdown-menu .dropdown-item:active .dropdown-item-icon {
  color: #fff;
}
.landing-page .dropdown .dropdown-toggle {
  display: inline-flex;
  align-items: center;
}
.landing-page .dropdown .dropdown-toggle .dropdown-arrow {
  margin-left: 0.4rem;
  margin-right: 0;
  transition: transform 0.1s ease-in-out;
  font-size: 0.6em;
}
.landing-page .dropdown .dropdown-toggle.show .dropdown-arrow {
  transform: rotate(90deg);
}
.landing-page .feather {
  height: 1rem;
  width: 1rem;
  vertical-align: top;
}
.landing-page .feather-sm {
  height: 0.8rem;
  width: 0.8rem;
}
.landing-page .icon-stack {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1rem;
  background-color: #f2f6fc;
  flex-shrink: 0;
}
.landing-page .icon-stack svg {
  height: 1rem;
  width: 1rem;
}
.landing-page .icon-stack-sm {
  height: 2rem;
  width: 2rem;
}
.landing-page .icon-stack-lg {
  height: 4rem;
  width: 4rem;
  font-size: 1.5rem;
}
.landing-page .icon-stack-lg svg {
  height: 1.5rem;
  width: 1.5rem;
}
.landing-page .icon-stack-xl {
  height: 5rem;
  width: 5rem;
  font-size: 1.75rem;
}
.landing-page .icon-stack-xl svg {
  height: 1.75rem;
  width: 1.75rem;
}
.landing-page .icon-list-social {
  display: flex;
}
.landing-page .icon-list-social .icon-list-social-link {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}
.landing-page .badge-sm {
  font-size: 0.75rem;
}
.landing-page .badge-md {
  font-size: 1rem;
}
.landing-page .badge-lg {
  font-size: 1.25rem;
}
.landing-page .timeline .timeline-item {
  display: flex;
  align-items: flex-start;
}
.landing-page .timeline .timeline-item .timeline-item-marker {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.landing-page .timeline .timeline-item .timeline-item-marker .timeline-item-marker-text {
  font-size: 0.875rem;
  width: 6rem;
  color: #a7aeb8;
  text-align: center;
  margin-bottom: 0.5rem;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.landing-page .timeline .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background-color: #f2f6fc;
  border-radius: 100%;
}
.landing-page .timeline .timeline-item .timeline-item-content {
  padding-top: 0;
  padding-bottom: 2rem;
  padding-left: 1rem;
  width: 100%;
}
.landing-page .timeline .timeline-item:last-child .timeline-item-content {
  padding-bottom: 0 !important;
}
@media (min-width: 576px) {
  .landing-page .timeline .timeline-item .timeline-item-marker {
    flex-direction: row;
    transform: translateX(1.625rem);
    margin-bottom: 0;
  }
  .landing-page .timeline .timeline-item .timeline-item-marker .timeline-item-marker-text {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  .landing-page .timeline .timeline-item .timeline-item-content {
    padding-top: 0.75rem;
    padding-bottom: 3rem;
    padding-left: 3rem;
    border-left: solid 0.25rem #f2f6fc;
  }
  .landing-page .timeline .timeline-item:last-child .timeline-item-content {
    border-left-color: transparent;
  }
}
.landing-page .timeline.timeline-sm .timeline-item .timeline-item-marker {
  transform: translateX(0.875rem);
}
.landing-page .timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-text {
  width: 3rem;
  font-size: 0.7rem;
}
.landing-page .timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.875rem;
}
.landing-page .timeline.timeline-sm .timeline-item .timeline-item-marker .timeline-item-marker-indicator .feather {
  height: 0.75rem;
  width: 0.75rem;
}
.landing-page .timeline.timeline-sm .timeline-item .timeline-item-content {
  font-size: 0.875rem;
  padding-top: 0.15rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}
.landing-page .timeline.timeline-xs .timeline-item .timeline-item-marker {
  transform: translateX(0.5625rem);
}
.landing-page .timeline.timeline-xs .timeline-item .timeline-item-marker .timeline-item-marker-text {
  width: 3rem;
  font-size: 0.7rem;
}
.landing-page .timeline.timeline-xs .timeline-item .timeline-item-marker .timeline-item-marker-indicator {
  height: 0.875rem;
  width: 0.875rem;
  font-size: 0.875rem;
  border: 0.125rem solid #fff;
  margin-top: -0.125rem;
}
.landing-page .timeline.timeline-xs .timeline-item .timeline-item-content {
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
}
.landing-page .step {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.landing-page .step .step-item {
  flex: 1 1 0;
  position: relative;
  text-align: center;
}
.landing-page .step .step-item:not(:first-child)::before {
  background-color: #69707a;
  content: "";
  height: 0.125rem;
  left: -50%;
  position: absolute;
  top: 0.4375rem;
  width: 100%;
  z-index: 0;
}
.landing-page .step .step-item .step-item-link {
  color: #69707a;
  position: relative;
  display: inline-block;
  padding-top: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  text-decoration: none;
  z-index: 1;
}
.landing-page .step .step-item .step-item-link::before {
  background-color: #69707a;
  border: 0.125rem solid #fff;
  border-radius: 100%;
  content: "";
  display: block;
  height: 1rem;
  width: 1rem;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}
.landing-page .step .step-item .step-item-link.disabled {
  pointer-events: none;
  cursor: default;
}
.landing-page .step .step-item.active a::before {
  background: #fff;
  border: 0.125rem solid #69707a;
}
.landing-page .step .step-item.active ~ .step-item .step-item-link {
  color: #d4dae3;
}
.landing-page .step .step-item.active ~ .step-item .step-item-link::before {
  background: #d4dae3;
}
.landing-page .step .step-item.active ~ .step-item::before {
  background: #d4dae3;
}
.landing-page .step-lg .step-item:not(:first-child)::before {
  height: 0.25rem;
  top: 0.625rem;
}
.landing-page .step-lg .step-item .step-item-link {
  padding-top: 2rem;
}
.landing-page .step-lg .step-item .step-item-link::before {
  border-width: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
}
.landing-page .step-lg .step-item.active a::before {
  border-width: 0.25rem;
}
.landing-page .step-primary .step-item:not(:first-child)::before {
  background-color: #0061f2;
}
.landing-page .step-primary .step-item .step-item-link {
  color: #0061f2;
}
.landing-page .step-primary .step-item .step-item-link::before {
  background-color: #0061f2;
}
.landing-page .step-primary .step-item.active a::before {
  border-color: #0061f2;
}
.landing-page .step-secondary .step-item:not(:first-child)::before {
  background-color: #5bc2ff;
}
.landing-page .step-secondary .step-item .step-item-link {
  color: #5bc2ff;
}
.landing-page .step-secondary .step-item .step-item-link::before {
  background-color: #5bc2ff;
}
.landing-page .step-secondary .step-item.active a::before {
  border-color: #5bc2ff;
}
.landing-page .step-success .step-item:not(:first-child)::before {
  background-color: #00ac69;
}
.landing-page .step-success .step-item .step-item-link {
  color: #00ac69;
}
.landing-page .step-success .step-item .step-item-link::before {
  background-color: #00ac69;
}
.landing-page .step-success .step-item.active a::before {
  border-color: #00ac69;
}
.landing-page .step-info .step-item:not(:first-child)::before {
  background-color: #00cfd5;
}
.landing-page .step-info .step-item .step-item-link {
  color: #00cfd5;
}
.landing-page .step-info .step-item .step-item-link::before {
  background-color: #00cfd5;
}
.landing-page .step-info .step-item.active a::before {
  border-color: #00cfd5;
}
.landing-page .step-warning .step-item:not(:first-child)::before {
  background-color: #f4a100;
}
.landing-page .step-warning .step-item .step-item-link {
  color: #f4a100;
}
.landing-page .step-warning .step-item .step-item-link::before {
  background-color: #f4a100;
}
.landing-page .step-warning .step-item.active a::before {
  border-color: #f4a100;
}
.landing-page .step-danger .step-item:not(:first-child)::before {
  background-color: #e81500;
}
.landing-page .step-danger .step-item .step-item-link {
  color: #e81500;
}
.landing-page .step-danger .step-item .step-item-link::before {
  background-color: #e81500;
}
.landing-page .step-danger .step-item.active a::before {
  border-color: #e81500;
}
.landing-page .step-light .step-item:not(:first-child)::before {
  background-color: #f2f6fc;
}
.landing-page .step-light .step-item .step-item-link {
  color: #f2f6fc;
}
.landing-page .step-light .step-item .step-item-link::before {
  background-color: #f2f6fc;
}
.landing-page .step-light .step-item.active a::before {
  border-color: #f2f6fc;
}
.landing-page .step-dark .step-item:not(:first-child)::before {
  background-color: #212832;
}
.landing-page .step-dark .step-item .step-item-link {
  color: #212832;
}
.landing-page .step-dark .step-item .step-item-link::before {
  background-color: #212832;
}
.landing-page .step-dark .step-item.active a::before {
  border-color: #212832;
}
.landing-page .step-black .step-item:not(:first-child)::before {
  background-color: #000;
}
.landing-page .step-black .step-item .step-item-link {
  color: #000;
}
.landing-page .step-black .step-item .step-item-link::before {
  background-color: #000;
}
.landing-page .step-black .step-item.active a::before {
  border-color: #000;
}
.landing-page .step-white .step-item:not(:first-child)::before {
  background-color: #fff;
}
.landing-page .step-white .step-item .step-item-link {
  color: #fff;
}
.landing-page .step-white .step-item .step-item-link::before {
  background-color: #fff;
}
.landing-page .step-white .step-item.active a::before {
  border-color: #fff;
}
.landing-page .step-red .step-item:not(:first-child)::before {
  background-color: #e81500;
}
.landing-page .step-red .step-item .step-item-link {
  color: #e81500;
}
.landing-page .step-red .step-item .step-item-link::before {
  background-color: #e81500;
}
.landing-page .step-red .step-item.active a::before {
  border-color: #e81500;
}
.landing-page .step-orange .step-item:not(:first-child)::before {
  background-color: #f76400;
}
.landing-page .step-orange .step-item .step-item-link {
  color: #f76400;
}
.landing-page .step-orange .step-item .step-item-link::before {
  background-color: #f76400;
}
.landing-page .step-orange .step-item.active a::before {
  border-color: #f76400;
}
.landing-page .step-yellow .step-item:not(:first-child)::before {
  background-color: #f4a100;
}
.landing-page .step-yellow .step-item .step-item-link {
  color: #f4a100;
}
.landing-page .step-yellow .step-item .step-item-link::before {
  background-color: #f4a100;
}
.landing-page .step-yellow .step-item.active a::before {
  border-color: #f4a100;
}
.landing-page .step-green .step-item:not(:first-child)::before {
  background-color: #00ac69;
}
.landing-page .step-green .step-item .step-item-link {
  color: #00ac69;
}
.landing-page .step-green .step-item .step-item-link::before {
  background-color: #00ac69;
}
.landing-page .step-green .step-item.active a::before {
  border-color: #00ac69;
}
.landing-page .step-teal .step-item:not(:first-child)::before {
  background-color: #00ba94;
}
.landing-page .step-teal .step-item .step-item-link {
  color: #00ba94;
}
.landing-page .step-teal .step-item .step-item-link::before {
  background-color: #00ba94;
}
.landing-page .step-teal .step-item.active a::before {
  border-color: #00ba94;
}
.landing-page .step-cyan .step-item:not(:first-child)::before {
  background-color: #00cfd5;
}
.landing-page .step-cyan .step-item .step-item-link {
  color: #00cfd5;
}
.landing-page .step-cyan .step-item .step-item-link::before {
  background-color: #00cfd5;
}
.landing-page .step-cyan .step-item.active a::before {
  border-color: #00cfd5;
}
.landing-page .step-blue .step-item:not(:first-child)::before {
  background-color: #0061f2;
}
.landing-page .step-blue .step-item .step-item-link {
  color: #0061f2;
}
.landing-page .step-blue .step-item .step-item-link::before {
  background-color: #0061f2;
}
.landing-page .step-blue .step-item.active a::before {
  border-color: #0061f2;
}
.landing-page .step-indigo .step-item:not(:first-child)::before {
  background-color: #5800e8;
}
.landing-page .step-indigo .step-item .step-item-link {
  color: #5800e8;
}
.landing-page .step-indigo .step-item .step-item-link::before {
  background-color: #5800e8;
}
.landing-page .step-indigo .step-item.active a::before {
  border-color: #5800e8;
}
.landing-page .step-purple .step-item:not(:first-child)::before {
  background-color: #6900c7;
}
.landing-page .step-purple .step-item .step-item-link {
  color: #6900c7;
}
.landing-page .step-purple .step-item .step-item-link::before {
  background-color: #6900c7;
}
.landing-page .step-purple .step-item.active a::before {
  border-color: #6900c7;
}
.landing-page .step-pink .step-item:not(:first-child)::before {
  background-color: #e30059;
}
.landing-page .step-pink .step-item .step-item-link {
  color: #e30059;
}
.landing-page .step-pink .step-item .step-item-link::before {
  background-color: #e30059;
}
.landing-page .step-pink .step-item.active a::before {
  border-color: #e30059;
}
.landing-page .step-red-soft .step-item:not(:first-child)::before {
  background-color: #f1e0e3;
}
.landing-page .step-red-soft .step-item .step-item-link {
  color: #f1e0e3;
}
.landing-page .step-red-soft .step-item .step-item-link::before {
  background-color: #f1e0e3;
}
.landing-page .step-red-soft .step-item.active a::before {
  border-color: #f1e0e3;
}
.landing-page .step-orange-soft .step-item:not(:first-child)::before {
  background-color: #f3e7e3;
}
.landing-page .step-orange-soft .step-item .step-item-link {
  color: #f3e7e3;
}
.landing-page .step-orange-soft .step-item .step-item-link::before {
  background-color: #f3e7e3;
}
.landing-page .step-orange-soft .step-item.active a::before {
  border-color: #f3e7e3;
}
.landing-page .step-yellow-soft .step-item:not(:first-child)::before {
  background-color: #f2eee3;
}
.landing-page .step-yellow-soft .step-item .step-item-link {
  color: #f2eee3;
}
.landing-page .step-yellow-soft .step-item .step-item-link::before {
  background-color: #f2eee3;
}
.landing-page .step-yellow-soft .step-item.active a::before {
  border-color: #f2eee3;
}
.landing-page .step-green-soft .step-item:not(:first-child)::before {
  background-color: #daefed;
}
.landing-page .step-green-soft .step-item .step-item-link {
  color: #daefed;
}
.landing-page .step-green-soft .step-item .step-item-link::before {
  background-color: #daefed;
}
.landing-page .step-green-soft .step-item.active a::before {
  border-color: #daefed;
}
.landing-page .step-teal-soft .step-item:not(:first-child)::before {
  background-color: #daf0f2;
}
.landing-page .step-teal-soft .step-item .step-item-link {
  color: #daf0f2;
}
.landing-page .step-teal-soft .step-item .step-item-link::before {
  background-color: #daf0f2;
}
.landing-page .step-teal-soft .step-item.active a::before {
  border-color: #daf0f2;
}
.landing-page .step-cyan-soft .step-item:not(:first-child)::before {
  background-color: #daf2f8;
}
.landing-page .step-cyan-soft .step-item .step-item-link {
  color: #daf2f8;
}
.landing-page .step-cyan-soft .step-item .step-item-link::before {
  background-color: #daf2f8;
}
.landing-page .step-cyan-soft .step-item.active a::before {
  border-color: #daf2f8;
}
.landing-page .step-blue-soft .step-item:not(:first-child)::before {
  background-color: #dae7fb;
}
.landing-page .step-blue-soft .step-item .step-item-link {
  color: #dae7fb;
}
.landing-page .step-blue-soft .step-item .step-item-link::before {
  background-color: #dae7fb;
}
.landing-page .step-blue-soft .step-item.active a::before {
  border-color: #dae7fb;
}
.landing-page .step-indigo-soft .step-item:not(:first-child)::before {
  background-color: #e3ddfa;
}
.landing-page .step-indigo-soft .step-item .step-item-link {
  color: #e3ddfa;
}
.landing-page .step-indigo-soft .step-item .step-item-link::before {
  background-color: #e3ddfa;
}
.landing-page .step-indigo-soft .step-item.active a::before {
  border-color: #e3ddfa;
}
.landing-page .step-purple-soft .step-item:not(:first-child)::before {
  background-color: #e4ddf7;
}
.landing-page .step-purple-soft .step-item .step-item-link {
  color: #e4ddf7;
}
.landing-page .step-purple-soft .step-item .step-item-link::before {
  background-color: #e4ddf7;
}
.landing-page .step-purple-soft .step-item.active a::before {
  border-color: #e4ddf7;
}
.landing-page .step-pink-soft .step-item:not(:first-child)::before {
  background-color: #f1ddec;
}
.landing-page .step-pink-soft .step-item .step-item-link {
  color: #f1ddec;
}
.landing-page .step-pink-soft .step-item .step-item-link::before {
  background-color: #f1ddec;
}
.landing-page .step-pink-soft .step-item.active a::before {
  border-color: #f1ddec;
}
.landing-page .step-primary-soft .step-item:not(:first-child)::before {
  background-color: #dae7fb;
}
.landing-page .step-primary-soft .step-item .step-item-link {
  color: #dae7fb;
}
.landing-page .step-primary-soft .step-item .step-item-link::before {
  background-color: #dae7fb;
}
.landing-page .step-primary-soft .step-item.active a::before {
  border-color: #dae7fb;
}
.landing-page .step-secondary-soft .step-item:not(:first-child)::before {
  background-color: #e3f1fc;
}
.landing-page .step-secondary-soft .step-item .step-item-link {
  color: #e3f1fc;
}
.landing-page .step-secondary-soft .step-item .step-item-link::before {
  background-color: #e3f1fc;
}
.landing-page .step-secondary-soft .step-item.active a::before {
  border-color: #e3f1fc;
}
.landing-page .step-success-soft .step-item:not(:first-child)::before {
  background-color: #daefed;
}
.landing-page .step-success-soft .step-item .step-item-link {
  color: #daefed;
}
.landing-page .step-success-soft .step-item .step-item-link::before {
  background-color: #daefed;
}
.landing-page .step-success-soft .step-item.active a::before {
  border-color: #daefed;
}
.landing-page .step-info-soft .step-item:not(:first-child)::before {
  background-color: #daf2f8;
}
.landing-page .step-info-soft .step-item .step-item-link {
  color: #daf2f8;
}
.landing-page .step-info-soft .step-item .step-item-link::before {
  background-color: #daf2f8;
}
.landing-page .step-info-soft .step-item.active a::before {
  border-color: #daf2f8;
}
.landing-page .step-warning-soft .step-item:not(:first-child)::before {
  background-color: #f2eee3;
}
.landing-page .step-warning-soft .step-item .step-item-link {
  color: #f2eee3;
}
.landing-page .step-warning-soft .step-item .step-item-link::before {
  background-color: #f2eee3;
}
.landing-page .step-warning-soft .step-item.active a::before {
  border-color: #f2eee3;
}
.landing-page .step-danger-soft .step-item:not(:first-child)::before {
  background-color: #f1e0e3;
}
.landing-page .step-danger-soft .step-item .step-item-link {
  color: #f1e0e3;
}
.landing-page .step-danger-soft .step-item .step-item-link::before {
  background-color: #f1e0e3;
}
.landing-page .step-danger-soft .step-item.active a::before {
  border-color: #f1e0e3;
}
.landing-page .form-check.form-check-solid .form-check-input {
  background-color: #eef2f8;
  border-color: #eef2f8;
}
.landing-page .form-check.form-check-solid .form-check-input:checked {
  background-color: #0061f2;
}
.landing-page .form-control-solid {
  background-color: #eef2f8;
  border-color: #eef2f8;
}
.landing-page .form-control-solid:focus {
  background-color: #eef2f8;
}
.landing-page .input-group-joined {
  border-color: #c5ccd6;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.4375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.landing-page .input-group-joined:focus-within {
  border-color: transparent;
  box-shadow: 0 0 0 0.25rem rgba(0, 97, 242, 0.25);
}
.landing-page .input-group-joined .form-control {
  border: none;
}
.landing-page .input-group-joined .form-control:focus {
  box-shadow: none;
  outline: none;
}
.landing-page .input-group-joined .input-group-text,
.landing-page .input-group-joined .input-group-text {
  border: none;
  background-color: #fff;
}
.landing-page .input-group-joined-xl .form-control {
  height: 4rem;
  font-size: 1.25rem;
}
.landing-page .input-group-joined.input-group-solid {
  border: 0;
  background-color: #eef2f8;
}
.landing-page .input-group-joined.input-group-solid .form-control,
.landing-page .input-group-joined.input-group-solid .input-group-text {
  background-color: transparent;
}
.landing-page #layoutDefault {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.landing-page #layoutDefault #layoutDefault_content {
  min-width: 0;
  flex-grow: 1;
}
.landing-page #layoutDefault #layoutDefault_footer {
  min-width: 0;
}
.landing-page .content-skewed {
  perspective: 1500px;
  transform-style: preserve-3d;
}
.landing-page .content-skewed-right {
  perspective-origin: right center;
}
.landing-page .content-skewed-right .content-skewed-item {
  transform: rotateY(30deg) rotateX(15deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.landing-page .content-skewed-left {
  perspective-origin: left center;
}
.landing-page .content-skewed-left .content-skewed-item {
  transform: rotateY(-30deg) rotateX(15deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.landing-page .badge-marketing {
  padding: 0.5em 1em;
}
.landing-page .list-group-careers {
  margin-bottom: 3rem;
}
.landing-page .list-group-careers .list-group-item {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.landing-page .media-img {
  height: 5rem;
  width: 5rem;
}
@media (min-width: 992px) {
  .landing-page .dropdown-lg,
.landing-page .dropdown-xl {
    position: relative;
  }
  .landing-page .dropdown-lg .dropdown-menu,
.landing-page .dropdown-xl .dropdown-menu {
    position: absolute;
    padding: 0;
    overflow: hidden;
  }
  .landing-page .dropdown-lg .dropdown-menu .dropdown-item,
.landing-page .dropdown-lg .dropdown-menu .dropdown-header,
.landing-page .dropdown-xl .dropdown-menu .dropdown-item,
.landing-page .dropdown-xl .dropdown-menu .dropdown-header {
    padding-left: 0;
    padding-right: 0;
  }
  .landing-page .dropdown-lg .dropdown-menu .dropdown-item:hover,
.landing-page .dropdown-lg .dropdown-menu .dropdown-item:active,
.landing-page .dropdown-lg .dropdown-menu .dropdown-item:focus,
.landing-page .dropdown-xl .dropdown-menu .dropdown-item:hover,
.landing-page .dropdown-xl .dropdown-menu .dropdown-item:active,
.landing-page .dropdown-xl .dropdown-menu .dropdown-item:focus {
    background-color: inherit;
    color: #69707a !important;
  }
  .landing-page .dropdown-lg .dropdown-menu {
    min-width: 30rem;
    overflow: hidden;
  }
  .landing-page .dropdown-xl .dropdown-menu {
    min-width: 45rem;
  }
}
.landing-page .navbar .dropdown-menu {
  top: calc(100% + 0.5rem + 0.5rem) !important;
  font-size: 0.9rem;
}
.landing-page .navbar .dropdown-menu .dropdown-header {
  font-size: 0.7rem;
  color: #a7aeb8;
}
.landing-page .navbar-marketing {
  transition: background-color 0.15s ease-in-out;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.landing-page .navbar-marketing .navbar-toggler {
  padding: 0.5rem;
  border: 0;
}
.landing-page .navbar-marketing .navbar-toggler svg {
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
}
.landing-page .navbar-marketing .navbar-brand {
  font-size: 1.2rem;
  font-weight: bold;
}
.landing-page .navbar-marketing .navbar-brand img {
  height: 1rem;
}
.landing-page .navbar-marketing .navbar-brand svg {
  height: 1rem;
  fill: currentColor;
}
.landing-page .navbar-marketing .navbar-nav {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.landing-page .navbar-marketing .navbar-nav .nav-item .nav-link {
  font-weight: 500;
}
.landing-page .navbar-marketing.fixed-top,
.landing-page .page-header-ui.navbar-fixed .navbar-marketing.navbar {
  max-height: 100vh;
  overflow-y: auto;
}
@media (min-width: 992px) {
  .landing-page .navbar-marketing {
    padding-top: 0;
    padding-bottom: 0;
  }
  .landing-page .navbar-marketing .navbar-nav {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .landing-page .navbar-marketing .navbar-nav .nav-item {
    margin-right: 1rem;
  }
  .landing-page .navbar-marketing .navbar-nav .nav-item:last-child {
    margin-right: 0;
  }
  .landing-page .navbar-marketing.fixed-top,
.landing-page .page-header-ui.navbar-fixed .navbar-marketing.navbar {
    max-height: none;
    overflow-y: visible;
  }
}
.landing-page .navbar-marketing.navbar-dark.navbar-scrolled {
  background-color: #212832 !important;
}
.landing-page .navbar-marketing.navbar-light.navbar-scrolled {
  background-color: #fff !important;
  border-bottom: 1px solid #f2f6fc;
}
@media (max-width: 991.98px) {
  .landing-page .navbar-marketing.bg-transparent.navbar-dark {
    background-color: #212832 !important;
  }
  .landing-page .navbar-marketing.bg-transparent.navbar-light {
    background-color: #fff !important;
    border-bottom: 1px solid #f2f6fc;
  }
  .landing-page .animated--fade-in,
.landing-page .animated--fade-in-up {
    -webkit-animation: none;
    animation: none;
  }
}
.landing-page .pagination-blog .page-item {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.landing-page .pagination-blog .page-item:first-child {
  margin-left: 0;
}
.landing-page .pagination-blog .page-item:last-child {
  margin-right: 0;
}
.landing-page .pagination-blog .page-item .page-link {
  border-radius: 0.35rem;
  border: none;
  padding: 0.75rem 1rem;
  font-weight: 500;
  font-size: 0.9rem;
}
.landing-page .card-portfolio {
  position: relative;
  overflow: hidden;
  border: none;
}
.landing-page .card-portfolio .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 100%;
  text-align: center;
}
.landing-page .card-portfolio .card-body .card-title {
  font-size: 0.75rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: 0.1em;
  color: #363d47;
}
@media (min-width: 768px) {
  .landing-page .card-portfolio .card-body {
    position: absolute;
    border-radius: 0.35rem;
    opacity: 0;
    height: 100%;
    transition: opacity 0.25s ease-in-out;
    background-color: rgba(0, 97, 242, 0.9);
  }
  .landing-page .card-portfolio .card-body .card-title {
    color: #fff;
  }
  .landing-page .card-portfolio:hover .card-body {
    opacity: 1;
  }
}
.landing-page .video {
  overflow: hidden;
}
.landing-page .video .video-link {
  position: relative;
  display: flex;
  width: 100%;
  padding: 10rem 1rem;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.2s ease-in-out;
}
.landing-page .video .video-link svg {
  height: 3rem;
  width: 3rem;
}
.landing-page .video .video-link:hover {
  color: #fff;
}
.landing-page .post-preview h5.card-title,
.landing-page .post-preview .card-title.h5 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.landing-page .post-preview .post-preview-meta {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.landing-page .post-preview .post-preview-meta .post-preview-meta-img {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.landing-page .post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-name {
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.landing-page .post-preview .post-preview-meta .post-preview-meta-details .post-preview-meta-details-date {
  font-size: 0.75rem;
  line-height: 1;
}
.landing-page .post-preview-featured .post-preview-featured-img {
  height: 20rem;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-page .post-preview-featured h5.card-title,
.landing-page .post-preview-featured .card-title.h5 {
  font-size: 1.75rem;
}
.landing-page .post-preview-featured p.card-text {
  font-size: 1.2rem;
  font-weight: 300;
}
@media (min-width: 992px) {
  .landing-page .post-preview-featured .post-preview-featured-img {
    height: 100%;
    width: 100%;
  }
}
.landing-page .post-archive-tag {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #e0e5ec;
  margin-bottom: 3rem;
  padding-bottom: 0.5rem;
}
.landing-page .post-archive-item {
  color: inherit !important;
  text-decoration: none !important;
}
.landing-page .post-archive-meta {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.landing-page .post-archive-meta .post-archive-meta-img {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.landing-page .post-archive-meta .post-archive-meta-details .post-archive-meta-details-name {
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.landing-page .post-archive-meta .post-archive-meta-details .post-archive-meta-details-date {
  font-size: 0.75rem;
  line-height: 1;
}
.landing-page .single-post .single-post-meta {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}
.landing-page .single-post .single-post-meta .single-post-meta-img {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  flex-shrink: 0;
}
.landing-page .single-post .single-post-meta .single-post-meta-details .single-post-meta-details-name {
  font-size: 0.9rem;
  line-height: 1;
  margin-bottom: 0.25rem;
}
.landing-page .single-post .single-post-meta .single-post-meta-details .single-post-meta-details-date {
  font-size: 0.75rem;
  line-height: 1;
}
.landing-page .single-post .single-post-meta-links {
  font-size: 1.25rem;
}
.landing-page .single-post .single-post-meta-links a {
  color: #c5ccd6;
  margin-left: 0.5rem;
}
.landing-page .single-post .single-post-meta-links a:hover {
  color: #69707a;
}
.landing-page .single-post .single-post-text {
  font-size: 1.1rem;
}
.landing-page .single-post .single-post-text p {
  margin-bottom: 1.5rem;
}
.landing-page .single-post .single-post-text h2,
.landing-page .single-post .single-post-text .h2,
.landing-page .single-post .single-post-text h3,
.landing-page .single-post .single-post-text .h3,
.landing-page .single-post .single-post-text h4,
.landing-page .single-post .single-post-text .h4,
.landing-page .single-post .single-post-text h5,
.landing-page .single-post .single-post-text .h5,
.landing-page .single-post .single-post-text h6,
.landing-page .single-post .single-post-text .h6 {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;
}
.landing-page section {
  position: relative;
}
.landing-page .svg-border-angled {
  padding-top: 3rem;
}
.landing-page .svg-border-angled svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
}
@media (min-width: 576px) {
  .landing-page .svg-border-angled {
    padding-top: 3.5rem;
  }
  .landing-page .svg-border-angled svg {
    height: 3.5rem;
  }
}
@media (min-width: 768px) {
  .landing-page .svg-border-angled {
    padding-top: 4rem;
  }
  .landing-page .svg-border-angled svg {
    height: 4rem;
  }
}
@media (min-width: 992px) {
  .landing-page .svg-border-angled {
    padding-top: 4.5rem;
  }
  .landing-page .svg-border-angled svg {
    height: 4.5rem;
  }
}
@media (min-width: 1200px) {
  .landing-page .svg-border-angled {
    padding-top: 5rem;
  }
  .landing-page .svg-border-angled svg {
    height: 5rem;
  }
}
.landing-page .svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1rem;
  width: 100%;
}
@media (min-width: 576px) {
  .landing-page .svg-border-rounded svg {
    height: 1.5rem;
  }
}
@media (min-width: 768px) {
  .landing-page .svg-border-rounded svg {
    height: 2rem;
  }
}
@media (min-width: 992px) {
  .landing-page .svg-border-rounded svg {
    height: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .landing-page .svg-border-rounded svg {
    height: 3rem;
  }
}
.landing-page .svg-border-waves {
  padding-top: 1rem;
}
.landing-page .svg-border-waves svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2rem;
  width: 100%;
}
@media (min-width: 576px) {
  .landing-page .svg-border-waves {
    padding-top: 1.5rem;
  }
  .landing-page .svg-border-waves svg {
    height: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .landing-page .svg-border-waves {
    padding-top: 2rem;
  }
  .landing-page .svg-border-waves svg {
    height: 3rem;
  }
}
.landing-page .brands svg {
  width: 100%;
  fill: currentColor;
}
.landing-page .card-team {
  overflow: visible;
  margin-top: 3rem;
}
.landing-page .card-team .card-body {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  text-align: center;
}
.landing-page .card-team .card-body .card-team-img {
  height: 10rem;
  width: 10rem;
  border-radius: 100%;
  margin-top: -4.5rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 40, 50, 0.2);
}
.landing-page .card-team .card-body .card-team-name {
  font-weight: 500;
  font-size: 1.1rem;
}
.landing-page .card-team .card-body .card-team-position {
  font-style: italic;
  color: #a7aeb8;
  font-size: 0.8rem;
}
.landing-page .device-laptop {
  position: relative;
}
.landing-page .device-laptop .device-container {
  display: block;
  height: auto;
  width: 100%;
  position: relative;
  z-index: 1;
}
.landing-page .device-laptop .device-screenshot {
  display: block;
  position: absolute;
  top: 6%;
  left: 11.8%;
  height: auto;
  width: 76.57%;
  z-index: 0;
}
.landing-page .footer {
  font-size: 0.875rem;
}
.landing-page .footer .footer-brand {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.landing-page .footer .footer-brand svg {
  height: 1rem;
}
.landing-page .footer.footer-dark {
  color: rgba(255, 255, 255, 0.6);
}
.landing-page .footer.footer-dark hr {
  border-color: rgba(255, 255, 255, 0.1);
}
.landing-page .footer.footer-light {
  color: #a7aeb8;
}
.landing-page .page-header-ui {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.landing-page .page-header-ui .page-header-ui-content .page-header-ui-title {
  font-size: 2.5rem;
}
.landing-page .page-header-ui .page-header-ui-content .page-header-ui-text {
  font-size: 1.15rem;
}
.landing-page .page-header-ui .page-header-ui-content .page-header-ui-text.small {
  font-size: 0.9rem;
}
.landing-page .page-header-ui-dark {
  color: #fff;
  background-color: #212832;
}
.landing-page .page-header-ui-dark .page-header-ui-title {
  color: #fff;
}
.landing-page .page-header-ui-light {
  background-color: #f2f6fc;
}
.landing-page .page-header-ui-light .page-header-ui-text {
  color: #69707a;
}
.landing-page .page-header-ui-light .page-header-ui-text a {
  color: #4a515b;
}
.landing-page .page-header-ui.navbar-fixed .page-header-ui-content {
  padding-top: 12.5rem;
}
.landing-page .pricing {
  font-size: 0.9rem;
  border: none;
}
.landing-page .pricing .pricing-price {
  font-size: 3rem;
  color: #363d47;
  margin-bottom: 1rem;
}
.landing-page .pricing .pricing-price sup {
  font-size: 1.5rem;
}
.landing-page .pricing .pricing-price .pricing-price-period {
  font-size: 1.25rem;
}
.landing-page .pricing .pricing-list {
  margin-bottom: 0;
  margin-left: 1.6em;
}
.landing-page .pricing .pricing-list .pricing-list-item {
  margin-bottom: 0.5rem;
}
@media (min-width: 992px) {
  .landing-page .pricing-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .landing-page .pricing-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.landing-page .testimonial .testimonial-brand {
  fill: currentColor;
  margin-bottom: 1.35rem;
}
.landing-page .testimonial .testimonial-brand svg {
  height: 3rem;
}
.landing-page .testimonial .testimonial-quote {
  font-size: 1.4rem;
  font-weight: 300;
}
.landing-page .testimonial .testimonial-name {
  font-weight: bold;
}
.landing-page .testimonial .testimonial-position {
  font-weight: lighter;
}
@media (min-width: 992px) {
  .landing-page .divider-right {
    border-right: 0.0625rem solid #e0e5ec;
  }
}
